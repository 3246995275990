import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { WorkLog } from "../../shared/types/WorkLog";

interface State {
  workLogs: WorkLog[];
  isLoading: boolean;
  isInitialLoading: boolean;
}

const initialState: State = {
  workLogs: [],
  isLoading: true,
  isInitialLoading: true,
};

export const workLogSlice = createSlice({
  name: "workLogs",
  initialState,
  reducers: {
    setWorkLogs: (state, action: PayloadAction<WorkLog[]>) => {
      state.workLogs = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateWorkLog: (state, action: PayloadAction<WorkLog>) => {
      const { workLogs } = state;
      const { payload } = action;
      const index = workLogs.findIndex(
        (workLog) => workLog._id === payload._id
      );
      if (index === -1) return;
      workLogs[index] = payload;
    },
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoading = action.payload;
    },
    addWorkLog: (state, action: PayloadAction<WorkLog>) => {
      state.workLogs.push(action.payload);
    },
    deleteWorkLog: (state, action: PayloadAction<string>) => {
      const { workLogs } = state;
      const index = workLogs.findIndex(
        (workLog) => workLog._id === action.payload
      );
      if (index === -1) return;
      workLogs.splice(index, 1);
    },
  },
});

export const {
  setWorkLogs,
  setLoading,
  updateWorkLog,
  setInitialLoading,
  addWorkLog,
  deleteWorkLog,
} = workLogSlice.actions;
