import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import { AddNewDeepCleanup } from "../pages/dashboard/cleanUps/AddNewDeepCleanUp";
import { CleanUpsBranch } from "../pages/dashboard/cleanUps/CleanUpsBranch";
import { DeepCleanUpShow } from "../pages/dashboard/cleanUps/DeepCleanUpShow";
import { SummaryCleanUps } from "../pages/dashboard/cleanUps/SummaryCleanUps";
import { useSelector } from "react-redux";

export const CleanUpsRouter = () => {
  return (
    <Routes>
      {/* <Route path="*" element={<MainPageIndex />} /> */}
      <Route path="summary" element={<SummaryCleanUps />} />
      <Route path=":branch" element={<CleanUpsBranch />} />
      <Route
        path=":branch/addNewDeepCleaning"
        element={<AddNewDeepCleanup />}
      />
      <Route path=":branch/:deepCleanUpId" element={<DeepCleanUpShow />} />
    </Routes>
  );
};
