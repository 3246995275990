import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  options: { value: string | number; label: string }[];
  selectedValue: string;
  handleSelectChange: (event: SelectChangeEvent<string>) => void;
  label: string;
};

export const DropdownField: React.FC<Props> = ({
  options,
  selectedValue,
  handleSelectChange,
  label,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        value={selectedValue}
        onChange={handleSelectChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
