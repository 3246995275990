import { Dispatch, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Task } from "../../shared/types/Task";
import {
  addTask,
  deleteTask,
  setInitialLoading,
  setLoading,
  setTasks,
  updateTask,
} from "../slice/taskSlice";
import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";
import { ApiResponse } from "../../shared/types/responses";
import { AnyAction } from "redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { fetchNotifications } from "./notificationThunks";

const taskRoute = "/tasks";

export const startLoadingTasksAction = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<ApiResponse<Task[]>>(
        taskRoute
      );
      const fetchedData = data.data;
      dispatch(setTasks(fetchedData));
    } catch (error: any) {
      console.error("Axios Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const startInitialLoading = () => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch(setInitialLoading(true));
    try {
      await dispatch(startLoadingTasksAction());
    } catch (error: any) {
      console.error("Axios Error:", error);
    } finally {
      dispatch(setInitialLoading(false));
    }
  };
};

export const startSavingTask = (task: Task) => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState
  ) => {
    if (!task.description || task.description.length < 1) {
      task.description = task.title;
    }

    try {
      const { data } = await hvpAuthenticatedApi.post<ApiResponse<Task>>(
        taskRoute,
        task
      );
      if (data.ok) {
        Swal.fire({
          icon: "success",
          title: "Registro exitoso",
          showConfirmButton: false,
          timer: 1000,
        });
      }

      const fetchedTask = data.data;
      dispatch(addTask(fetchedTask));
      await dispatch(fetchNotifications());

      return fetchedTask._id;
    } catch (error: any) {
      console.error("Axios Error:", error);
    }
  };
};

export const startUpdatingTask = (task: Task, id: string) => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const { data } = await hvpAuthenticatedApi.put<ApiResponse<Task>>(
        `${taskRoute}/${id}`,
        task
      );

      if (!data.ok) {
        throw new Error("Error updating task");
      }

      const fetchedTask = data.data;
      dispatch(updateTask(fetchedTask));
      await dispatch(fetchNotifications());

      return fetchedTask;
    } catch (error: any) {
      console.error("Axios Error:", error);
    }
  };
};

export const startDeletingTask = (id: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { data } = await hvpAuthenticatedApi.delete<ApiResponse<Task>>(
        `${taskRoute}/${id}`
      );

      if (!data.ok) {
        throw new Error("Error deleting task");
      }

      const deletedTask = data.data;
      dispatch(deleteTask(id!));

      return deletedTask;
    } catch (error: any) {
      console.error("Axios Error:", error);
    }
  };
};
