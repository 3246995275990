import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button, TextField, Grid, Typography, Paper, Box } from "@mui/material";
import { IActivityRegister } from "../../../../shared/types/activity-register.types";
import {
  FormikCollaboratorSelect,
  FormikDateTimePicker,
  FormikOptionSelect,
} from "../../../../components";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useAuthHook } from "../../../../hooks";
import { ActivityRegisterService } from "../../../../services/activity-register.service";
import { checkIfAdmin } from "../../../../helpers";
import {
  createActivityRegister,
  updateActivityRegister,
} from "../../../../store/thunks/activity-register.thunks";

const validationSchema = Yup.object().shape({
  collaborator: Yup.string().required("Collaborator is required"),
  activity: Yup.string().required("Activity is required"),
  desc: Yup.string(),
  startingTime: Yup.date().required("Starting time is required"),
  endingTime: Yup.date().min(
    Yup.ref("startingTime"),
    "Ending time must be after starting time"
  ),
});

export const ActivityRegisterForm = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { currentUserId } = useAuthHook();
  const [initialValues, setInitialValues] = useState<IActivityRegister>({
    collaborator: currentUserId!,
    activity: "",
    desc: "",
    startingTime: new Date(),
    endingTime: undefined,
  });

  const { activityRegisterTypes } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const { currentUserRole } = useAuthHook();

  const isAdmin = checkIfAdmin(currentUserRole!);

  useEffect(() => {
    if (id && id! !== "new") {
      const fetchActivityRegister = async () => {
        const activityRegister = await ActivityRegisterService.getById(id);
        setInitialValues(activityRegister);
      };
      fetchActivityRegister();
    }
  }, [id]);

  const handleSubmit = (values: IActivityRegister) => {
    if (id && id !== "new") {
      dispatch(updateActivityRegister(values));
    } else {
      dispatch(createActivityRegister(values));
    }
  };

  return (
    <Paper elevation={3}>
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          Activity Register
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormikCollaboratorSelect
                    label="Collaborator"
                    name="collaborator"
                    error={touched.collaborator && !!errors.collaborator}
                    disabled={!isAdmin}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikOptionSelect
                    label="Activity"
                    name="activity"
                    error={touched.activity && !!errors.activity}
                    options={activityRegisterTypes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    multiline
                    rows={4}
                    name="desc"
                    label="Description"
                    error={touched.desc && !!errors.desc}
                    helperText={touched.desc && errors.desc}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikDateTimePicker
                    name="startingTime"
                    label="Starting Time"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikDateTimePicker name="endingTime" label="Ending Time" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};
