import React from "react";
import { Grid } from "@mui/material";
import { MenuRoute, ModuleMenu } from "../../../components";
import { ProductRouter } from "../../../routes/dashboard-routes";

export const ProductsLayout = () => {
  const routes: MenuRoute[] = [
    { name: "List", path: "" },
    { name: "New", path: "new" },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        <ProductRouter />
      </Grid>
    </Grid>
  );
};
