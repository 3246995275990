import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button, TextField, Grid, Typography, Paper, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks";
import { ISupplier } from "../../../../shared/types";
import { ProductService } from "../../../../services";
import { supplierThunks } from "../../../../store/thunks";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  legalName: Yup.string(),
});

export const SuppliersForm = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<ISupplier>({
    name: "",
    legalName: "",
  });

  useEffect(() => {
    if (id && id! !== "new") {
      const fetchById = async () => {
        const service = new ProductService();
        const resource = await service.getById(id);
        setInitialValues(resource);
      };
      fetchById();
    }
  }, [id]);

  const handleSubmit = (values: ISupplier) => {
    if (id && id !== "new") {
      dispatch(supplierThunks.updateItem(values));
    } else {
      dispatch(supplierThunks.createItem(values));
    }
    navigate("/dashboard/suppliers");
  };

  return (
    <Paper elevation={3}>
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          Supplier
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="name"
                    label="Name"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="legalName"
                    label="Legal Name"
                    error={touched.legalName && !!errors.legalName}
                    helperText={touched.legalName && errors.legalName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};
