import React from "react";
import { Field } from "formik";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Collaborator } from "../../shared/types";
import { getCollaboratorAvatarPropsById } from "../../helpers";
import { CustomAvatar } from "../avatar/CustomAvatar";
import { useCollaboratorHook } from "../../hooks";

interface CollaboratorSelectProps {
  label: string;
  name: string;
  error?: boolean;
  disabled?: boolean;
}

export const FormikCollaboratorSelect: React.FC<CollaboratorSelectProps> = ({
  label,
  name,
  error,
  disabled = false,
}) => {
  const { activeCollaborators } = useCollaboratorHook();
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Field
        as={Select}
        labelId={`${name}-label`}
        id={name}
        name={name}
        label={label}
        disabled={disabled}
      >
        {activeCollaborators.map((collaborator: Collaborator) => (
          <MenuItem key={collaborator._id} value={collaborator._id}>
            <CustomAvatar
              {...getCollaboratorAvatarPropsById(
                activeCollaborators,
                collaborator._id
              )}
            />
            {collaborator.col_code}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};
