import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, SelectChangeEvent } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../../hooks/";
import { ActivityRegistersTable } from "./";
import { CollaboratorSelect, CustomDayPicker } from "../../../../components";
import { fetchActivityRegisters } from "../../../../store/thunks";
import { IActivityRegister } from "../../../../shared/types";
import { HOUR_IN_MILLISECONDS } from "../../../../shared/constants/time.constants";
import {
  getEndOfDate,
  getEndOfTodayDate,
  getStartOfDate,
  getStartOfTodayDate,
} from "../../../../helpers";

export const ActivityRegisterCalculator = () => {
  const dispatch = useAppDispatch();
  const { uid } = useAppSelector((state) => state.authReducer.user!);
  const { activityRegisters } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const [startDate, setStartDate] = useState<Date>(getStartOfTodayDate());
  const [endDate, setEndDate] = useState<Date>(getEndOfTodayDate());
  const [totalHours, setTotalHours] = useState<number>(0);
  const [selectedCollaborator, setSelectedCollaborator] = useState<string>(
    uid!
  );

  useEffect(() => {
    dispatch(
      fetchActivityRegisters({
        collaborator: selectedCollaborator,
        page: 1,
        limit: 200,
        $startingTime: `$gte:${startDate.toISOString()}`,
        $endingTime: `$lte:${endDate.toISOString()}`,
      })
    );
  }, [selectedCollaborator, dispatch, startDate, endDate]);

  useEffect(() => {
    if (activityRegisters.length === 0) return;
    const sumOfDifferences = activityRegisters.reduce(
      (sum: number, activity: IActivityRegister) => {
        const activityStartDate = new Date(activity.startingTime!);
        const activityEndDate = new Date(activity.endingTime!);
        const differenceInMilliseconds =
          activityEndDate.getTime() - activityStartDate.getTime();
        const differenceInHours =
          differenceInMilliseconds / HOUR_IN_MILLISECONDS;
        return sum + differenceInHours;
      },
      0
    );
    setTotalHours(sumOfDifferences);
  }, [activityRegisters]);

  const handleSelectCollaboratorChange = (event: SelectChangeEvent<string>) => {
    setSelectedCollaborator(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h4" marginBottom={2}>
        Activity Register Calculator
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <Box display="flex" justifyContent="center">
            <CollaboratorSelect
              selectedCollaborator={selectedCollaborator}
              handleSelectChange={handleSelectCollaboratorChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box display="flex" justifyContent="center">
            <CustomDayPicker
              label="Start Date"
              selected={startDate}
              setter={(date) => {
                setStartDate(getStartOfDate(date));
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box display="flex" justifyContent="center">
            <CustomDayPicker
              label="End Date"
              selected={endDate}
              setter={(date) => {
                setEndDate(getEndOfDate(date));
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            fontSize: "1.2em",
            textAlign: "center",
            maxWidth: "300px",
            margin: "0 auto",
          }}
        >
          <Typography variant="h4">
            Total Hours: {totalHours.toFixed(2)}
          </Typography>
        </Box>

        <ActivityRegistersTable activityRegisters={activityRegisters} />
      </Box>
    </Box>
  );
};
