import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { Chip, Button, Select, MenuItem } from "@mui/material";
import { Task, TaskTag } from "../../../../../shared/types/Task";
import { getTagColor } from "../../../../../helpers/tasksHelpers";

type Props = {
  task: Task;
  onSave: (updatedTask: Task) => void;
  enumValues: string[];
};

export const EditableTagsField: React.FC<Props> = ({
  task,
  onSave,
  enumValues,
}) => {
  const [tags, setTags] = useState<TaskTag[]>(task.tags!);
  const [newTag, setNewTag] = useState<TaskTag>(TaskTag.General);
  const [isEditable, setIsEditable] = useState(false);

  const handleAddTag = () => {
    setTags([...tags, newTag]);
    setNewTag(TaskTag.General);
  };

  const handleDeleteTag = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleSave = async () => {
    await onSave({ ...task, tags: tags as TaskTag[] });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          {tags.map((tag) => (
            <Chip key={tag} label={tag} onDelete={() => handleDeleteTag(tag)} />
          ))}
          <Select
            value={newTag}
            onChange={(e) => setNewTag(e.target.value as TaskTag)}
          >
            {enumValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleAddTag}>Add Tag</Button>
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          {tags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              variant="outlined"
              size="small"
              style={{
                marginRight: "5px",
                backgroundColor: getTagColor(tag),
              }}
            />
          ))}
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </>
      )}
    </>
  );
};
