import axios from "axios";
import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import { IActivityRegister } from "../shared/types/activity-register.types";
import { ApiResponse, AxiosParams } from "../shared/types";
import { IOption } from "../shared/types/option.types";

const API_URL = "/activity-register";
const API_URL_TYPES = "/activity-register-types";

export class ActivityRegisterService {
  static async getAll(params: AxiosParams): Promise<IActivityRegister[]> {
    const response = await hvpAuthenticatedApi.get(API_URL, { params });
    return response.data.data;
  }

  static async getById(id: string): Promise<IActivityRegister> {
    const response = await hvpAuthenticatedApi.get(`${API_URL}/${id}`);

    return response.data.data;
  }

  static async create(
    activityRegister: IActivityRegister
  ): Promise<IActivityRegister> {
    const response = await axios.post(API_URL, activityRegister);
    return response.data;
  }

  static async update(
    id: number,
    activityRegister: IActivityRegister
  ): Promise<IActivityRegister> {
    const response = await axios.patch(`${API_URL}/${id}`, activityRegister);
    return response.data;
  }

  static async delete(id: number): Promise<void> {
    await axios.delete(`${API_URL}/${id}`);
  }

  static async createActivityRegisterType(
    activityRegisterType: IOption
  ): Promise<IOption> {
    const response = await hvpAuthenticatedApi.post<ApiResponse<IOption>>(
      API_URL_TYPES,
      activityRegisterType
    );

    return response.data.data;
  }

  static async updateActivityRegisterTypes(
    id: string,
    activityRegisterType: IOption
  ): Promise<IOption> {
    const response = await hvpAuthenticatedApi.patch<ApiResponse<IOption>>(
      `${API_URL_TYPES}/${id}`,
      activityRegisterType
    );

    return response.data.data;
  }

  static async deleteActivityRegisterType(id: string): Promise<string> {
    const response = await hvpAuthenticatedApi.delete<ApiResponse<string>>(
      `${API_URL_TYPES}/${id}`
    );

    return response.data.data;
  }
}
