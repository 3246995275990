import {
  Collaborator,
  CollaboratorRole,
} from "../shared/types/CollaboratorsTypes";

export const getCollaboratorAvatarDataById = (
  collaborators: Collaborator[],
  id: string
) => collaborators.find((c) => c._id === id);

export const getCollaboratorImgUrlById = (
  collaborators: Collaborator[],
  id: string
) => collaborators.find((c) => c._id === id)?.imgUrl;

export const getCollaboratorById = (
  collaborators: Collaborator[],
  id: string
) => collaborators.find((c) => c._id === id);

export const getCollaboratorAvatarPropsById = (
  collaborators: Collaborator[],
  id: string
) => {
  const collaborator = collaborators.find((c) => c._id === id);
  return {
    id: collaborator!._id!,
    firstName: collaborator!.first_name!,
    imgUrl: collaborator!.imgUrl || "",
  };
};

export const getActiveCollaborators = (collaborators: Collaborator[]) =>
  [...collaborators]
    .filter((c) => c.isActive)
    .sort((a, b) => a.col_code.localeCompare(b.col_code));

export const getActiveCollaboratorsIds = (collaborators: Collaborator[]) =>
  [...collaborators]
    .filter((c) => c.isActive)
    .sort((a, b) => a.col_code.localeCompare(b.col_code))
    .map((c) => c._id);

export const getSortedCollaborators = (collaborators: Collaborator[]) =>
  [...collaborators].sort((a, b) => a.col_code.localeCompare(b.col_code));

export const isAuthorizedByRole = (
  role?: CollaboratorRole,
  authorizedRoles: CollaboratorRole[] = [
    CollaboratorRole.admin,
    CollaboratorRole.manager,
  ]
) => {
  if (!role) return false;
  return authorizedRoles.includes(role);
};

export const checkIfManagerOrAdmin = (role: CollaboratorRole) => {
  return isAuthorizedByRole(role, [
    CollaboratorRole.admin,
    CollaboratorRole.manager,
  ]);
};

export const checkIfAdmin = (role: CollaboratorRole) => {
  return isAuthorizedByRole(role, [CollaboratorRole.admin]);
};

// Function to check if the role has the required level or higher
export const hasAuthorization = (
  role?: CollaboratorRole,
  requiredRole: CollaboratorRole = CollaboratorRole.manager
): boolean => {
  if (!role) return false;
  const roleHierarchy = {
    [CollaboratorRole.admin]: 4,
    [CollaboratorRole.manager]: 3,
    [CollaboratorRole.collaborator]: 2,
    [CollaboratorRole.user]: 1,
    [CollaboratorRole.guest]: 0,
  };
  return roleHierarchy[role] >= roleHierarchy[requiredRole];
};
