import React, { useEffect, useState } from "react";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import {
  useAppDispatch,
  useAppSelector,
  useCollaboratorHook,
} from "../../../hooks";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getAuthActivities } from "../../../store/thunks/authActivitiesThunks";
import { CustomAvatar } from "../../../components/avatar/CustomAvatar";
import { formatLocaleDateTime } from "../../../helpers";

export const AuthLogPage = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const dispatch = useAppDispatch();
  const { authActivities, isLoading, totalAuthActivities } = useAppSelector(
    (state) => state.authActivitiesReducer
  );
  const { getCollaboratorById } = useCollaboratorHook();

  useEffect(() => {
    dispatch(getAuthActivities(page, pageSize));
  }, [dispatch, page, pageSize]);

  const columns = [
    {
      field: "user",
      headerName: "User",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CustomAvatar {...getCollaboratorById(params.value)} />
          <span>{getCollaboratorById(params.value).col_code}</span>
        </Box>
      ),
    },
    { field: "activity", headerName: "Activity Type", width: 180 },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      renderCell: (params: GridRenderCellParams) => (
        <span>{formatLocaleDateTime(params.value)}</span>
      ),
    },
  ];

  if (isLoading) return <CircularProgress />;

  return (
    <Box sx={{ height: "90vh", width: "100%" }}>
      <DataGrid
        rows={authActivities}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          setPage(1);
        }}
        pagination
        rowsPerPageOptions={[20, 50, 100]}
        disableSelectionOnClick
        getRowId={(row) => row._id!}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        paginationMode="server" // Use server-side pagination
        rowCount={totalAuthActivities}
        loading={isLoading} // Loading state
      />
    </Box>
  );
};
