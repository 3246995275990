import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BillCreationInfo,
  CustomerRFC,
  FiscalRegime,
  InvoiceUsage,
  PaymentMethod,
} from "../../shared/types";

interface State {
  isInitialLoading: boolean;
  customerRFCs: CustomerRFC[];
  isLoading: boolean;
  invoiceUsages?: InvoiceUsage[];
  fiscalRegimes?: FiscalRegime[];
  paymentMethods?: PaymentMethod[];
  billCreationInfo?: BillCreationInfo;
  billCreationInfos?: BillCreationInfo[];
}

const initialState: State = {
  isInitialLoading: true,
  customerRFCs: [],
  isLoading: true,
  invoiceUsages: [],
  fiscalRegimes: [],
  paymentMethods: [],
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoading = action.payload;
    },

    setCustomerRFCs: (state, action: PayloadAction<CustomerRFC[]>) => {
      state.customerRFCs = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setInvoiceUsages: (state, action: PayloadAction<InvoiceUsage[]>) => {
      state.invoiceUsages = action.payload;
    },
    setFiscalRegimes: (state, action: PayloadAction<FiscalRegime[]>) => {
      state.fiscalRegimes = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethods = action.payload;
    },
    setBillCreationInfo: (state, action: PayloadAction<BillCreationInfo>) => {
      state.billCreationInfo = action.payload;
    },
    setBillCreationInfos: (
      state,
      action: PayloadAction<BillCreationInfo[]>
    ) => {
      state.billCreationInfos = action.payload;
    },
  },
});

export const {
  setInitialLoading,
  setCustomerRFCs,
  setIsLoading,
  setInvoiceUsages,
  setFiscalRegimes,
  setPaymentMethods,
  setBillCreationInfo,
  setBillCreationInfos,
} = billingSlice.actions;
