import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AttendanceRecord } from "../../shared/types";

interface State {
  currentAttendanceRecords: AttendanceRecord[];
  collaboratorAttendanceRecords: AttendanceRecord[];
  lastCollaboratorAttendanceRecord?: AttendanceRecord;
  allAttendanceRecords: AttendanceRecord[];
  isLastCollaboratorAttendanceRecordLoading: boolean;
  isInitialLoading: boolean;
  isCurrentAttendanceRecordsLoading: boolean;
  isCollaboratorAttendanceRecordsLoading: boolean;
  isLoading: boolean;
  attendanceRecord?: AttendanceRecord;
}

const initialState: State = {
  allAttendanceRecords: [],
  collaboratorAttendanceRecords: [],
  currentAttendanceRecords: [],
  isLastCollaboratorAttendanceRecordLoading: true,
  isInitialLoading: true,
  isCurrentAttendanceRecordsLoading: true,
  isCollaboratorAttendanceRecordsLoading: true,
  isLoading: true,
  attendanceRecord: undefined,
};

export const attendanceRecordsSlice = createSlice({
  name: "attendanceRecords",
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoading = action.payload;
    },
    setIsLastCollaboratorAttendanceLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLastCollaboratorAttendanceRecordLoading = action.payload;
    },
    setLastCollaboratorAttendanceRecord: (
      state,
      action: PayloadAction<AttendanceRecord>
    ) => {
      state.lastCollaboratorAttendanceRecord = action.payload;
    },
    addAttendanceRecord: (state, action: PayloadAction<AttendanceRecord>) => {
      state.allAttendanceRecords.push(action.payload);
    },
    setIsCurrentAttendanceRecordLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCurrentAttendanceRecordsLoading = action.payload;
    },
    setCurrentAttendanceRecords: (
      state,
      action: PayloadAction<AttendanceRecord[]>
    ) => {
      state.currentAttendanceRecords = action.payload;
    },
    setIsCollaboratorAttendanceRecordsLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCollaboratorAttendanceRecordsLoading = action.payload;
    },
    setCollaboratorAttendanceRecords: (
      state,
      action: PayloadAction<AttendanceRecord[]>
    ) => {
      state.collaboratorAttendanceRecords = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAttendanceRecord: (state, action: PayloadAction<AttendanceRecord>) => {
      state.attendanceRecord = action.payload;
    },
    setAllAttendanceRecords: (
      state,
      action: PayloadAction<AttendanceRecord[]>
    ) => {
      state.allAttendanceRecords = action.payload;
    },
  },
});

export const {
  setInitialLoading,
  setLastCollaboratorAttendanceRecord,
  addAttendanceRecord,
  setIsLastCollaboratorAttendanceLoading,
  setIsCurrentAttendanceRecordLoading,
  setCurrentAttendanceRecords,
  setIsCollaboratorAttendanceRecordsLoading,
  setCollaboratorAttendanceRecords,
  setAttendanceRecord,
  setIsLoading,
  setAllAttendanceRecords,
} = attendanceRecordsSlice.actions;
