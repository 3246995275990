import { IOption } from "../shared/types/option.types";

export const getOptionLabel = (options: IOption[], value: string) => {
  const option = options.find((option) => option.value === value);
  return option?.label || value;
};

export const createOptionsFromArray = (
  array: any[],
  key: string
): IOption[] => {
  return array.map((item) => ({
    value: item.id,
    label: item[key],
  }));
};
