import { createSlice } from "@reduxjs/toolkit";
import { ISupplier } from "../../shared/types/suppliers.types";
import { supplierThunks } from "../thunks/suppliers.thunks";

interface State {
  suppliers: ISupplier[];
  isLoading: boolean;
}

const initialState: State = {
  suppliers: [],
  isLoading: true,
};

export const suppliersSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(supplierThunks.fetchItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(supplierThunks.fetchItems.fulfilled, (state, action) => {
        state.suppliers = action.payload;
        state.isLoading = false;
      })
      .addCase(supplierThunks.fetchItems.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(supplierThunks.createItem.fulfilled, (state, action) => {
        state.suppliers.push(action.payload);
      })
      .addCase(supplierThunks.updateItem.fulfilled, (state, action) => {
        const index = state.suppliers.findIndex(
          (product) => product.id === action.payload.id
        );
        state.suppliers[index] = action.payload;
      })
      .addCase(supplierThunks.deleteItem.fulfilled, (state, action) => {
        state.suppliers = state.suppliers.filter(
          (product) => product.id !== action.payload
        );
      });
  },
});
