import React from "react";
import { Task, TaskStatus } from "../../../../shared/types/Task";
import { Box, Typography } from "@mui/material";
import { KanbanTask } from "./KanbanTask";
import { Droppable } from "react-beautiful-dnd";

type KanbanColumnProps = {
  tasks: Task[];
  status: TaskStatus;
};
export const KanbanColumn: React.FC<KanbanColumnProps> = ({
  tasks,
  status,
}) => {
  return (
    <Droppable droppableId={status}>
      {(provided, snapshot) => (
        <Box
          key={status}
          minWidth="300px"
          p={1}
          border={1}
          borderColor="#ddd"
          m={0.5}
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            backgroundColor: snapshot.isDraggingOver ? "lightblue" : "inherit",
          }}
        >
          <Typography variant="h6">{status.toUpperCase()}</Typography>

          {tasks.map((task, index) => (
            <KanbanTask key={task._id} task={task} index={index} />
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};
