import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";

import { Outlet } from "react-router-dom";
import { WorkLogsMenu } from "./components/WokLogsMenu";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { startInitialLoading as startInitialLoadingTasks } from "../../../store/thunks/taskThunks";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { startInitialWorkLogsLoading as startInitialLoadingWorkLogs } from "../../../store/thunks/workLogsThunks";

export const WorkLogsLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(startInitialLoadingTasks());
    dispatch(startInitialLoadingWorkLogs());
  }, [dispatch]);

  const { isInitialLoading: isInitialLoadingTasks } = useAppSelector(
    (state) => state.taskReducer
  );
  const { isInitialLoading: isInitialLoadingWorkLogs } = useAppSelector(
    (state) => state.workLogsReducer
  );

  if (isInitialLoadingTasks || isInitialLoadingWorkLogs) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <WorkLogsMenu />
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
