import React from "react";

import { Box } from "@mui/material";
import { DaysWithoutMissingProducts } from "./components/DaysWithoutMissingProducts";

export const HomeDashPage = () => {
  return (
    <Box className="homeDashPage">
      <DaysWithoutMissingProducts />
      {/* <FeaturedInfo></FeaturedInfo>
      <Chart
        title="User Analytics"
        data={userData}
        dataKey="Active User"
        grid
      />
      <div className="homeWidgets">
        <WidgetSmall />
        <WidgetLarge />
      </div> */}
    </Box>
  );
};
