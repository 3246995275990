import { createSlice } from "@reduxjs/toolkit";
import { IControlledPrescription } from "../../shared/types";
import { controlledPrescriptionThunks } from "../thunks";
import { IOption } from "../../shared/types/option.types";

interface State {
  controlledPrescriptions: IControlledPrescription[];
  controlledPrescriptionsStatusOptions: IOption[];
  isLoading: boolean;
}

const initialState: State = {
  controlledPrescriptions: [],
  controlledPrescriptionsStatusOptions: [],
  isLoading: true,
};

export const controlledPrescriptionsSlice = createSlice({
  name: "controlled-prescriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(controlledPrescriptionThunks.fetchItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        controlledPrescriptionThunks.fetchItems.fulfilled,
        (state, action) => {
          state.controlledPrescriptions = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(controlledPrescriptionThunks.fetchItems.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(
        controlledPrescriptionThunks.createItem.fulfilled,
        (state, action) => {
          state.controlledPrescriptions.push(action.payload);
        }
      )
      .addCase(
        controlledPrescriptionThunks.updateItem.fulfilled,
        (state, action) => {
          const index = state.controlledPrescriptions.findIndex(
            (element) => element.id === action.payload.id
          );
          state.controlledPrescriptions[index] = action.payload;
        }
      )
      .addCase(
        controlledPrescriptionThunks.deleteItem.fulfilled,
        (state, action) => {
          state.controlledPrescriptions = state.controlledPrescriptions.filter(
            (element) => element.id !== action.payload
          );
        }
      )
      .addCase(
        controlledPrescriptionThunks.fetchStatusOptions.fulfilled,
        (state, action) => {
          state.controlledPrescriptionsStatusOptions = action.payload;
        }
      );
  },
});
