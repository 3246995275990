import React, { Fragment } from "react";

import { FcmListItem } from "../components/FcmListItem";
import { FcmStep } from "./components/FcmStep";
import { FcmCalculator } from "./components/FcmCalculator";
import { procedureTypes } from "../../../shared/types/types";

export const FCMPedigree = () => {
  return (
    <Fragment>
      <div className="mb-5r">
        <h3 className="heading--secondary u-center u-textPrimary">
          Pedigr� (camadas)
        </h3>
        <div className="separationLine"></div>
      </div>
      <div className="c-card u-bgPrimaryDarkest u-p2r u-mb-5r">
        <h4 className="heading--tertiary u-textWhite">Descripci�n</h4>
        <p className="text-white">
          El tr�mite para la obtenci�n del pedigr� tiene por objeto garantizar
          que una camada pertenece a determinada raza y que posee un m�nimo de
          tres generaciones de antepasados de la misma raza cumpliendo un
          est�ndar determinado.
        </p>
      </div>
      <div className="mb-5r">
        <h4 className="heading--tertiary mb-2r">Requisitos</h4>
        <ul className="">
          {/* todo adjuntar enlace */}
          <FcmListItem text="Llenar el formato electr�nico de certificado de cruza correctamente, a trav�s de este sitio web." />
          <FcmListItem text="Ambos padres deber�n contar con pedigr�. Esto se prueba presentando copia de los pedigr�s internacionales (color azul) de la Federaci�n Can�fila Mexicana. Tambi�n se pueden registrar los perros que tengan antecedentes de tres generaciones con certificado de pureza racial." />
          <FcmListItem text="Los cachorros deber�n ser mayores de 6 semanas y menores de 9 meses. En caso contrario, deber� realizarse el tr�mite de registro extempor�neo." />
          <FcmListItem text="Los propietarios de ambos padres sean socios vigentes de la FCM. Esto se prueba presentando sus credenciales vigentes. En caso de no serlo deber�n darse de alta mediante el procedimiento correspondiente que se puede consultar en este sitio." />
          <FcmListItem text="Comprobar la identidad del o los propietarios, presentando originales y copias de sus credenciales de elector." />
          <FcmListItem text="Informar del n�mero telef�nico para contacto posterior." />
          <FcmListItem text="Informar del correo electr�nico para contacto posterior." />
          <FcmListItem text="Los propietarios de los padres deber�n constar en los pedigr�s respectivos, en caso de haberse endosado previamente sin haberse formalizado el cambio de propietario ante la FCM, se deber� realizar tambi�n el cambio de propietario que se puede consultar en este sitio." />
          <FcmListItem text="En caso de que se presenten documentos internacionales no expedidos por la FCM, deben venir endosados en el pa�s de origen." />
          <FcmListItem text="Pagar las cuotas que correspondan." />
        </ul>
      </div>
      {/* STEPS */}
      <div className="u-mb-5r">
        <h4 className="heading--tertiary u-mb-2r">Procedimiento</h4>
        <FcmStep
          imgSrc={"requirement.png"}
          num="01"
          title="Requisitos"
          desc="Consulta y ten preparados los requisitos que se solicitan, de
              conformidad con este sitio."
          reverse={false}
        />
        <FcmStep
          imgSrc={"format.png"}
          num="02"
          title="Formato"
          desc="Date de alta en este sitio, crea un paquete, llena los formatos respectivos y anexa la documentaci�n solicitada."
          reverse={true}
        />
        <FcmStep
          imgSrc={"appointments.png"}
          num="03"
          title="Agenda una cita"
          desc="Agenda telef�nicamente una cita en nuestra sucursal de Harbor."
          reverse={false}
        />
        <FcmStep
          imgSrc={"veterinarian.png"}
          num="04"
          title="Acude a la cita"
          desc="Acude a la cita con los documentos requeridos y los cachorros."
          reverse={true}
        />
        <FcmStep
          imgSrc={"stethoscope.png"}
          num="05"
          title="Inspecci�n"
          desc="Nuestro m�dico har� la inspecci�n respectiva, as� como la implantaci�n del microchip y el tatuaje. El tatuaje es opcional, pero debe pagarse."
          reverse={false}
        />
        <FcmStep
          imgSrc={"signature.png"}
          num="06"
          title="Firma"
          desc="Se termina de llenar el formato y se firma por el m�dico as� como por los clientes."
          reverse={true}
        />
        <FcmStep
          imgSrc={"money.png"}
          num="07"
          title="Pago"
          desc="Realiza el pago en nuestra caja."
          reverse={false}
        />
        <FcmStep
          imgSrc={"package.png"}
          num="08"
          title="Env�o"
          desc="Nuestro personal enviar� el paquete con los documentos respectivos a la sede de la FCM."
          reverse={true}
        />
        <FcmStep
          imgSrc={"delivery.png"}
          num="09"
          title="Entrega"
          desc="Cuando recibamos los documentos de la FCM, nos comunicaremos para pasar a recogerlos."
          reverse={false}
        />
      </div>
      {/* CALCULATOR */}
      <FcmCalculator recProcedure={procedureTypes.pedigree.value} />
    </Fragment>
  );
};
