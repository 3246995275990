import { Collaborator } from "../shared/types/CollaboratorsTypes";
import {
  CollaboratorTimeOffOverview,
  DateTimeOffRequestCollaborator,
  TimeOffStatus,
} from "../shared/types/TimeOffRequest";

export const convertTimeOffOverviewToDateTimeOffRequests = (
  collaborators: Collaborator[],
  collaboratorsTimeOffsOverview: CollaboratorTimeOffOverview[]
) => {
  if (collaborators.length === 0) return [];

  const timeOffDates: DateTimeOffRequestCollaborator[] = [];

  collaboratorsTimeOffsOverview.forEach((collaboratorTimeOffOverview) => {
    collaboratorTimeOffOverview.dateTimeOffRequests.forEach(
      (dateTimeOffRequest) => {
        const { collaborator, id, status, timeOffType, date } =
          dateTimeOffRequest;
        const foundCollaborator = collaborators?.find(
          (collaboratorDb) => collaboratorDb._id === collaborator
        );

        if (status === TimeOffStatus.canceled) return;

        timeOffDates.push({
          id: id!,
          timeOffType,
          status: status!,
          date: new Date(date),
          collaborator: {
            id: foundCollaborator?._id!,
            name: foundCollaborator?.first_name!,
            imgUrl: foundCollaborator?.imgUrl!,
          },
        });
      }
    );
  });

  const timeOffDatesSorted = timeOffDates.sort(
    (a, b) => a.date.getTime() - b.date.getTime()
  );
  return timeOffDatesSorted;
};

export const getUpcomingAndRecentTimeOffDates = (
  timeOffDates: DateTimeOffRequestCollaborator[]
) => {
  const filteredTimeOffDates = timeOffDates.filter(
    (request) => request.status === TimeOffStatus.approved
  );
  const currentDateTime = new Date();
  const recentTimeOffDates = filteredTimeOffDates
    .filter((request) => request.date < currentDateTime)
    .sort((a, b) => b.date.getTime() - a.date.getTime()) // Sort in descending order
    .slice(0, 10);
  const upcomingTimeOffDates = filteredTimeOffDates
    .filter((request) => request.date >= currentDateTime)
    .slice(0, 10);
  return { recentTimeOffDates, upcomingTimeOffDates };
};
