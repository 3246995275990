import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../hooks";
import { convertMStoHours } from "../../../../helpers";

const StatItem = ({ label, value }: { label: string; value: string }) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="subtitle1">{label}</Typography>
    <Typography variant="body1">{value}</Typography>
  </Grid>
);

export const AcivityRegisterColStats = () => {
  const { isLoading, collaboratorStats } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const renderStats = () => {
    if (isLoading) return <CircularProgress />;
    if (!collaboratorStats)
      return <Typography color="error">Failed to load stats</Typography>;

    const statItems = [
      { label: "Yesterday", value: collaboratorStats.yesterdayHours },
      { label: "This Half-Month", value: collaboratorStats.halfMonthHours },
      { label: "This Month", value: collaboratorStats.monthHours },
      { label: "This Quarter", value: collaboratorStats.quarterHours },
      { label: "This Year", value: collaboratorStats.yearHours },
      { label: "Total", value: collaboratorStats.totalHours },
    ];

    return (
      <Grid container spacing={2}>
        {statItems.map(({ label, value }) => (
          <StatItem
            key={label}
            label={label}
            value={`${convertMStoHours(value)} hours`}
          />
        ))}
      </Grid>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Stats (Registered Hours)
      </Typography>
      {renderStats()}
    </Paper>
  );
};
