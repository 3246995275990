import { Box, Paper, Typography, duration } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {
  DateTimeOffRequestCollaborator,
  TimeOffStatus,
} from "../../../../shared/types/TimeOffRequest";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";

type VacationCalendarDayProps = {
  day: dayjs.Dayjs;
  monthYear: {
    month: number;
    year: number;
  };
  timeOffCalendarDayData: DateTimeOffRequestCollaborator[];
};

export const TimeOffsCalendarDay: React.FC<VacationCalendarDayProps> = ({
  day,
  monthYear,
  timeOffCalendarDayData,
}) => {
  const isCurrentMonth =
    monthYear.month === day.month() && monthYear.year === day.year();
  const isToday = day.isSame(dayjs(), "day");

  const hasApprovedTimeOff = timeOffCalendarDayData.some(
    (timeOffData) => timeOffData.status === TimeOffStatus.approved
  );

  const hasPendingTimeOff = timeOffCalendarDayData.some(
    (timeOffData) => timeOffData.status === TimeOffStatus.pending
  );

  const fixedHeight = 80;

  return (
    <Paper
      style={{
        textAlign: "center",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: isToday
          ? "#3498db" // Today's color
          : isCurrentMonth
          ? hasApprovedTimeOff
            ? "#b8e994" // Color if someone has approved time off
            : hasPendingTimeOff
            ? "#f8c291" // Color if someone has pending time off
            : "#ccced9" // Default color if no time off
          : "transparent", // Transparent if not current month
        color: isToday ? "white" : "inherit",
        height: `${fixedHeight}px`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="body1">{day.format("D")}</Typography>
      <Box
        style={{
          display: "flex", // Display avatars in a row
          justifyContent: "center", // Center the avatars horizontally
        }}
      >
        {timeOffCalendarDayData.map((day) => (
          <CustomAvatar
            firstName={day.collaborator.name}
            id={day.collaborator.id}
            imgUrl={day.collaborator.imgUrl}
            key={day.id}
          />
        ))}
      </Box>
    </Paper>
  );
};
