import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

type RemainingVacationDaysProps = {
  isLoading: boolean;
  remainingVacationDays: number;
};
export const RemainingVacationDays: React.FC<RemainingVacationDaysProps> = ({
  isLoading,
  remainingVacationDays,
}) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box margin={4}>
          <Typography variant="h5">
            Remaining Vacation Days:{" "}
            {remainingVacationDays > 0 ? remainingVacationDays : 0}
          </Typography>
        </Box>
      )}
    </>
  );
};
