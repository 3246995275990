import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../../../store/thunks/authThunks";
import { Button, TextField, Typography, Link, Divider } from "@mui/material";
import { useAppDispatch } from "../../../hooks";
import { Link as MuiLink } from "@mui/material";
import { NavLink } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string().required("Required"),
});

export const LoginTab = () => {
  const dispatch = useAppDispatch();

  const handleGoogleLogin = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/collaborator/google-login`,
      "_self"
    );
  };

  return (
    <div className="auth__box-container">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(login(values.email, values.password));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              label="Correo electr�nico"
              name="email"
              type="email"
              fullWidth
              helperText={<ErrorMessage name="email" component="div" />}
            />
            <Field
              as={TextField}
              label="Contrase�a"
              name="password"
              type="password"
              fullWidth
              helperText={<ErrorMessage name="password" component="div" />}
            />
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              className="btn btn-primary col-12 mb-3"
            >
              Submit
            </Button>
            <Typography variant="body2" align="center" className="mb-3">
              <MuiLink
                component={NavLink}
                to="/auth/forgot-password"
                underline="hover"
              >
                �Olvidaste tu contrase�a?
              </MuiLink>
            </Typography>
            <Divider className="mb-3" />
            <Typography variant="body2" align="center" className="mb-3">
              O accede con Google
            </Typography>
            <Button
              onClick={handleGoogleLogin}
              fullWidth
              variant="outlined"
              startIcon={
                <img
                  src="https://cdn-icons-png.flaticon.com/256/300/300221.png"
                  alt="Google button"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              Accede con Google
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
