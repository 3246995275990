export const countByKey = <T>(
  objects: T[],
  keyExtractor: (obj: T) => string
): Record<string, number> =>
  objects.reduce((acc, obj) => {
    const key = keyExtractor(obj);
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

// Example usage:
const tasks = [
  { status: "Backlog" },
  { status: "InProgress" },
  { status: "Backlog" },
  // ... other tasks
];

export const taskStatuses = countByKey(tasks, (task) => task.status);

export const getValueByKey = (
  collection: any[],
  key: string,
  id: string
): any | undefined => {
  // Find the object with the matching ID
  const foundItem = collection.find((item) => item.id === id);

  // Return the value of the specified key, or undefined if not found
  return foundItem ? foundItem[key] : undefined;
};
