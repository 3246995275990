import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const RowFlexBoxContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 1,
      }}
    >
      {children}
    </Box>
  );
};
