import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { getWorkLogForToday } from "../../../../helpers/workLogsHelpers";
import { CreateWorkLog } from "./CreateWorkLog";
import { Box, Button, Typography } from "@mui/material";
import { WorkLog } from "../../../../shared/types/WorkLog";
import { getFormattedMxDate } from "../../../../helpers/dateHelpers";

const emptyWorkLog: WorkLog = {
  logDate: new Date().toISOString(),
  activities: [
    { content: "", relatedTask: "" },
    { content: "", relatedTask: "" },
    { content: "", relatedTask: "" },
    { content: "", relatedTask: "" },
  ],
};

const getEmptyWorkLog = (date: Date): WorkLog => {
  return {
    ...emptyWorkLog,
    logDate: date.toISOString(),
  };
};

export const LastLogView = () => {
  const { workLogs } = useAppSelector((state) => state.workLogsReducer);
  const newCurrentDate = new Date();
  const [currentDate, setcurrentDate] = useState(newCurrentDate);
  const [currentWorkLog, setcurrentWorkLog] = useState<WorkLog>(emptyWorkLog);

  const { uid } = useAppSelector((state) => state.authReducer.user!);

  const handleDateChange = (change: number) => {
    setcurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setHours(0, 0, 0, 0);
      newDate.setDate(newDate.getDate() + change);
      return newDate;
    });
  };

  const handleSetDate = (date: Date) => {
    setcurrentDate(date);
  };

  useEffect(() => {
    let curWorkLog =
      getWorkLogForToday(workLogs, currentDate, uid!) ??
      getEmptyWorkLog(currentDate);

    setcurrentWorkLog(curWorkLog);
  }, [currentDate, workLogs, uid]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin={4}
        gap={4}
      >
        <Button onClick={() => handleDateChange(-1)}>Previous</Button>
        <Typography variant="h4">{getFormattedMxDate(currentDate)}</Typography>
        <Button onClick={() => handleDateChange(1)}>Next</Button>
      </Box>
      <CreateWorkLog
        originalValues={currentWorkLog!}
        onSetDate={handleSetDate}
      />
    </>
  );
};
