import { Branch } from "./Branch";

export interface CustomerRFC {
  _id?: string;
  name: string;
  rfc: string;
  invoice_usage: string;
  fiscal_regime: string;
  postal_code: string;
  email: string;
  phone_number: string;
  isValidated: boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface InvoiceUsage {
  id: string;
  name: string;
  value: string;
  moral: boolean;
  natural: boolean;
}

export interface FiscalRegime {
  id: string;
  name: string;
  value: string;
  moral: boolean;
  natural: boolean;
}

export interface PaymentMethod {
  id: string;
  name: string;
  value: string;
}

export interface BillCreationInfo {
  _id?: string;
  bill_date: string;
  customer_rfc: string;
  branch?: Branch;
  document_number: string;
  status: BillCreationInfoStatus;
  total: number;
  is_documented: boolean;
  payment_method: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export enum BillCreationInfoStatus {
  PENDING = "PENDING",
  DONE = "DONE",
  OBSERVED = "OBSERVED",
  REJECTED = "REJECTED",
}
