import { fireSwalError } from "./swal.helpers";

export const handleError = (error: any) => {
  console.error("Axios Error:", error);

  let errorTitle = "Error al registrar";
  let errorMessage = "";

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorMessage = `Error: ${error.response.data.message || error.message}`;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response from server. Please try again later.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }

  fireSwalError(errorMessage);
};
