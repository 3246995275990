import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { getAllAttendanceRecords } from "../../../../store/thunks/attendanceRecordsThunks";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import { AttendanceRecordsTable } from "./AttendanceRecordsTable";
import { exportToExcel } from "../../../../helpers/xlsxHelpers";
import { Button } from "@mui/material";
import { AttendanceRecord } from "../../../../shared/types";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { Collaborator } from "../../../../shared/types/CollaboratorsTypes";
import { getCollaboratorById } from "../../../../helpers";

export const transformDataForExport = (
  attendanceRecords: AttendanceRecord[],
  collaborators: Collaborator[]
) => {
  const transformedData: any[] = [];
  const excelDateOffset = new Date("1899-12-30T00:00:00Z").getTime();

  attendanceRecords.forEach((record) => {
    if (record.shiftDate && record.startTime) {
      const startTime = new Date(record.startTime);
      const startTimeOffset = new Date(
        startTime.toLocaleString("en-US", { timeZone: "America/Mexico_City" })
      );

      transformedData.push([
        record.clockInBranch,
        getCollaboratorById(collaborators, record.collaborator)?.col_code,
        "Entrada",
        (new Date(record.shiftDate).getTime() - excelDateOffset) /
          (24 * 60 * 60 * 1000),
        (startTimeOffset.getTime() - startTimeOffset.setHours(0, 0, 0, 0)) /
          (24 * 60 * 60 * 1000),
      ]);
    }

    if (record.shiftDate && record.endTime) {
      const endTime = new Date(record.endTime);
      const endTimeOffset = new Date(
        endTime.toLocaleString("en-US", { timeZone: "America/Mexico_City" })
      );

      transformedData.push([
        record.clockOutBranch,
        getCollaboratorById(collaborators, record.collaborator)?.col_code,
        "Salida",
        (new Date(record.shiftDate).getTime() - excelDateOffset) /
          (24 * 60 * 60 * 1000),
        (endTimeOffset.getTime() - endTimeOffset.setHours(0, 0, 0, 0)) /
          (24 * 60 * 60 * 1000),
      ]);
    }
  });

  return transformedData;
};

export const AttendanceRecordsAll = () => {
  const { collaborators } = useCollaboratorHook();

  const handleExportToExcel = () => {
    const transformedData = transformDataForExport(
      attendanceRecords,
      collaborators
    );
    // Call the function to export records to Excel
    exportToExcel(transformedData, "Attendance Records");
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllAttendanceRecords());
  }, [dispatch]);
  const { allAttendanceRecords: attendanceRecords } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  return (
    <>
      <Button onClick={handleExportToExcel} variant="contained" color="primary">
        Export to Excel
      </Button>
      <AttendanceRecordsTable attendanceRecords={attendanceRecords} />
    </>
  );
};
