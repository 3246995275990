import { useAppSelector } from "./useAppSelector";

export const useBillingHook = () => {
  const { customerRFCs, paymentMethods } = useAppSelector(
    (state) => state.billingReducer
  );

  const getCustomerRFCById = (id: string) => {
    return customerRFCs.find((c) => c._id === id);
  };

  const getCustomerRFCNameById = (id: string) => {
    return getCustomerRFCById(id)?.name;
  };

  const getPaymentMethodById = (id: string) => {
    return paymentMethods ? paymentMethods.find((p) => p.id === id) : undefined;
  };

  const getPaymentMethodNameById = (id: string) => {
    return getPaymentMethodById(id)?.name;
  };

  return { getCustomerRFCNameById, getPaymentMethodNameById };
};
