import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProductsForm, ProductsList } from "../../pages/dashboard";

export const ProductRouter = () => {
  return (
    <Routes>
      <Route index element={<ProductsList />} />
      <Route path=":id" element={<ProductsForm />} />
      <Route path="*" element={<ProductsList />} />
    </Routes>
  );
};
