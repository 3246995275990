import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Task } from "../../shared/types/Task";

interface TaskState {
  tasks: Task[];
  isLoading: boolean;
  isInitialLoading: boolean;
}

const initialState: TaskState = {
  tasks: [],
  isLoading: true,
  isInitialLoading: true,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateTask: (state, action: PayloadAction<Task>) => {
      const { tasks } = state;
      const { payload } = action;
      const index = tasks.findIndex((task) => task._id === payload._id);
      if (index === -1) return;
      tasks[index] = payload;
    },
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoading = action.payload;
    },
    addTask: (state, action: PayloadAction<Task>) => {
      state.tasks.push(action.payload);
    },
    deleteTask: (state, action: PayloadAction<string>) => {
      const { tasks } = state;
      const index = tasks.findIndex((task) => task._id === action.payload);
      if (index === -1) return;
      tasks.splice(index, 1);
    },
  },
});

export const {
  setTasks,
  setLoading,
  updateTask,
  setInitialLoading,
  addTask,
  deleteTask,
} = tasksSlice.actions;
