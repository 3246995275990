import React, { Fragment } from "react";

import { FcmListItem } from "../components/FcmListItem";
import { FcmStep } from "./components/FcmStep";
import { FcmCalculator } from "./components/FcmCalculator";
import { procedureTypes } from "../../../shared/types/types";

export const FcmPartnership = () => {
  return (
    <Fragment>
      <div className="mb-5r">
        <h3 className="heading--secondary u-center u-textPrimary">
          Altas y renovaciones de socios
        </h3>
        <div className="separationLine"></div>
      </div>
      <div className="c-card u-bgPrimaryDarkest u-p2r  mb-5r">
        <h4 className="heading--tertiary u-textWhite">Descripci�n</h4>
        <p className="text-white">
          El tr�mite tiene por objeto dar de alta a un socio en la FCM o renovar
          su membres�a, lo que resulta obligatorio para realizar cualquiera de
          los otros tr�mites referidos en este minisitio.
        </p>
      </div>
      <div className="mb-5r">
        <h4 className="heading--tertiary mb-2r">Requisitos</h4>
        <ul className="">
          {/* todo adjuntar enlace */}
          <FcmListItem text="Llenar el formato electr�nico de nuevo socio." />
          <FcmListItem text="Llenar el formato f�sico de nuevo socio. El nuevo socio deber� poner �nicamente su firma en la credencial y en el resguardo de la FCM y en la parte final deber� poner su nombre completo, direcci�n (calle, n�mero, colonia, ciudad, estado y CP), tel�fono y correo electr�nico." />
          <FcmListItem text="Comprobar la identidad del cliente, presentando original y copia de su credencial de elector." />
          <FcmListItem text="Comprobar el domicilio del cliente, presentando original y copia de un comprobante domiciliario." />
          <FcmListItem text="Informar del n�mero telef�nico para contacto posterior." />
          <FcmListItem text="Informar del correo electr�nico para contacto posterior." />
          <FcmListItem text="Pagar las cuotas que correspondan." />
        </ul>
      </div>
      <div className=" mb-5r">
        <h4 className="heading--tertiary mb-2r">Procedimiento</h4>
        <FcmStep
          imgSrc={"requirement.png"}
          num="01"
          title="Requisitos"
          desc="Consulta y ten preparados los requisitos que se solicitan, de
              conformidad con este sitio."
          reverse={false}
        />
        <FcmStep
          imgSrc={"format.png"}
          num="02"
          title="Formato"
          desc="Date de alta en este sitio, crea un paquete, llena los formatos respectivos y anexa la documentaci�n solicitada."
          reverse={true}
        />
        <FcmStep
          imgSrc={"appointments.png"}
          num="03"
          title="Agenda una cita"
          desc="Agenda telef�nicamente una cita en nuestra sucursal de Harbor."
          reverse={false}
        />
        <FcmStep
          imgSrc={"veterinarian.png"}
          num="04"
          title="Acude a la cita"
          desc="Acude a la cita con los documentos requeridos."
          reverse={true}
        />
        <FcmStep
          imgSrc={"signature.png"}
          num="05"
          title="Firma"
          desc="Se termina de llenar el formato y se firma por el cliente."
          reverse={false}
        />
        <FcmStep
          imgSrc={"money.png"}
          num="06"
          title="Pago"
          desc="Realiza el pago en nuestra caja."
          reverse={true}
        />
        <FcmStep
          imgSrc={"package.png"}
          num="07"
          title="Env�o"
          desc="Nuestro personal enviar� el paquete con los documentos respectivos a la sede de la FCM."
          reverse={false}
        />
        <FcmStep
          imgSrc={"delivery.png"}
          num="08"
          title="Entrega"
          desc="Cuando recibamos los documentos de la FCM, nos comunicaremos para pasar a recogerlos."
          reverse={true}
        />
      </div>
      <FcmCalculator recProcedure={procedureTypes.partnership.value} />
    </Fragment>
  );
};
