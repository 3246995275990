import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INotification } from "../../shared/types";
import {
  fetchNotifications,
  markAllNotificationsAsRead,
  readNotification,
} from "../thunks/notificationThunks";

interface State {
  isLoading: boolean;
  notifications: INotification[];
}

const initialState: State = {
  isLoading: true,
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNotifications: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(readNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        readNotification.fulfilled,
        (state, action: PayloadAction<INotification>) => {
          state.isLoading = false;
          state.notifications = state.notifications.map((notification) =>
            notification.id === action.payload.id
              ? action.payload
              : notification
          );
        }
      )
      .addCase(readNotification.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(
        fetchNotifications.fulfilled,
        (state, action: PayloadAction<INotification[]>) => {
          state.isLoading = false;
          state.notifications = action.payload;
        }
      )
      .addCase(fetchNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(markAllNotificationsAsRead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        markAllNotificationsAsRead.fulfilled,
        (state, action: PayloadAction<INotification[]>) => {
          state.isLoading = false;
          state.notifications = action.payload;
        }
      );
  },
});

export const { setLoading, setNotifications } = notificationSlice.actions;
