import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  Paper,
  Box,
  TableRow,
  TableCell,
  Button,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../../../../shared/types/TimeOffRequest";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import { getCollaboratorAvatarDataById } from "../../../../helpers/collaboratorsHelpers";
import { useNavigate } from "react-router-dom";

type TimeOffRequestsListProps = { timeOffRequests: TimeOffRequest[] };
export const TimeOffRequestsList = ({
  timeOffRequests,
}: TimeOffRequestsListProps) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const navigate = useNavigate();

  // Separate pending and approved/rejected requests
  const pendingRequests = timeOffRequests
    .filter((timeOffRequest) => timeOffRequest.status === TimeOffStatus.pending)
    .sort((a, b) => Number(a.requestedAt) - Number(b.requestedAt));

  const resolvedRequests = timeOffRequests
    .filter(
      (timeOffRequest) =>
        timeOffRequest.status === TimeOffStatus.approved ||
        timeOffRequest.status === TimeOffStatus.rejected
    )
    .sort((a, b) => Number(b.requestedAt) - Number(a.requestedAt));

  const canceledRequests = timeOffRequests.filter(
    (timeOffRequest) => timeOffRequest.status === TimeOffStatus.canceled
  );

  const handleClick = (id: string) => {
    navigate(`/dashboard/time-off-requests/${id}`);
  };

  const renderRow = (request: TimeOffRequest) => {
    const collaboratorAvatarData = getCollaboratorAvatarDataById(
      collaborators,
      request.collaborator!
    );
    return (
      <TableRow
        key={request._id}
        style={{
          backgroundColor:
            request.status === TimeOffStatus.approved
              ? "lightgreen"
              : request.status === TimeOffStatus.rejected
              ? "lightcoral"
              : request.status === TimeOffStatus.canceled
              ? "lightgray"
              : "white",
        }}
      >
        <TableCell>
          <CustomAvatar
            key={collaboratorAvatarData!._id}
            id={collaboratorAvatarData!._id}
            firstName={collaboratorAvatarData!.first_name}
            imgUrl={collaboratorAvatarData!.imgUrl!}
          />
        </TableCell>
        <TableCell>
          {new Date(request.requestedAt!).toLocaleDateString()}
        </TableCell>
        <TableCell>{request.requestedDays.length}</TableCell>
        <TableCell>
          {request.requestedDays
            .map((date) => new Date(date).toLocaleDateString())
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .join(", ")}
        </TableCell>
        <TableCell>{request.timeOffType}</TableCell>
        <TableCell>{request.status}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClick(request._id!)}
          >
            <VisibilityIcon />
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableBody>
            {pendingRequests.map((request) => renderRow(request))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableBody>
            {resolvedRequests.map((request) => renderRow(request))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableBody>
            {canceledRequests.map((request) => renderRow(request))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
