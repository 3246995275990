export const sortAlphabeticallyByProperty = (
  property: string,
  array: any[]
) => {
  const arrayCopy = [...array];
  return arrayCopy.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }

    if (a[property] > b[property]) {
      return 1;
    }

    return 0;
  });
};

export const convertArrayToObject = (array: any[], key: string) => {
  return array.reduce((acc, item) => {
    acc[item[key]] = item["value"];
    return acc;
  }, {});
};

interface HasDateProperty {
  [key: string]: any; // Allow indexing with any string
}

export const sortArrayByDateProperty = <T extends HasDateProperty>(
  property: string,
  array: T[],
  order: "asc" | "desc" = "desc" // Default to 'desc'
): T[] => {
  const arrayCopy = [...array];
  return arrayCopy.sort((a, b) => {
    const dateA = new Date(a[property]).getTime();
    const dateB = new Date(b[property]).getTime();

    if (order === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });
};
