import React from "react";
import { Cell, Pie, PieChart, Tooltip, Legend } from "recharts";
import { CustomPieLabel } from "./CustomPieLabel";

type PieChartData = {
  name: string;
  value: number;
  fill: string;
};

type Props = {
  data: PieChartData[];
  width?: number;
};

export const CustomPieChart: React.FC<Props> = ({ data, width = 400 }) => {
  return (
    <PieChart width={width} height={width}>
      <Pie
        dataKey="value"
        isAnimationActive={true}
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={width * 0.4}
        fill="#8884d8"
        labelLine={false}
        label={(props) => <CustomPieLabel {...props} />}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};
