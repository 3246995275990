import React from "react";
import { CollaboratorLog } from "../../../workLogs";
import { Box, Typography } from "@mui/material";
import { getFormattedMxDate } from "../../../../../helpers";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import dayjsWithPlugins from "../../../../../config/dayjs";

type Props = {
  startDate: Date;
  endDate: Date;
};
export const OtherTasks: React.FC<Props> = ({ startDate, endDate }) => {
  const { role } = useAppSelector((state) => state.authReducer.user!);
  const { workLogs } = useAppSelector((state) => state.workLogsReducer);

  console.log({ role });

  if (role === "Colaborador") {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="h6">
          You don't have permission to view this report
        </Typography>
      </Box>
    );
  }
  const filteredWorkLogs = workLogs
    .filter(
      (workLog) =>
        dayjsWithPlugins(workLog.logDate).isSameOrAfter(
          dayjsWithPlugins(startDate)
        ) &&
        dayjsWithPlugins(workLog.logDate).isSameOrBefore(
          dayjsWithPlugins(endDate)
        )
    )
    .map((workLog) => {
      return {
        ...workLog,
        activities: workLog.activities.filter(
          (activity) => !activity.relatedTask
        ),
      };
    })
    .filter((workLog) => workLog.activities.length > 0);

  const sortedWorkLogs = [...filteredWorkLogs].sort((a, b) => {
    return new Date(b.logDate).getTime() - new Date(a.logDate).getTime();
  });
  return (
    <Box>
      {sortedWorkLogs.map((log) => (
        // DATE GROUP
        <Box key={log.logDate} margin={4}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h5">
              {getFormattedMxDate(log.logDate)}
            </Typography>
          </Box>
          <Box>
            <CollaboratorLog log={log} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
