import React from "react";

import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CollaboratorLog } from "../";
import { getFormattedMxDate } from "../../../../helpers/dateHelpers";

export const MyLogs = () => {
  // @ts-ignore
  const { uid } = useAppSelector((state) => state.authReducer.user!);
  const { workLogs } = useAppSelector((state) => state.workLogsReducer);

  const filteredLogs = workLogs.filter((log) => log.createdBy === uid);
  filteredLogs.sort((a, b) => {
    return new Date(b.logDate).getTime() - new Date(a.logDate).getTime();
  });

  return (
    <Box>
      <Typography variant="h4">My Logs</Typography>
      {filteredLogs.map((log) => (
        // DATE GROUP
        <Box key={log.logDate} margin={4}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h5">
              {getFormattedMxDate(log.logDate)}
            </Typography>
          </Box>
          <Box>
            <CollaboratorLog log={log} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
