import React, { Fragment, useState } from "react";

import { DashboardRouter } from "../../routes/DashboardRouter";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Topbar } from "./components/topbar/Topbar";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setMenuView, setSmallScreen } from "../../actions/dbUiActions";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { collaboratorsStartInitialLoading } from "../../actions/collaboratorActions";

export const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { isMenuOpen, isSmallScreen } = useSelector((state) => state.dbUi);
  // Assume loading is true on first render

  const isASmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const { isInitialLoading: isLoadingCollaborators } = useAppSelector(
    (state) => state.collaborator
  );

  // State to track if the initial loading process has started
  const [loadingStarted, setLoadingStarted] = useState(false);
  // State to control rendering based on the loading process
  const [allowRender, setAllowRender] = useState(false);

  useEffect(() => {
    dispatch(setSmallScreen(isASmallScreen));
  }, [isASmallScreen, dispatch]);

  // set the view according both values
  useEffect(() => {
    dispatch(setMenuView(isMenuOpen, isSmallScreen));
  }, [isMenuOpen, isSmallScreen, dispatch]);

  useEffect(() => {
    dispatch(collaboratorsStartInitialLoading());
    setLoadingStarted(true); // Mark that loading has started
  }, [dispatch]);

  useEffect(() => {
    if (loadingStarted && !isLoadingCollaborators) {
      setAllowRender(true); // Allow rendering only after loading has completed
    }
  }, [isLoadingCollaborators, loadingStarted]);

  if (!allowRender) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <Topbar />

      <div className="l-dashboardBottomContainer">
        <Sidebar />
        <div className="l-dashboardMain" style={{ overflow: "hidden" }}>
          <DashboardRouter />
        </div>
      </div>
    </Fragment>
  );
};
