import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL!;

const hvpAuthenticatedApi = axios.create({
  baseURL: baseUrl,
});

hvpAuthenticatedApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") ?? "";
  config.headers["x-token"] = token;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default hvpAuthenticatedApi;
