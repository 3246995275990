import React from "react";

import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../../../../shared/types/TimeOffRequest";
import { CustomPieChart } from "../../../../components/charts/CustomPieChart";
import { generateColors } from "../../../../helpers/colorHelpers";

const getDataForChart = (timeOffRequests: TimeOffRequest[]) => {
  const data = [
    {
      name: TimeOffStatus.approved,
      value: 0,
      fill: generateColors(3)[0],
    },
    { name: TimeOffStatus.pending, value: 0, fill: generateColors(3)[1] },
    { name: TimeOffStatus.rejected, value: 0, fill: generateColors(3)[2] },
  ];

  timeOffRequests.forEach((timeOffRequest) => {
    if (timeOffRequest.status === TimeOffStatus.approved) {
      data[0].value += 1;
    }
    if (timeOffRequest.status === TimeOffStatus.pending) {
      data[1].value += 1;
    }
    if (timeOffRequest.status === TimeOffStatus.rejected) {
      data[2].value += 1;
    }
  });
  return data;
};

export const TimeOffRequestsSummaryOverview = () => {
  const { timeOffRequests } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const lastYearTimeOffRequests = timeOffRequests.filter((timeOffRequest) => {
    const lastYear = new Date(Date.now() - 31536000000); // Adjusted for 365 days
    const timeOffRequestDate = new Date(timeOffRequest.requestedAt!);
    return timeOffRequestDate > lastYear;
  });

  const totalData = getDataForChart(timeOffRequests);
  const lastYearData = getDataForChart(lastYearTimeOffRequests);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h4" component="h2">
        Summary
      </Typography>
      <Box>
        <Typography>Overall</Typography>
        <CustomPieChart data={totalData} width={300} />
      </Box>
      <Box>
        <Typography>Past 12 months</Typography>
        <CustomPieChart data={lastYearData} width={300} />
      </Box>
    </Box>
  );
};
