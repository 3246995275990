import { useState } from "react";
import { Task } from "../shared/types/Task";
import { SelectChangeEvent } from "@mui/material";

export const useSortTasksHook = (tasks: Task[]) => {
  const [sortBy, setSortBy] = useState<keyof Task>("number");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSortByChange = (event: SelectChangeEvent<string>) => {
    setSortBy(event.target.value as keyof Task);
  };

  const handleSortOrderChange = (event: SelectChangeEvent<string>) => {
    setSortOrder(event.target.value as "asc" | "desc");
  };

  return { sortBy, sortOrder, handleSortByChange, handleSortOrderChange };
};
