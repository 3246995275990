import React from "react";
import { Task, TaskStatus } from "../../../../../shared/types/Task";
import dayjsWithPlugins from "../../../../../config/dayjs";
import { TaskListCard } from "../TaskListCard";

type Props = { tasks: Task[]; startDate: Date; endDate: Date };
export const ReportCompletedTasks: React.FC<Props> = ({
  tasks,
  startDate,
  endDate,
}) => {
  const completedTasks = tasks.filter((task) => {
    if (task.status !== TaskStatus.Completed || !task.completedAt) {
      return false;
    }

    const completedAtDate = dayjsWithPlugins(task.completedAt);
    return (
      completedAtDate.isSameOrAfter(dayjsWithPlugins(startDate)) &&
      completedAtDate.isSameOrBefore(dayjsWithPlugins(endDate))
    );
  });
  return (
    <>
      {completedTasks.map((task) => (
        <TaskListCard
          task={task}
          showActivities={true}
          startDate={startDate}
          endDate={endDate}
        ></TaskListCard>
      ))}
    </>
  );
};
