import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { Checkbox, Button, Box } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  field: keyof Task;
  onSave: (updatedTask: Task) => void;
  fontColor?: string;
};

export const EditableCheckboxField: React.FC<Props> = ({
  task,
  field,
  onSave,
  fontColor,
}) => {
  const [editedValue, setEditedValue] = useState<boolean>(
    task[field] as boolean
  );
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedValue(event.target.checked);
  };

  const handleSave = async () => {
    if (editedValue === undefined) {
      fireSwalError("The status must be set");
      setIsEditable(false);

      return;
    }
    await onSave({ ...task, [field]: editedValue });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          <Checkbox checked={editedValue} onChange={handleChange} />
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          <Box component="span" sx={{ color: fontColor }}>
            {String(task[field])}
          </Box>
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </>
      )}
    </>
  );
};
