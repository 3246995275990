import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import { IControlledPrescription } from "../shared/types";
import { IOption } from "../shared/types/option.types";
import { BaseService } from "./";

export class ControlledPrescriptionService extends BaseService<IControlledPrescription> {
  constructor() {
    super("/controlled-prescriptions");
  }

  async getStatusOptions(): Promise<IOption[]> {
    const response = await hvpAuthenticatedApi.get(
      `${this.API_URL}/status-options`
    );
    return response.data.data;
  }
}
