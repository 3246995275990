import { DayPicker } from "react-day-picker";
import React, { useCallback } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Task,
  TaskPriority,
  TaskStatus,
  TaskTag,
} from "../../../../shared/types/Task";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Select,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { Collaborator } from "../../../../shared/types/CollaboratorsTypes";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import {
  getActiveCollaborators,
  getCollaboratorAvatarPropsById,
} from "../../../../helpers/collaboratorsHelpers";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { startSavingTask } from "../../../../store/thunks/taskThunks";
import { useNavigate } from "react-router-dom";

const TaskSchema: Yup.SchemaOf<Task> = Yup.object().shape({
  _id: Yup.string(),
  number: Yup.number().notRequired(),
  title: Yup.string().required("Required"),
  createdAt: Yup.string(),
  author: Yup.string().required("Required"),
  dueDate: Yup.string(),
  completedAt: Yup.string(),
  priority: Yup.mixed<TaskPriority>()
    .oneOf(Object.values(TaskPriority))
    .required("Required"),
  assignees: Yup.array().of(Yup.string()).notRequired(),
  status: Yup.mixed<TaskStatus>().required("Required"),
  description: Yup.string(),
  createdBy: Yup.string(),
  updatedAt: Yup.string(),
  updatedBy: Yup.string(),
  completionCriteria: Yup.array().of(Yup.string()).notRequired(),
  activities: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string(),
        createdAt: Yup.string().required("Required"),
        createdBy: Yup.string().required("Required"),
        content: Yup.string().required("Required"),
      })
    )
    .notRequired(),
  notes: Yup.string(),
  requestedAt: Yup.string(),
  tags: Yup.mixed<TaskTag[]>(),
  isRestrictedView: Yup.boolean().required("Required"),
});

export const CreateTask = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { uid } = useAppSelector((state) => state.authReducer.user!);
  const initialValues: Task = {
    title: "",
    priority: TaskPriority.Low,
    status: TaskStatus.Backlog,
    author: uid!,
    assignees: [uid!],
    dueDate: "",
    completedAt: "",
    description: "",
    completionCriteria: [""],
    activities: [],
    notes: "",
    requestedAt: new Date().toISOString(),
    tags: [],
    isRestrictedView: false,
  };

  const handleSubmit = useCallback(
    async (values: Task) => {
      console.log("here", { values });
      const taskId = await dispatch(startSavingTask(values));
      console.log({ taskId });
      navigate(`/dashboard/tasks/${taskId}`); // Updated path
    },
    [dispatch, navigate]
  );

  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const activeCollaborators = getActiveCollaborators(collaborators);

  return (
    <Box>
      <Typography variant="h4">Create a Task</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={TaskSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values, handleChange }) => {
          return (
            <Form>
              <Paper elevation={3} sx={{ margin: 2, padding: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="author-label">Author</FormLabel>
                      <Field
                        as={Select}
                        labelId="author-label"
                        id="author"
                        name="author"
                        error={Boolean(errors.author)}
                        input={<OutlinedInput label="Author*" />}
                      >
                        {activeCollaborators.map(
                          (collaborator: Collaborator) => {
                            return (
                              <MenuItem
                                key={collaborator._id}
                                value={collaborator._id}
                              >
                                <CustomAvatar
                                  {...getCollaboratorAvatarPropsById(
                                    collaborators,
                                    collaborator._id
                                  )}
                                />
                                {collaborator.col_code}
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      id="title"
                      name="title"
                      label="Title*"
                      error={touched.title && Boolean(errors.title)}
                      helperText={errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="status-label">Requested At*</FormLabel>
                      <Field
                        as={DayPicker}
                        labelId="requestedAt-label"
                        name="requestedAt"
                        selected={new Date(values.requestedAt!)}
                        onSelect={(date: Date) => {
                          setFieldValue("requestedAt", date.toISOString());
                        }}
                        dateFormat="yyyy-MM-dd"
                        mode="single"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="status-label">Status*</FormLabel>
                      <Field
                        as={Select}
                        labelId="status-label"
                        id="status"
                        name="status"
                        error={Boolean(touched.status && errors.status)}
                        input={<OutlinedInput label="Status" />}
                      >
                        {Object.entries(TaskStatus).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="priority-label">Priority*</FormLabel>
                      <Field
                        as={Select}
                        labelId="priority-label"
                        id="priority"
                        name="priority"
                        error={Boolean(touched.priority && errors.priority)}
                        input={<OutlinedInput label="Priority" />}
                      >
                        {Object.entries(TaskPriority).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="tags-label">Tags</FormLabel>
                      <Field
                        as={Select}
                        labelId="tags-label"
                        id="tags"
                        name="tags"
                        multiple
                        error={Boolean(touched.tags && errors.tags)}
                        input={<OutlinedInput label="Tags" />}
                      >
                        {Object.entries(TaskTag).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="isRestrictedView"
                          color="primary"
                        />
                      }
                      label="Is Restricted View"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      id="description"
                      name="description"
                      label="Description"
                      error={touched.description && Boolean(errors.description)}
                      helperText={errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="assignees-label">Assignees</FormLabel>
                      <Field
                        as={Select}
                        labelId="assignees-label"
                        id="assignees"
                        name="assignees"
                        multiple
                        error={Boolean(errors.assignees)}
                        input={<OutlinedInput label="Assignees" />}
                      >
                        {activeCollaborators.map(
                          (collaborator: Collaborator) => {
                            return (
                              <MenuItem
                                key={collaborator._id}
                                value={collaborator._id}
                              >
                                <CustomAvatar
                                  {...getCollaboratorAvatarPropsById(
                                    collaborators,
                                    collaborator._id
                                  )}
                                />
                                {collaborator.col_code}
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="status-label">Due Date</FormLabel>
                      <Field
                        as={DayPicker}
                        name="dueDate"
                        selected={new Date(values.dueDate!)}
                        onSelect={(date: Date) => {
                          setFieldValue("dueDate", date.toISOString());
                        }}
                        dateFormat="yyyy-MM-dd"
                        mode="single"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Completed At</FormLabel>
                      <Field
                        as={DayPicker}
                        name="completedAt"
                        selected={new Date(values.completedAt!)}
                        onSelect={(date: Date) => {
                          setFieldValue("completedAt", date.toISOString());
                        }}
                        dateFormat="yyyy-MM-dd"
                        mode="single"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Completion Criteria</FormLabel>
                      <FieldArray
                        name="completionCriteria"
                        render={(arrayHelpers) => (
                          <Box>
                            {values.completionCriteria &&
                            values.completionCriteria.length > 0 ? (
                              values.completionCriteria.map(
                                (criteria, index) => (
                                  <Box key={index}>
                                    <Field
                                      as={TextField}
                                      name={`completionCriteria.${index}`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                    <IconButton
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleOutlineIcon />
                                    </IconButton>
                                    <IconButton
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      }
                                    >
                                      <AddCircleOutlineIcon />
                                    </IconButton>
                                  </Box>
                                )
                              )
                            ) : (
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.push("")}
                                startIcon={<AddCircleOutlineIcon />}
                              >
                                Add a criteria
                              </Button>
                            )}
                          </Box>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      id="notes"
                      name="notes"
                      label="notes"
                      error={touched.notes && Boolean(errors.notes)}
                      helperText={errors.notes}
                      multiline
                      rows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
