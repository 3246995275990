import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CollaboratorSelect } from "../../../../components";
import { DatePicker } from "@mui/x-date-pickers";
import { useAppDispatch, useAppSelector, useAuthHook } from "../../../../hooks";
import { fetchActivityRegisters } from "../../../../store/thunks";
import { ActivityRegistersTable } from "./ActivityRegistersTable";
import { sortArrayByDateProperty } from "../../../../helpers";
import dayjs from "dayjs";

export const ActivityRegisterAll = () => {
  const dispatch = useAppDispatch();
  const { activityRegisters } = useAppSelector(
    (state) => state.activityRegisterReducer
  );
  const { currentUserId } = useAuthHook();
  const [collaborator, setCollaborator] = useState<string | undefined>(
    currentUserId
  );
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    dayjs().subtract(1, "week")
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs());

  console.log("activityRegistersAll", { collaborator, startDate, endDate });

  useEffect(() => {
    if (!collaborator && !startDate && !endDate) return;
    dispatch(
      fetchActivityRegisters({
        collaborator,
        $startingTime: startDate
          ? `$gte:${startDate.toISOString()}`
          : undefined,
        $endingTime: endDate ? `$lte:${endDate.toISOString()}` : undefined,
      })
    );
  }, [dispatch, collaborator, startDate, endDate]);

  const sortedActivities = sortArrayByDateProperty(
    "startingTime",
    activityRegisters
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" gap={4} mb={2} px={4}>
        <CollaboratorSelect
          selectedCollaborator={collaborator}
          handleSelectChange={(event) => setCollaborator(event.target.value)}
          sx={{ flex: 1 }}
        />
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          sx={{ flex: 1 }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          sx={{ flex: 1 }}
        />
      </Box>

      <ActivityRegistersTable activityRegisters={sortedActivities} />
    </Box>
  );
};
