import React from "react";
import { DateTimeOffRequestCollaborator } from "../../../../shared/types/TimeOffRequest";
import { Avatar, Box, Chip, Grid, Typography } from "@mui/material";
import { getFormattedMxDate } from "../../../../helpers/dateHelpers";

type DateTimeOffTableProps = {
  dateTimeOffRequests: DateTimeOffRequestCollaborator[];
};
export const DateTimeOffTable = ({
  dateTimeOffRequests,
}: DateTimeOffTableProps) => {
  return (
    <Grid container spacing={1}>
      {dateTimeOffRequests.map((request) => (
        <Grid item key={request.id} xs={12}>
          <Chip
            label={
              <Box display="flex" alignItems="center" width="100%">
                <Avatar
                  alt={request.collaborator.name}
                  src={request.collaborator.imgUrl}
                  sx={{ width: 32, height: 32, marginRight: 1 }}
                />
                <Typography variant="body2">
                  {request.timeOffType} - {getFormattedMxDate(request.date)}
                </Typography>
              </Box>
            }
            variant="outlined"
            color="primary"
            style={{ width: "100%" }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
