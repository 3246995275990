import React from "react";
import { Box, Typography } from "@mui/material";

type LabelValuePairProps = {
  label: string;
  value: string;
  fontColor?: string;
  handleEditClick?: () => void;
};
const LabelValuePair: React.FC<LabelValuePairProps> = ({
  label,
  value,
  fontColor,
  handleEditClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 1,
      }}
    >
      <Typography
        variant="body1"
        style={{ marginRight: 2, fontWeight: "bold" }}
      >
        {label}:
      </Typography>
      <Typography variant="body1" style={{ color: fontColor }}>
        {value}
      </Typography>
      <Typography
        onClick={handleEditClick}
        variant="body1"
        style={{ color: fontColor }}
      ></Typography>
    </Box>
  );
};

export default LabelValuePair;
