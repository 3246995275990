import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { roleTypes } from "../../../../shared/types/types";
import {
  Business,
  Badge,
  CleaningServices,
  Grid3x3,
  AccessTime,
  LineStyle,
  PermIdentity,
  WorkOutline,
  MiscellaneousServicesOutlined,
  Article,
  WorkspacePremium,
  BeachAccess,
  Task,
  EditNote,
  WorkHistory,
  MonetizationOn,
  NotificationsNone,
  Inventory,
  Description,
} from "@mui/icons-material";

import { setMenuState } from "../../../../actions/dbUiActions";
import { SidebarLink } from "../SidebarLink";
import { useAppSelector } from "../../../../hooks";
import { RootState } from "../../../../store/store";

export const Sidebar = () => {
  const dispatch = useDispatch();

  const { menuState } = useSelector((state: RootState) => state.dbUi);

  const { role } = useAppSelector((state) => state.authReducer.user!);
  const { isSmallScreen } = useSelector((state: RootState) => state.dbUi);
  const [isAuthorized, setisAuthorized] = useState(false);

  useEffect(() => {
    if (role === roleTypes.admin) {
      setisAuthorized(true);
    }
  }, [role]);

  const handleNavLinkClick = () => {
    if (isSmallScreen) {
      dispatch(setMenuState(false));
    }
  };

  return (
    <div className={`c-sidebar ${menuState}`}>
      <div className="c-sidebar_menu">
        <h3 className="c-sidebar_title">Dashboard</h3>
        <ul className="c-sidebar_linksWrapper">
          <SidebarLink
            link="/dashboard/Home"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<LineStyle className="c-sidebar_icon" />}
            label="Home"
          />
          <SidebarLink
            link="/dashboard/collaborators"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Badge className="db-c-sidebar__icon" />}
            label="Colaboradores"
          />
          <SidebarLink
            link="/dashboard/cleanups/summary"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<CleaningServices className="db-c-sidebar__icon" />}
            label="Limpieza"
          />
          <SidebarLink
            link="/dashboard/rfc"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Grid3x3 className="db-c-sidebar__icon" />}
            label="RFC"
          />
          <SidebarLink
            link="/dashboard/users"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<PermIdentity className="db-c-sidebar__icon" />}
            label="Usuarios"
          />
          <SidebarLink
            link="/dashboard/authLogs"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<AccessTime className="db-c-sidebar__icon" />}
            label="Log de acceso"
          />
          <SidebarLink
            link="/dashboard/activity-register"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<WorkOutline className="db-c-sidebar__icon" />}
            label="Registro de actividades"
          />
          <SidebarLink
            link="/dashboard/misc"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={
              <MiscellaneousServicesOutlined
                className="db-c-sidebar__icon"
                fontSize="large"
              />
            }
            label="Misc"
          />
          <SidebarLink
            link="/dashboard/documentation"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Article className="db-c-sidebar__icon" />}
            label="Documentación"
          />
          <SidebarLink
            link="/dashboard/fcm"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<WorkspacePremium className="db-c-sidebar__icon" />}
            label="FCM"
          />
          <SidebarLink
            link="/dashboard/time-off-requests"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<BeachAccess className="db-c-sidebar__icon" />}
            label="Time off requests"
          />
          <SidebarLink
            link="/dashboard/tasks"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Task className="db-c-sidebar__icon" />}
            label="Tasks"
          />
          <SidebarLink
            link="/dashboard/work-logs"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<EditNote className="db-c-sidebar__icon" />}
            label="Work Logs"
          />
          <SidebarLink
            link="/dashboard/attendance-records"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<WorkHistory className="db-c-sidebar__icon" />}
            label="Attendance Records"
          />
          <SidebarLink
            link="/dashboard/billing"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<MonetizationOn className="db-c-sidebar__icon" />}
            label="Billing"
          />{" "}
          <SidebarLink
            link="/dashboard/notifications"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<NotificationsNone className="db-c-sidebar__icon" />}
            label="Notifications"
          />
          <SidebarLink
            link="/dashboard/products"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Inventory className="db-c-sidebar__icon" />}
            label="Products"
          />
          <SidebarLink
            link="/dashboard/suppliers"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Business className="db-c-sidebar__icon" />}
            label="Suppliers"
          />
          <SidebarLink
            link="/dashboard/controlled-prescriptions"
            handleNavLinkClick={handleNavLinkClick}
            muiIcon={<Description className="db-c-sidebar__icon" />}
            label="Controlled Prescriptions"
          />
        </ul>
      </div>
    </div>
  );
};
