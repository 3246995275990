import { useEffect, useState } from "react";
import { TaskStatus, Task, TaskColumns } from "../shared/types/Task";
import { sortTasks } from "../helpers/tasksHelpers";
import { useAppSelector } from "./useAppSelector";
import { useFilterTasksHook } from "./useFilterTasksHook";
import { DraggableLocation, DropResult } from "react-beautiful-dnd";
import { useAppDispatch } from "./useAppDispatch";
import { startUpdatingTask } from "../store/thunks/taskThunks";

export const useKanban = () => {
  const dispatch = useAppDispatch();

  const { tasks } = useAppSelector((state) => state.taskReducer);
  const { filters, filteredTasks, handleFilterChange } =
    useFilterTasksHook(tasks);

  const sortedTasks = sortTasks(filteredTasks);
  const [columns, setColumns] = useState<TaskColumns>(
    getInitialColumns(sortedTasks)
  );

  useEffect(() => {
    const sortedTasks = sortTasks(filteredTasks);
    setColumns(getInitialColumns(sortedTasks));
  }, [filteredTasks]);

  function getInitialColumns(sortedTasks: Task[]): TaskColumns {
    return Object.keys(TaskStatus).reduce((columnData, key) => {
      const status: TaskStatus = TaskStatus[key as keyof typeof TaskStatus];
      columnData[status] = sortedTasks.filter((task) => task.status === status);
      return columnData;
    }, {} as TaskColumns);
  }

  const onDragEnd = async (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || isSamePosition(source, destination)) {
      return;
    }

    const startColumn = columns[source.droppableId as TaskStatus];
    const finishColumn = columns[destination.droppableId as TaskStatus];

    if (startColumn === finishColumn) {
      handleSameColumnMove(startColumn, source, destination);
    } else {
      handleDifferentColumnMove(startColumn, finishColumn, source, destination);
    }
  };

  const handleSameColumnMove = (
    column: Task[],
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const newTasks = Array.from(column);
    const [movedTask] = newTasks.splice(source.index, 1);
    newTasks.splice(destination.index, 0, movedTask);

    const newColumns = { ...columns, [source.droppableId]: newTasks };
    setColumns(newColumns);
  };

  const handleDifferentColumnMove = (
    startColumn: Task[],
    finishColumn: Task[],
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const movedTask = {
      ...startColumn[source.index],
      status: destination.droppableId as TaskStatus,
    };

    const startColumnTasks = Array.from(startColumn);
    startColumnTasks.splice(source.index, 1);

    const finishColumnTasks = Array.from(finishColumn);
    finishColumnTasks.splice(destination.index, 0, movedTask);

    const newState = {
      ...columns,
      [source.droppableId]: startColumnTasks,
      [destination.droppableId]: finishColumnTasks,
    };

    setColumns(newState);

    updateTaskStatusAndDispatch(movedTask);
  };

  const updateTaskStatusAndDispatch = async (task: Task) => {
    try {
      if (shouldSetCompletedAt(task.status, task.completedAt)) {
        task.completedAt = new Date().toISOString();
      } else {
        task.completedAt = null;
      }

      await dispatch(startUpdatingTask(task, task._id!));
    } catch (error) {
      console.error(error);
    }
  };

  const isSamePosition = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) =>
    destination.droppableId === source.droppableId &&
    destination.index === source.index;

  const shouldSetCompletedAt = (
    status: TaskStatus,
    completedAt: string | null | undefined
  ) =>
    (status === TaskStatus.Completed || status === TaskStatus.Canceled) &&
    !completedAt;

  return { filters, handleFilterChange, columns, onDragEnd };
};
