import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { MainPagesNavbar } from "../pages/mainPages/components/MainPagesNavbar";
import { FCMIndex } from "../pages/mainPages/FCM/FCMIndex";
import { FCMLayout, FCMLayout2 } from "../pages/mainPages/FCM/FCMLayout";
import { MainPageIndex } from "../pages/mainPages/MainPageIndex";

import { MainPagesAdvices } from "../pages/mainPages/MainPagesAdvices";
import { MainPagesServices } from "../pages/mainPages/MainPagesServices";
import { MainPagesServicesCataractSurgery } from "../pages/mainPages/services/MainPagesServicesCataractSurgery";
import { MainPagesServicesConsultation } from "../pages/mainPages/services/MainPagesServicesConsultation";
import { MainPagesServicesFCM } from "../pages/mainPages/services/MainPagesServicesFCM";
import { MainPagesServicesInsemination } from "../pages/mainPages/services/MainPagesServicesInsemination";
import { MainPagesServicesLaboratory } from "../pages/mainPages/services/MainPagesServicesLaboratory";
import { MainPagesServicesModel } from "../pages/mainPages/services/MainPagesServicesModel";
import { MainPagesServicesSurgery } from "../pages/mainPages/services/MainPagesServicesSurgery";
import { FCMRouter } from "./FCMRouter";

export const MainPageServicesRouter = () => {
  return (
    <Routes>
      <Route path="" element={<MainPagesServices />} />
      <Route path="FCM" element={<FCMIndex />} />
      <Route path="FCM/*" element={<FCMLayout />} />
      <Route
        path="consultation"
        element={
          <MainPagesServicesModel
            sectionTitle="Consultas"
            imgName="consulta.jpg"
            content={
              <Fragment>
                <p>
                  Su mascota es recibida en recepci�n por nuestro personal y se
                  verifica si contamos con su expediente en nuestros archivos.
                </p>
                <p>
                  Deber� seleccionar al especialista que lo atender�; en algunas
                  ocasiones, hay que hacer una cita ya en la consulta, se
                  establecen los motivos de la visita y seg�n sea el caso, el
                  especialista le har� preguntas que lo ayuden a orientarse en
                  el diagn�stico. Posteriormente aplicar� las t�cnicas de
                  diagn�stico que as� requiera y le explicar� detalladamente
                  todo lo que acontece, as� como las medidas a seguir que ayuden
                  al diagn�stico o terap�utica correspondiente.
                </p>
                <p>
                  Siempre, se le tomar� el peso en kilogramos, se observar� su
                  excremento al microscopio y se revisar� su calendario de
                  vacunas. Deber� existir confianza y seguridad bilateral (Uds.
                  Y Nosotros), ya que como su mascota no habla, la causa de la
                  enfermedad posiblemente la encontremos en el di�logo; comparta
                  con nosotros sus inquietudes, as� como sus sugerencias y
                  observaciones, nuestra filosof�a de amistad busca en sus
                  clientes relaciones duraderas.
                </p>
              </Fragment>
            }
          />
        }
      />
      <Route
        path="laboratory"
        element={
          <MainPagesServicesModel
            sectionTitle="Laboratorio"
            imgName="lab.jpg"
            content={
              <Fragment>
                <h3>Rayos X</h3>
                <p>
                  Somos una cl�nica de referencia para la toma de Rayos X.
                  Muchos colegas solicitan nuestros servicios como apoyo a sus
                  cl�nicas veterinarias. Esta es una prueba de gabinete muy �til
                  que en cuesti�n de minutos contamos con el revelado y la placa
                  seca, lista para ayudar en el diagn�stico. Podemos ver
                  fracturas, gestaciones avanzadas, objetos en los intestinos
                  que su mascota haya ingerido, c�lculos en los ri�ones o la
                  vejiga, as� como infinidad de patolog�as que nos ayudan a dar
                  diagn�sticos certeros.
                </p>
                <p>
                  Muchas pruebas r�pidas son elaboradas en nuestras
                  instalaciones y los resultados los tenemos en minutos, para
                  as� poder tomar decisiones correctas en la atenci�n de
                  nuestros pacientes; Cuando se requieren pruebas m�s laboriosas
                  contamos con el apoyo de 2 grandes empresas: 1.- Laboratorios
                  Biom�dicos que cuentan con varias sucursales distribuidas por
                  la ciudad, es uno de los mejores laboratorios en M�rida y 2.-
                  Carpemor con sede en el D.F. Laboratorio de Referencia
                  Internacional para Laboratorios y corren cualquier tipo de
                  prueba que no se realice incluso en el pa�s. Como Ud. Podr�
                  apreciar hemos procurado las mejores relaciones para brindarle
                  la mejor opci�n en pruebas de laboratorio. Laboratorios IDEXX
                  est�n especializados en veternaria y nos proporcionan mchos
                  kits de diagn�stico r�pido espec�ficos para muchas de las
                  enfermedades que padecen.
                </p>
                <h3>Laboratorio</h3>
                <p>
                  Muchas pruebas r�pidas son elaboradas en nuestras
                  instalaciones y los resultados los tenemos en minutos, para
                  as� poder tomar decisiones correctas en la atenci�n de
                  nuestros pacientes; Cuando se requieren pruebas m�s laboriosas
                  contamos con el apoyo de 2 grandes empresas: 1.- Laboratorios
                  Biom�dicos que cuentan con varias sucursales distribuidas por
                  la ciudad, es uno de los mejores laboratorios en M�rida y 2.-
                  Carpemor con sede en el D.F. Laboratorio de Referencia
                  Internacional para Laboratorios y corren cualquier tipo de
                  prueba que no se realice incluso en el pa�s. Como Ud. Podr�
                  apreciar hemos procurado las mejores relaciones para brindarle
                  la mejor opci�n en pruebas de laboratorio. Laboratorios IDEXX
                  est�n especializados en veternaria y nos proporcionan mchos
                  kits de diagn�stico r�pido espec�ficos para muchas de las
                  enfermedades que padecen.
                </p>
              </Fragment>
            }
          />
        }
      />

      <Route
        path="surgery"
        element={
          <MainPagesServicesModel
            sectionTitle="Cirug�as"
            imgName="surgery.jpg"
            content={
              <Fragment>
                <p>
                  Las cirug�as en la gran mayor�a de los casos, no son
                  urgencias, por lo tanto, se requiere de programaci�n y
                  preparaci�n del paciente, as� como una autorizaci�n escrita
                  por parte del propietario y los an�lisis de laboratorio que se
                  requieran de acuerdo al caso. Debe tener ayuno de s�lidos y/o
                  l�quidos un tiempo previo, el cu�l se le indicar�. Empleamos
                  los equipos m�s modernos de anestesia con ox�geno y
                  fulguraci�n (coagulaci�n sangu�nea y corte), as� como los
                  monitores electr�nicos que nos indican los signos vitales de
                  nuestros pacientes durante el proceso quir�rgico. Tenga Ud. La
                  plena confianza que su mascota est� en las mejores manos y que
                  tratamos de tener el nivel tecnol�gico y de actualizaci�n m�s
                  avanzado.
                </p>
                <p>
                  La �reas de especializaci�n son: Cirug�a General, Est�tica,
                  Reproductiva o ginecol�gica (Ces�reas, partos), Traumatolog�a
                  y Ortopedia, Urol�gica, Oncol�gica y Oftalmol�gica, con alta
                  experiencia en cataratas con m�nima invasi�n. Ejemplos de
                  cirug�as: General: Tumores cut�neos, heridas en general,
                  Prolapsos, Hernias, etc. Reproductiva: Ovario histerectom�a
                  (Castraci�n en hembras), Orquiectom�a (Castraci�n en machos),
                  Vasectom�a, Ces�reas, etc. Gastrointestinal: Obstrucci�n
                  intestinal, por cuerpos extra�os, Gastrotom�as, Enterotom�as,
                  Anastomosis intestinales, Torsiones intestinales, etc.
                  Urol�gica: C�lculos Urinarios, Uretrostom�a, Nefrectom�as y
                  Nefrotom�as, etc. Traumatolog�a y Ortopedia: Reparaci�n de
                  Fracturas de los diversos huesos del cuerpo y articulaciones.
                  Oftalmol�gica: Defectos en P�rpados y C�rnea, Cataratas con
                  Facoemulsificaci�n (una peque�a incisi�n de 3 mm) con
                  microscopio oftalmol�gico. Etc. Odontol�gica: Extracci�n y
                  limpieza de piezas dentales con equipo ultras�nico, y mucho
                  m�s.
                </p>
              </Fragment>
            }
          />
        }
      />

      <Route
        path="insemination"
        element={
          <MainPagesServicesModel
            sectionTitle="Inseminaci�n artificial"
            imgName="insemin.jpg"
            content={
              <Fragment>
                <p>
                  Este servicio, ha cobrado mucha popularidad en nuestros d�as,
                  pues la causa m�s com�n de infertilidad se debe a problemas
                  conductuales (la hembra no se deja montar) y la Inseminaci�n
                  es la mejor opci�n para corregir el problema.
                </p>

                <p>
                  Es importante partir de una consulta de la hembra a reproducir
                  para que a trav�s de una citolog�a se determine el momento
                  ideal para inocular el semen del reproductor, por lo general
                  el servicio de inseminaci�n incluye 3 visitas que constan cada
                  una en:
                </p>

                <ul className="list">
                  <li>Revisi�n del macho y de la hembra</li>
                  <li>Citolog�a vaginal</li>
                  <li>Recolecci�n del semen</li>
                  <li>Espermatobioscopia (valoraci�n del semen)</li>
                  <li>
                    Inseminaci�n artificial (inoculaci�n vaginal del semen)
                  </li>
                </ul>

                <p>
                  Esta t�cnica permite que hembras muy nerviosas y dominantes,
                  agresivas con problemas de conducta que reh�san el
                  apareamiento logren ser cruzadas en forma artificial; tambi�n
                  en perros muy t�midos de car�cter agresivo, de menor tama�o
                  que la hembra, que no pueden cruzarse, se recomienda una
                  inseminaci�,n. En conclusi�n si su mascotas no pueden tener
                  una monta natural lo mejor ser� realizar una INSEMINACION
                  ARTIFICIAL. El porcentaje de �xito es superior al 95% asegura
                  un n�mero mayor de cachorros, sabemos la fecha probable de
                  parto y se controla mejor toda la gestaci�n. Los materiales
                  usados son esterilizados y se emplea limpieza e higiene en el
                  procedimiento, pueden estar presentes el o due�os de las
                  mascotas. No pierdas nuevamente el celo de tu mascota. Nota: A
                  los sementales les encanta este tipo de visitas, ya que la
                  hembra produce feromonas (sustancias qu�,micas que eliminan al
                  aire cuando est�n en celo) y ellos las perciben a largas
                  distancias, los tratamos con cari�o y ellos siempre son
                  agradecidos.
                </p>
              </Fragment>
            }
          />
        }
      />
      <Route
        path="cataractSurgery"
        element={
          <MainPagesServicesModel
            sectionTitle="Cirug�a de cataratas"
            imgName="catarata.jpg"
            content={
              <Fragment>
                <p>
                  Es una t�cnica moderna con equipo de alta especialidad que con
                  una incisi�n de 3 mil�metros se extrae la catarata devolviendo
                  la vista a ejemplares que la hab�an perdido, se recomienda
                  valorar al paciente antes, pues existen algunas que por sus
                  caracter�sticas no son operables, pues reflejan otro tipo de
                  patolog�as; somos �nicos en el sureste que ha desarrollado
                  esta implementaci�n y se requiere de propietarios
                  comprometidos con la aplicaci�n de gotas post-cirug�a y
                  pacientes d�ciles que no se rasquen en exceso y que se dejen
                  medicar, por lo general los propietarios que viven con sus
                  mascotas en el interior de la casa, son buenos candidatos a
                  este tipo de cirug�as. Las cataratas se producen cuando una
                  lente que existe dentro del ojo llamada cristalino que en
                  condiciones normales es transparente se vuelve opaca. Las
                  causas pueden ser varias como un traumatismo, metab�licas pero
                  la causa m�s frecuente es la debida al envejecimiento de
                  nuestro perro. Es muy frecuente que veamos una opacidad de
                  color blanquecino a partir de los 7-8 a�os de nuestro animal
                  sobre todo cuando le incide directamente una luz como una
                  linterna.
                </p>
              </Fragment>
            }
          />
        }
      />
    </Routes>
  );
};
