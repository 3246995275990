import { createAsyncThunk } from "@reduxjs/toolkit";

import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";
import { ApiResponse, INotification } from "../../shared/types";
import { fireSwalError } from "../../helpers";
import { RootState } from "../store";

const notificationsRoute = "/notifications";

export const readNotification = createAsyncThunk(
  "notification/readNotification",
  async (notification: INotification, { rejectWithValue }) => {
    try {
      const { data } = await hvpAuthenticatedApi.patch<
        ApiResponse<INotification>
      >(`${notificationsRoute}/${notification.id}`, notification);

      return data.data;
    } catch (error) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchNotifications = createAsyncThunk<INotification[]>(
  "notification/fetchNotifications",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { authReducer } = getState() as RootState;

      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<INotification[]>
      >(`${notificationsRoute}?user=${authReducer.user!.uid}`);

      return data.data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const markAllNotificationsAsRead = createAsyncThunk(
  "notification/markAllNotificationsAsRead",
  async (notifications: Partial<INotification>[], { rejectWithValue }) => {
    try {
      const { data } = await hvpAuthenticatedApi.patch<
        ApiResponse<INotification[]>
      >(`${notificationsRoute}/`, notifications);

      return data.data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

const handleError = (error: any) => {
  console.error("Axios Error:", error);

  let errorTitle = "Error al registrar";
  let errorMessage = "";

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorMessage = `Error: ${error.response.data.message || error.message}`;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response from server. Please try again later.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }

  fireSwalError(errorMessage);
};
