import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";

type Props = {
  date: Date;
  label: string;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
};
export const CustomDateField: React.FC<Props> = ({ date, label, setDate }) => {
  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (!date) return;
    setDate(dayjs(date).startOf("day").toDate());
  };

  const handleDateChangeByDays = (change: number) => {
    setDate((prevDate) => {
      const newDate = dayjs(prevDate)
        .add(change, "day")
        .startOf("day")
        .toDate();
      return newDate;
    });
  };
  return (
    <>
      <Button onClick={() => handleDateChangeByDays(-1)}>
        <NavigateBefore />
      </Button>
      <DateField
        label={label}
        value={dayjs(date)}
        onChange={handleDateChange}
        format="MM-DD-YYYY"
      />
      <Button onClick={() => handleDateChangeByDays(1)}>
        <NavigateNext />
      </Button>
    </>
  );
};
