import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { useBillingHook } from "../../../../hooks/useBillingHook";
import {
  BillCreationInfo,
  BillCreationInfoStatus,
} from "../../../../shared/types";

type Props = {
  billCreationInfos: BillCreationInfo[];
};
export const BillTable = ({ billCreationInfos: sortedBills }: Props) => {
  const navigate = useNavigate();
  const handleRowClick = (id: string) => {
    navigate(`/dashboard/billing/create-bill/${id}`);
  };
  const { getCustomerRFCNameById, getPaymentMethodNameById } = useBillingHook();

  const getRowStyle = (bill: BillCreationInfo) => {
    const today = new Date();
    const billDate = new Date(bill.bill_date);
    const daysDifference = Math.floor(
      (today.getTime() - billDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (bill.status === BillCreationInfoStatus.DONE) {
      return { backgroundColor: "green", cursor: "pointer" };
    } else if (
      bill.status === BillCreationInfoStatus.PENDING &&
      daysDifference > 3
    ) {
      return { backgroundColor: "red", cursor: "pointer" };
    } else if (bill.status === BillCreationInfoStatus.PENDING) {
      return { backgroundColor: "yellow", cursor: "pointer" };
    }
    return { cursor: "pointer" }; // default style
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Customer RFC</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>Document Number</TableCell>
            <TableCell>Payment Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedBills.map((bill) => (
            <TableRow
              key={bill._id}
              onClick={() => handleRowClick(bill._id!)}
              style={getRowStyle(bill)}
            >
              <TableCell>
                {new Date(bill.bill_date).toLocaleDateString()}
              </TableCell>
              <TableCell>{getCustomerRFCNameById(bill.customer_rfc)}</TableCell>
              <TableCell>{bill.branch}</TableCell>
              <TableCell>{bill.document_number}</TableCell>
              <TableCell>
                {getPaymentMethodNameById(bill.payment_method)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
