import React from "react";
import { Route, Routes } from "react-router-dom";
import { SuppliersForm } from "../../pages/dashboard/suppliers";
import {
  ControlledPrescriptionsForm,
  ControlledPrescriptionsList,
} from "../../pages/dashboard/controlled-prescriptions";

export const ControlledPrescriptionRouter = () => {
  return (
    <Routes>
      <Route index element={<ControlledPrescriptionsList />} />
      <Route path=":id" element={<ControlledPrescriptionsForm />} />
      <Route path="*" element={<ControlledPrescriptionsList />} />
    </Routes>
  );
};
