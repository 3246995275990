import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { TimeOffRequestsList } from "./TimeOffRequestsList";
import { Box, Typography } from "@mui/material";
import { TimeOffRequestsFilters } from "./TimeOffRequestsFilters";

export const TimeOffRequestsAll = () => {
  const { timeOffRequests } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const [filters, setFilters] = useState({
    collaborator: "",
    status: "",
    type: "",
  });

  const filteredTimeOffRequests = useMemo(() => {
    return timeOffRequests
      .filter((timeOffReq) =>
        filters.collaborator
          ? timeOffReq.collaborator!.includes(filters.collaborator)
          : true
      )
      .filter((timeOffReq) =>
        filters.status ? timeOffReq.status === filters.status : true
      )
      .filter((timeOffReq) =>
        filters.type ? timeOffReq.timeOffType === filters.type : true
      );
  }, [timeOffRequests, filters]);

  const handleFilterChange = (filterType: string, value: string) => {
    setFilters({ ...filters, [filterType]: value });
  };

  return (
    <Box>
      <Box marginBottom={4}>
        {/* FILTERS */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={1}
          width="100%"
        >
          <Typography variant="h5" align="center">
            Filters
          </Typography>
          <Box width="100%">
            <TimeOffRequestsFilters
              filters={filters}
              handleFilterChange={handleFilterChange}
            />
          </Box>
        </Box>
      </Box>
      <TimeOffRequestsList timeOffRequests={filteredTimeOffRequests} />
    </Box>
  );
};
