import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthActivity } from "../../shared/types";
import { set } from "lodash";

interface State {
  isLoading: boolean;
  authActivities: AuthActivity[];
  totalAuthActivities?: number;
}

const initialState: State = {
  isLoading: true,
  authActivities: [],
  totalAuthActivities: 0,
};

export const authActivitiesSlice = createSlice({
  name: "authActivities",
  initialState,
  reducers: {
    setAuthActivities: (state, action: PayloadAction<AuthActivity[]>) => {
      state.authActivities = action.payload;
      state.isLoading = false;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalAuthActivities: (state, action: PayloadAction<number>) => {
      state.totalAuthActivities = action.payload;
    },
  },
});

export const { setAuthActivities, setLoading, setTotalAuthActivities } =
  authActivitiesSlice.actions;
