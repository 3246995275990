import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import { useAppSelector } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

interface NewActivityCardProps {
  onContinueLastActivity: () => void;
  onCreateNewActivity: () => void;
}

export const ActivityRegisterNew: React.FC<NewActivityCardProps> = ({
  onContinueLastActivity,
  onCreateNewActivity,
}) => {
  const { currentRegister } = useAppSelector(
    (state) => state.activityRegisterReducer
  );
  const navigate = useNavigate();

  return (
    <Card className="mb-3r">
      <CardContent>
        <Typography variant="h5" component="h3">
          Create New Activity
        </Typography>
      </CardContent>
      <CardActions>
        {!currentRegister && (
          <Button variant="contained" onClick={onContinueLastActivity}>
            Continue last activity
          </Button>
        )}
        <Button variant="contained" onClick={onCreateNewActivity}>
          Start new activity
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/activity-register/new")}
        >
          Register a concluded activity{" "}
        </Button>
      </CardActions>
    </Card>
  );
};
