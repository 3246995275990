import React, { useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useTimeOffRequestsStore } from "../../../../hooks/useTimeOffRequestsStore";
import { RemainingVacationDays } from "./RemainingVacationDays";
import { TimeOffRequestForm } from "./TimeOffRequestForm";
import { TimeOffType } from "../../../../shared/types/TimeOffRequest";

export const TimeOffRequestFormWrapper: React.FC = () => {
  const [remainingVacationDays, setRemainingVacationDays] = useState(0);
  const [selectedDates, setselectedDates] = useState<Date[]>([]);
  const [currentMonth, setcurrentMonth] = useState<Date>(new Date());
  const [timeOffType, setTimeOffType] = useState<TimeOffType>(
    TimeOffType.vacation
  );

  const { startLoadingCollaboratorTimeOffOverview } = useTimeOffRequestsStore();

  const { isLoading, activeCollaboratorTimeOffOverview } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const handleMonthChange = (newMonth: Date) => {
    setcurrentMonth(newMonth);
  };

  const handleSelectedDatesChange = (dates: Date[]) => {
    setselectedDates(dates);
  };

  useEffect(() => {
    startLoadingCollaboratorTimeOffOverview(currentMonth);

    return () => {};
  }, [currentMonth, startLoadingCollaboratorTimeOffOverview]);

  useEffect(() => {
    if (!activeCollaboratorTimeOffOverview) return;

    setRemainingVacationDays(
      activeCollaboratorTimeOffOverview.remainingVacationDays
    );
  }, [activeCollaboratorTimeOffOverview]);

  useEffect(() => {
    if (!activeCollaboratorTimeOffOverview) return;
    if (timeOffType === TimeOffType.vacation) {
      setRemainingVacationDays(
        activeCollaboratorTimeOffOverview.remainingVacationDays -
          selectedDates.length
      );
    } else {
      setRemainingVacationDays(
        activeCollaboratorTimeOffOverview.remainingVacationDays
      );
    }
  }, [timeOffType, selectedDates, activeCollaboratorTimeOffOverview]);

  return (
    <>
      <RemainingVacationDays
        isLoading={isLoading}
        remainingVacationDays={remainingVacationDays}
      />
      <TimeOffRequestForm
        handleMonthChange={handleMonthChange}
        handleSelectedDatesChange={handleSelectedDatesChange}
        setTimeOffType={setTimeOffType}
      />
    </>
  );
};
