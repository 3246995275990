import { Grid } from "@mui/material";
import React from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import { CollaboratorRole } from "../../../../shared/types/CollaboratorsTypes";
import { useAppSelector } from "../../../../hooks/useAppSelector";

const routes = [
  {
    name: "Main",
    path: "",
  },
  {
    name: "History",
    path: "history",
  },
  {
    name: "Admin Panel",
    path: "admin-panel",
    authorizedRoles: [CollaboratorRole.admin, CollaboratorRole.manager],
  },
];

export const AttendanceRecordsMenu = () => {
  const { role } = useAppSelector((state) => state.authReducer.user!);
  return (
    <Grid container direction="row" spacing={1}>
      {routes.map(
        (route) =>
          // Check if the route has authorizedRoles and if the user's role is included
          (!route.authorizedRoles ||
            (role && route.authorizedRoles.includes(role))) && (
            <Grid key={route.path} item xs={12} md={3}>
              <CustomButton to={route.path}>{route.name}</CustomButton>
            </Grid>
          )
      )}
    </Grid>
  );
};
