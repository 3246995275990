import React, { useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Badge,
  Box,
  Button,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  fetchNotifications,
  markAllNotificationsAsRead,
  readNotification,
} from "../../../store/thunks/notificationThunks";
import { useNavigate } from "react-router-dom";
import {
  createNotificationLink,
  formatMexicanDateTime,
} from "../../../helpers";
import { INotification } from "../../../shared/types";

export const Notifications = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const { notifications } = useAppSelector(
    (state) => state.notificationsReducer
  );

  const sortedNotifications = [...notifications].sort(
    (a, b) =>
      new Date(b.createdAt ?? 0).getTime() -
      new Date(a.createdAt ?? 0).getTime()
  );

  const handleNotificationClick = async (notification: INotification) => {
    console.log(`Notification ${notification.id} clicked`);
    await handleToggleReadStatus(notification);
    const link = createNotificationLink(notification);
    navigate(link);
  };

  const handleToggleReadStatus = (notification: INotification) => {
    return dispatch(
      readNotification({ ...notification, read: !notification.read })
    );
  };

  const handleMarkAllAsRead = () => {
    const notificationsToMark = sortedNotifications.map((notification) => ({
      id: notification.id,
      read: true,
    }));
    dispatch(markAllNotificationsAsRead(notificationsToMark));
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleMarkAllAsRead}
        style={{ marginBottom: "16px" }}
      >
        Mark all as read
      </Button>
      <List>
        {sortedNotifications.map((notification) => (
          <ListItem
            key={notification.id}
            onClick={() => handleNotificationClick(notification)}
            style={{
              backgroundColor: notification.read ? "#f0f0f0" : "#ffffff",
            }} // Change colors as needed
          >
            <ListItemText
              primary={
                <>
                  <Typography variant="subtitle1" component="span">
                    {notification.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    {`: ${notification.message}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    style={{ marginTop: "4px" }}
                  >
                    {formatMexicanDateTime(notification.createdAt!)}
                  </Typography>
                </>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="toggle read status"
                onClick={() => handleToggleReadStatus(notification)}
              >
                {notification.read ? (
                  <MarkunreadIcon />
                ) : (
                  <Badge color="secondary" variant="dot">
                    <MailIcon />
                  </Badge>
                )}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
