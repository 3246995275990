import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { Task } from "../../../../shared/types/Task";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { getCollaboratorImgUrlById } from "../../../../helpers/collaboratorsHelpers";
import { Link } from "react-router-dom";
import {
  getPriorityColor,
  getTagColor,
} from "../../../../helpers/tasksHelpers";
import { Draggable } from "react-beautiful-dnd";

type KanbanTaskProps = { task: Task; index: number };
export const KanbanTask: React.FC<KanbanTaskProps> = ({ task, index }) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const authorAvatar = getCollaboratorImgUrlById(collaborators, task.author);
  // console.log({ index });

  return (
    <Draggable draggableId={task._id!} index={index}>
      {(provided, snapshot) => (
        <Card
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          // $isDragging={snapshot.isDragging}
          key={task._id}
          sx={{
            minWidth: 275,
            marginBottom: 0.5,
            backgroundColor: snapshot.isDragging ? "lightgreen" : "white",
          }}
        >
          <CardHeader
            sx={{ whiteSpace: "pre-line", paddingBottom: 0 }}
            avatar={<Avatar alt={task.author} src={authorAvatar} />}
            title={
              <>
                <Chip
                  style={{
                    backgroundColor: getPriorityColor(task.priority),
                    marginRight: "5px",
                  }}
                  size="small"
                />
                <Link
                  className="task-link"
                  to={`/dashboard/tasks/${task._id}`}
                >{`#${task.number} - ${task.title}`}</Link>
              </>
            }
            titleTypographyProps={{ align: "left" }}
          />
          <CardContent sx={{ whiteSpace: "pre-line" }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {task.description}
            </Typography>
            <Box sx={{ display: "flex", "& > *": { mr: -2, mt: 1, mb: 1 } }}>
              {task.assignees!.map((assignee, index) => (
                <Avatar
                  key={index}
                  alt={assignee}
                  src={getCollaboratorImgUrlById(collaborators, assignee)}
                />
              ))}
            </Box>
            {task.tags!.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                variant="outlined"
                size="small"
                style={{
                  marginRight: "5px",
                  backgroundColor: getTagColor(tag),
                }}
              />
            ))}
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};
