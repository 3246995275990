import { Grid } from "@mui/material";
import React from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CollaboratorRole } from "../../../../shared/types/CollaboratorsTypes";

const vacationsRoutes = [
  {
    name: "Home",
    path: "",
  },
  {
    name: "Request a time off",
    path: "request-vacations",
  },
  {
    name: "Calendar",
    path: "calendar",
  },
  {
    name: "Request Status",
    path: "requests-status",
  },
  {
    name: "User Profile",
    path: "user-profile",
  },
  {
    name: "General Statistics",
    path: "general-statistics",
  },
  {
    name: "Approve",
    path: "approval",
    authorizedRoles: [CollaboratorRole.admin, CollaboratorRole.manager],
  },
];

export const TimeOffRequestsMenu = () => {
  const { role } = useAppSelector((state) => state.authReducer.user!);

  return (
    <Grid container direction="row" spacing={1}>
      {vacationsRoutes.map(
        (route) =>
          // Check if the route has authorizedRoles and if the user's role is included
          (!route.authorizedRoles ||
            (role && route.authorizedRoles.includes(role))) && (
            <Grid key={route.path} item xs={12} md={3}>
              <CustomButton to={route.path}>{route.name}</CustomButton>
            </Grid>
          )
      )}
    </Grid>
  );
};

export default TimeOffRequestsMenu;
