import { Dispatch } from "@reduxjs/toolkit";
import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";

import { ApiResponse, AuthActivity } from "../../shared/types";

import {
  setAuthActivities,
  setLoading,
  setTotalAuthActivities,
} from "../slice/authActivitiesSlice";

const route = "/auth-activities";

export const getAuthActivities = (page: number, pageSize: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<AuthActivity[]>
      >(`${route}?page=${page}&limit=${pageSize}`);

      dispatch(setAuthActivities(data.data));
      dispatch(setTotalAuthActivities(data.meta.total));
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
