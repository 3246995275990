import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { startChecking } from "../actions/authActions";
import { AuthPage } from "../pages/authPages/loginPage/AuthPage";
import { MainPageLayout } from "../pages/mainPages/MainPageLayout";

import { roleTypes } from "../shared/types/types";
import { DashboardLayout } from "../pages/dashboard/DashboardLayout";
import { checkAuthorization } from "../helpers/utilities";
import { ClientLayout } from "../pages/clientsPages/ClientLayout";
import { FcmTransferFormikNew } from "../pages/clientsPages/FcmTransferFormikNew";
import { PackagePrint } from "../pages/other/PackagePrint";
import { useAppDispatch, useAppSelector } from "../hooks";
import { startCheckingNew } from "../store/thunks/authThunks";
import ForgotPassword from "../pages/authPages/loginPage/ForgotPassword";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const dispatch2 = useAppDispatch();
  const { user, isLoading } = useAppSelector((state) => state.authReducer);
  const role = user ? user.role : null; // Safely access role2 only if user is defined

  // todo check this

  useEffect(() => {
    dispatch2(startCheckingNew());
  }, [dispatch, dispatch2]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <HashRouter>
      <Routes>
        {checkAuthorization(role, roleTypes.collaborator) && (
          <Route path="/dashboard/*" element={<DashboardLayout />}></Route>
        )}

        <Route path="/auth" element={<AuthPage />}></Route>
        <Route
          path="/auth/forgot-password"
          element={<ForgotPassword />}
        ></Route>
        <Route path="/auth/:token" element={<AuthPage />}></Route>
        {role === "User" && (
          <Route path="/clients/*" element={<ClientLayout />}></Route>
        )}

        <Route path="/*" element={<MainPageLayout />}></Route>
        <Route path="/test" element={<FcmTransferFormikNew />}></Route>
        <Route
          path="/other/print/fcmPackage/:fcmPackageId"
          element={<PackagePrint />}
        ></Route>
        {/* <Route path="*" element={<LoginPage />} /> */}
        {/* <Route path="/auth/register" element={<LoginPage />} /> */}
      </Routes>
    </HashRouter>
  );
};
