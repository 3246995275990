import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { MenuRoute, ModuleMenu } from "../../../components";
import { ControlledPrescriptionRouter } from "../../../routes/dashboard-routes";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  controlledPrescriptionThunks,
  productThunks,
  supplierThunks,
} from "../../../store/thunks";

export const ControlledPrescriptionLayout = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(
    (state) => state.controlledPrescriptions
  );
  const routes: MenuRoute[] = [
    { name: "List", path: "" },
    { name: "New", path: "new" },
  ];

  useEffect(() => {
    dispatch(controlledPrescriptionThunks.fetchStatusOptions());
    dispatch(controlledPrescriptionThunks.fetchItems());
    dispatch(productThunks.fetchItems());
    dispatch(supplierThunks.fetchItems());
  }, [dispatch]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        <ControlledPrescriptionRouter />
      </Grid>
    </Grid>
  );
};
