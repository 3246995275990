import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  convertTimeOffOverviewToDateTimeOffRequests,
  getUpcomingAndRecentTimeOffDates,
} from "../../../../helpers/timeOffRequestsHelpers";
import { DateTimeOffTable } from "./DateTimeOffTable";

export const TimeOffRequestsUpcomingOverview = () => {
  const { collaboratorsTimeOffsOverview } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const timeOffDates = useMemo(() => {
    return convertTimeOffOverviewToDateTimeOffRequests(
      collaborators,
      collaboratorsTimeOffsOverview
    );
  }, [collaborators, collaboratorsTimeOffsOverview]);

  const { upcomingTimeOffDates, recentTimeOffDates } = useMemo(() => {
    return getUpcomingAndRecentTimeOffDates(timeOffDates);
  }, [timeOffDates]);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Upcoming Time Off
      </Typography>
      {upcomingTimeOffDates.length > 0 ? (
        <DateTimeOffTable dateTimeOffRequests={upcomingTimeOffDates} />
      ) : (
        <Typography variant="body2">No upcoming time off requests</Typography>
      )}

      <Typography variant="h4" component="h2" mt={4} mb={2} gutterBottom>
        Recent Time Off
      </Typography>
      {recentTimeOffDates.length > 0 ? (
        <DateTimeOffTable dateTimeOffRequests={recentTimeOffDates} />
      ) : (
        <Typography variant="body2">No recent time off requests</Typography>
      )}
    </Box>
  );
};
