export const getContrastText = (color: string): string => {
  // Convert hex color to RGB
  const hexToRgb = (hex: string): [number, number, number] => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Calculate relative luminance
  const calculateLuminance = (r: number, g: number, b: number): number => {
    const sRGB = [r / 255, g / 255, b / 255];
    const sRGBAdjusted = sRGB.map((v) =>
      v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    );
    const luminance =
      sRGBAdjusted[0] * 0.2126 +
      sRGBAdjusted[1] * 0.7152 +
      sRGBAdjusted[2] * 0.0722;
    return luminance;
  };

  // Check if background color is dark or light
  const isDarkColor = (r: number, g: number, b: number): boolean => {
    const luminance = calculateLuminance(r, g, b);
    return luminance <= 0.5 && luminance >= 0.2; // Adjust the threshold as needed
  };

  // Get RGB values from hex color
  const [r, g, b] = hexToRgb(color);

  // Determine contrast color (black or another color of your choice)
  const contrastColor = isDarkColor(r, g, b) ? "#000000" : "#333333"; // Adjust the color as needed

  return contrastColor;
};

export const generateColors = (length: number): string[] => {
  const baseColors = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
    "#aec7e8",
    "#ffbb78",
    "#98df8a",
    "#ff9896",
    "#c5b0d5",
    "#c49c94",
    "#f7b6d2",
    "#c7c7c7",
    "#dbdb8d",
  ];

  const colors: string[] = [];

  for (let i = 0; i < length; i++) {
    colors.push(baseColors[i % baseColors.length]);
  }

  return colors;
};
