import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { Button } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";
import { DayPicker } from "react-day-picker";
import {
  getFormattedMxDate,
  isSameDate,
} from "../../../../../helpers/dateHelpers";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  field: keyof Task;
  onSave: (updatedTask: Task) => void;
  isRequiredField?: boolean;
};

export const EditableDateFields: React.FC<Props> = ({
  task,
  field,
  onSave,
  isRequiredField = false,
}) => {
  const [editedValue, setEditedValue] = useState<Date | undefined>(
    task[field] ? new Date(task[field] as string) : undefined
  );
  const [isEditable, setIsEditable] = useState(false);

  const handleDayClick = (day: Date) => {
    setEditedValue((prevDay) => {
      return isSameDate(prevDay, day) ? undefined : day;
    });
  };

  const handleSave = async () => {
    if (isRequiredField && !editedValue) {
      fireSwalError("The date must be set");
      setIsEditable(false);
      setEditedValue(task[field] ? new Date(task[field] as string) : undefined);

      return;
    }
    await onSave({ ...task, [field]: editedValue?.toISOString() });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          <DayPicker
            selected={editedValue ?? undefined}
            onDayClick={handleDayClick}
          />
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          <span>
            {task[field] && getFormattedMxDate(task[field] as string)}
          </span>
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </>
      )}
    </>
  );
};
