import React from "react";
import { Task, TaskStatus } from "../../../../../shared/types/Task";
import dayjsWithPlugins from "../../../../../config/dayjs";
import { TaskListCard } from "../TaskListCard";

type Props = { tasks: Task[]; startDate: Date; endDate: Date };
export const ReportTasksWithProgress: React.FC<Props> = ({
  tasks,
  startDate,
  endDate,
}) => {
  const advancedTasks = tasks.filter((task) => {
    if (
      (task.status === TaskStatus.Completed ||
        task.status === TaskStatus.Canceled) &&
      dayjsWithPlugins(task.completedAt).isAfter(dayjsWithPlugins(startDate)) &&
      dayjsWithPlugins(task.completedAt).isBefore(dayjsWithPlugins(endDate))
    ) {
      return false;
    }

    if (!task.activities) {
      return false;
    }

    return task.activities.some((activity) => {
      const activityDate = dayjsWithPlugins(activity.registeredAt);

      return (
        activityDate.isSameOrAfter(dayjsWithPlugins(startDate)) &&
        activityDate.isSameOrBefore(dayjsWithPlugins(endDate))
      );
    });
  });
  return (
    <>
      {advancedTasks.map((task) => (
        <TaskListCard
          task={task}
          showActivities={true}
          startDate={startDate}
          endDate={endDate}
        ></TaskListCard>
      ))}
    </>
  );
};
