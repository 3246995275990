import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CustomerRFC } from "../../../../shared/types";

import CustomNavigateButton from "../../../../components/buttons/CustomNavigateButton";

type Props = {
  customerRFC: CustomerRFC;
};

export const CustomerRFCCard: React.FC<Props> = ({ customerRFC }) => {
  const {
    _id,
    name,
    rfc,
    invoice_usage,
    fiscal_regime,
    postal_code,
    isValidated,
    email,
    phone_number,
  } = customerRFC;

  const cardStyle = {
    margin: 2,
    boxShadow: 3,
    backgroundColor: isValidated ? "green" : "white",
    color: isValidated ? "white" : "black",
  };

  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography color="text.secondary">RFC: {rfc}</Typography>
        <Typography color="text.secondary">
          Invoice Usage: {invoice_usage}
        </Typography>
        <Typography color="text.secondary">
          Fiscal Regime: {fiscal_regime}
        </Typography>
        <Typography color="text.secondary">
          Postal Code: {postal_code}
        </Typography>
        <Typography color="text.secondary">Email: {email}</Typography>
        <Typography color="text.secondary">Phone: {phone_number}</Typography>
        <Typography color="text.secondary">
          Validated: {isValidated ? "Yes" : "No"}
        </Typography>
      </CardContent>
      <CardActions>
        <CustomNavigateButton
          to={`/dashboard/billing/create-bill?customer-rfc-id=${_id}`}
        >
          Bill
        </CustomNavigateButton>
        <CustomNavigateButton to={`/dashboard/billing/customer-rfcs/${_id}`}>
          Edit
        </CustomNavigateButton>
      </CardActions>
    </Card>
  );
};
