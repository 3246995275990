import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CollaboratorSelect } from "../../../../components/dropdowns/CollaboratorSelect";

interface ChangeUserByAdminDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  selectedCollaborator: string;
  handleSelectCollaborator: (event: SelectChangeEvent<string>) => void;
}

const ChangeUserByAdminDialog: React.FC<ChangeUserByAdminDialogProps> = ({
  open,
  onClose,
  onSubmit,
  selectedCollaborator,
  handleSelectCollaborator,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Change User</DialogTitle>
    <DialogContent>
      <CollaboratorSelect
        selectedCollaborator={selectedCollaborator}
        handleSelectChange={handleSelectCollaborator}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit}>Submit</Button>
    </DialogActions>
  </Dialog>
);

export default ChangeUserByAdminDialog;
