import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import originalDayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

originalDayjs.extend(utc);
originalDayjs.extend(timezone);

// Extend dayjs with plugins
originalDayjs.extend(isSameOrAfter);
originalDayjs.extend(isSameOrBefore);

// Export both the extended dayjs instance and the original dayjs
const dayjsWithPlugins = originalDayjs;

export default dayjsWithPlugins;
