import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface CustomButtonProps extends ButtonProps {
  to: string;
  children: React.ReactNode;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  to,
  children,
  ...props
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ width: "100%", height: "100%" }}
      component={RouterLink}
      to={to}
      {...props}
    >
      {children}
    </Button>
  );
};
export default CustomButton;
