import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { Select, MenuItem, Button, Box } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";

import { SelectChangeEvent } from "@mui/material/Select";
import { CustomAvatar } from "../../../../../components/avatar/CustomAvatar";
import {
  getActiveCollaborators,
  getCollaboratorAvatarPropsById,
} from "../../../../../helpers/collaboratorsHelpers";
import { Collaborator } from "../../../../../shared/types/CollaboratorsTypes";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  field: keyof Task;
  onSave: (updatedTask: Task) => void;
};

export const EditableAuthor: React.FC<Props> = ({ task, field, onSave }) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const activeCollaborators = getActiveCollaborators(collaborators);
  const [editedValue, setEditedValue] = useState<string>(task[field] as string);
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setEditedValue(event.target.value);
  };

  const handleSave = async () => {
    if (!editedValue) {
      fireSwalError("The status must be set");
      setIsEditable(false);

      return;
    }
    await onSave({ ...task, [field]: editedValue });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          <Select value={editedValue} onChange={handleChange}>
            {activeCollaborators.map((col: Collaborator) => (
              <MenuItem key={col._id} value={col._id}>
                {col.col_code}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          <Box sx={{ marginRight: 1, position: "relative" }}>
            <CustomAvatar
              {...getCollaboratorAvatarPropsById(collaborators, task.author)}
              width={60}
            />
            <Button
              onClick={handleEdit}
              sx={{ position: "absolute", bottom: -10, right: -40 }}
            >
              <EditIcon />
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
