import { useCallback, useMemo } from "react";
import { useAppSelector } from "./useAppSelector";
import { Collaborator } from "../shared/types/CollaboratorsTypes";

export const useCollaboratorHook = () => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const { user } = useAppSelector((state) => state.authReducer);

  const uid = user?.uid;

  const getCollaboratorById = useCallback(
    (id: string) => {
      return collaborators.find((c: Collaborator) => c.id === id);
    },
    [collaborators]
  );

  const getCollaboratorCodeById = useCallback(
    (id: string) => {
      return getCollaboratorById(id)?.col_code;
    },
    [getCollaboratorById]
  );

  const getCurrentCollaborator = useCallback(() => {
    if (!uid) return undefined;
    return getCollaboratorById(uid);
  }, [getCollaboratorById, uid]);

  const getCurrentCollaboratorCode = useCallback(() => {
    return getCurrentCollaborator()?.col_code;
  }, [getCurrentCollaborator]);

  const getCurrentCollaboratorId = useCallback(() => {
    return getCurrentCollaborator()?._id;
  }, [getCurrentCollaborator]);

  const getCurrentCollaboratorRole = useCallback(() => {
    return getCurrentCollaborator()?.role;
  }, [getCurrentCollaborator]);

  // Directly compute the role
  const currentCollaboratorRole = useMemo(() => {
    return getCurrentCollaborator()?.role;
  }, [getCurrentCollaborator]);

  const activeCollaborators = useMemo(() => {
    return collaborators.filter((c: Collaborator) => c.isActive);
  }, [collaborators]);

  return {
    collaborators,
    getCollaboratorById,
    getCollaboratorCodeById,
    getCurrentCollaborator,
    getCurrentCollaboratorCode,
    getCurrentCollaboratorId,
    getCurrentCollaboratorRole,
    currentCollaboratorRole,
    activeCollaborators,
  };
};
