export enum CollaboratorRole {
  admin = "Administrador",
  manager = "Gerente",
  collaborator = "Colaborador",
  user = "User",
  guest = "Invitado",
}

export interface Collaborator {
  _id: string;
  id: string;
  first_name: string;
  last_name: string;
  role: CollaboratorRole;
  col_code: string;
  col_numId?: number;
  isActive: boolean;
  gender?: string;
  imgUrl?: string;
  accessCode?: string;
  isRegistered: boolean;
  email?: string;
  password?: string;
  position?: string;
  isDisplayedWeb: boolean;
  textPresentation?: string;
  registeredDate: Date;
  lastLogin?: Date;
  // VacationDays
  startDate?: Date;
  endDate?: Date;
  vacationsTakenBefore2021?: number;
  createdBy: string;
}
