// TODO

export interface TimeOffRequest {
  _id?: string;
  approvedAt?: Date;
  collaborator?: string;
  createdAt?: Date;
  createdBy?: string;
  collaboratorNote?: string;
  managerNote?: string;
  requestedAt?: Date;
  requestedDays: Date[];
  status?: TimeOffStatus;
  timeOffType: TimeOffType;
  approvedBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
}

export enum AttendanceType {
  ordinary = "Ordinary",
  partialPermission = "Permission",
  simulatedAbsence = "Simulated Absence",
  restDay = "Rest Day",
  vacation = "Vacation",
  sickLeaveIMSSUnpaid = "IMSS Disability (Unpaid)",
  sickLeaveIMSSPaid = "IMSS Paid Disability",
  sickLeaveJustifiedByCompany = "Justified by Company",
  unjustifiedAbsence = "Unjustified Absence",
  dayLeave = "Permission Day (Deducted)",
  NotCounted = "Not Counted",
}

export interface AttendanceTypeInfo {
  description: string;
  previousCode: string;
}

export const attendanceTypeDescriptions: Record<
  AttendanceType,
  AttendanceTypeInfo
> = {
  [AttendanceType.ordinary]: {
    description: "Jornada habitual de trabajo. Se considera asistencia.",
    previousCode: "ORD",
  },
  [AttendanceType.partialPermission]: {
    description:
      "Permiso concedido al trabajador para llegar tarde o ausentarse antes, es una jornada ordinaria. Se considera asistencia.",
    previousCode: "PER",
  },
  [AttendanceType.simulatedAbsence]: {
    description:
      "Ausencia del trabajador, pero la empresa asume pagar su salario completo. Se considera como asistencia.",
    previousCode: "ASE",
  },
  [AttendanceType.restDay]: {
    description: "Jornada de descanso semanal",
    previousCode: "DES",
  },
  [AttendanceType.vacation]: {
    description: "Jornada de vacaciones",
    previousCode: "VAC",
  },
  [AttendanceType.sickLeaveIMSSUnpaid]: {
    description:
      "Falta justificada por el IMSS, por los tres primeros d�as, pero no pagada; la empresa otorga una compensaci�n del 60%. Se considera como incapacidad.",
    previousCode: "INC",
  },
  [AttendanceType.sickLeaveIMSSPaid]: {
    description:
      "Falta justificada del trabajador, pero el IMSS paga la jornada. Aplicable a partir del cuarto d�a. Se considera como incapacidad.",
    previousCode: "IMS",
  },
  [AttendanceType.sickLeaveJustifiedByCompany]: {
    description:
      "Falta justificada por la empresa sin justificante del IMSS, pero se paga al trabajador una compensaci�n del 60%. Se considera como ausencia.",
    previousCode: "JUE",
  },
  [AttendanceType.unjustifiedAbsence]: {
    description:
      "Falta injustificada del trabajador, se descuenta del salario. Se considera como inasistencia.",
    previousCode: "INJ",
  },
  [AttendanceType.dayLeave]: {
    description:
      "Permiso concedido al trabajador que no acude a trabajar por permiso concedido previamente, se descuenta el d�a. Se considera como asistencia.",
    previousCode: "PED",
  },
  [AttendanceType.NotCounted]: {
    description:
      "D�as no computados en virtud de que en la fecha, el trabajador no laboraba. No se considera en la cantidad de d�as.",
    previousCode: "NCO",
  },
};

export enum TimeOffType {
  partialPermission = AttendanceType.partialPermission,
  simulatedAbsence = AttendanceType.simulatedAbsence,
  vacation = AttendanceType.vacation,
  sickLeaveIMSSUnpaid = AttendanceType.sickLeaveIMSSUnpaid,
  sickLeaveIMSSPaid = AttendanceType.sickLeaveIMSSPaid,
  sickLeaveJustifiedByCompany = AttendanceType.sickLeaveJustifiedByCompany,
  dayLeave = AttendanceType.dayLeave,
}

export enum TimeOffStatus {
  pending = "Pending",
  approved = "Approved",
  rejected = "Rejected",
  canceled = "Canceled",
}

export interface CollaboratorTimeOffOverview {
  collaboratorId: string;
  totalVacationDays: number; // a) The number of vacation days the collaborator has a right to take
  vacationsTaken: Date[]; // b) Vacations taken by the collaborator (an array of dates)
  vacationsRequested: Date[]; // c) Vacations requested but not approved (an array of dates)
  remainingVacationDays: number; // d) Number of vacation days left for the collaborator
  dateTimeOffRequests: DateTimeOffRequest[];
  lastAnniversaryDate: Date; // e) The last anniversary date of the collaborator
  legalVacationDays: number; // f) The number of legal vacation days the collaborator has a right to take
  remainingLegalVacationDays: number;
  remainingcurrentYearVacationDays: number;
}

export interface DateTimeOffRequest {
  date: Date;
  id: string;
  timeOffType: TimeOffType;
  status: TimeOffStatus;
  collaborator: string | { name: string; id: string; imgUrl: string };
}

export interface DateTimeOffRequestCollaborator {
  date: Date;
  id: string;
  timeOffType: TimeOffType;
  status: TimeOffStatus;
  collaborator: { name: string; id: string; imgUrl: string };
}
