import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

import { useTimeOffRequestsStore } from "../../../hooks/useTimeOffRequestsStore";
import { TimeOffRequestsMenu } from "./components";

// Your TypeScript file

// Rest of your code

export const TimeOffRequestsLayout: React.FC = () => {
  const { startLoadingInitialState } = useTimeOffRequestsStore();

  useEffect(() => {
    startLoadingInitialState();

    return () => {};
  }, [startLoadingInitialState]);

  return (
    <Container>
      <Grid container spacing={4}>
        {/* Menu Section */}

        <Grid item xs={12}>
          <TimeOffRequestsMenu />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};
