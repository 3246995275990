import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import { getRecentDates } from "../../../../helpers/workLogsHelpers";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CollaboratorLog } from "../";

export const RecentLogs = () => {
  const recentDates = getRecentDates();
  const { workLogs } = useAppSelector((state) => state.workLogsReducer);

  const groupedLogs = recentDates.map((date) => ({
    date,
    logs: workLogs.filter(
      (log) => new Date(log.logDate).setHours(0, 0, 0, 0) === date.getTime()
    ),
  }));

  return (
    <Box>
      <Typography variant="h4">Recent Logs</Typography>
      {groupedLogs.map((group) => (
        // DATE GROUP
        <Box key={group.date.getTime()} margin={4}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h5">{group.date.toDateString()}</Typography>
          </Box>
          <Grid container spacing={2}>
            {group.logs.map((log) => (
              <Grid item xs={12} sm={6} key={log._id}>
                <CollaboratorLog log={log} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};
