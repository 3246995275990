import { createAsyncThunk } from "@reduxjs/toolkit";
import { ControlledPrescriptionService } from "../../services";
import { createCRUDThunks, handleAsyncThunkError } from "../../shared/helpers";
import { IOption } from "../../shared/types/option.types";

const service = new ControlledPrescriptionService();

const fetchStatusOptions = createAsyncThunk(
  "controlled-prescriptions/fetchStatusOptions",
  async (_, { rejectWithValue }) => {
    try {
      const data: IOption[] = await service.getStatusOptions();
      return data;
    } catch (error) {
      return handleAsyncThunkError(error, rejectWithValue);
    }
  }
);

const crudThunks = createCRUDThunks({
  service,
  resourceName: "controlled-prescriptions",
});

export const controlledPrescriptionThunks = {
  ...crudThunks,
  fetchStatusOptions,
};
