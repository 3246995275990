import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchBillCreationInfos } from "../../../../store/thunks/billingThunks";
import { BillTable } from "./BillTable";

export const BillList = () => {
  const dispatch = useAppDispatch();

  const { billCreationInfos } = useAppSelector((state) => state.billingReducer);

  useEffect(() => {
    dispatch(fetchBillCreationInfos());
  }, [dispatch]);

  const sortedBills = billCreationInfos
    ? billCreationInfos
        .slice()
        .sort(
          (a, b) =>
            new Date(b.bill_date).getTime() - new Date(a.bill_date).getTime()
        )
    : [];

  return <BillTable billCreationInfos={sortedBills} />;
};
