import {
  createActivityRegister,
  createActivityRegisterType,
  deleteActivityRegister,
  deleteActivityRegisterType,
  fetchActivityRegisters,
  fetchActivityRegisterTypes,
  fetchCollaboratorStats,
  updateActivityRegister,
  updateActivityRegisterType,
} from "./../thunks/activity-register.thunks";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IActivityRegister } from "../../shared/types/activity-register.types";
import { IOption } from "../../shared/types/option.types";
import { fetchCurrentColActivityRegisters } from "../thunks/activity-register.thunks";

export interface ActivityRegisterCollaboratorStats {
  todayHours: number;
  yesterdayHours: number;
  halfMonthHours: number;
  monthHours: number;
  quarterHours: number;
  yearHours: number;
  totalHours: number;
}

interface State {
  currentCollaboratoractivityRegisters: IActivityRegister[];
  lastActivityRegister?: IActivityRegister;
  collaboratorStats: ActivityRegisterCollaboratorStats;
  currentRegister?: IActivityRegister;
  activityRegisters: IActivityRegister[];
  isLoading: boolean;
  activityRegisterTypes: IOption[];
}

const initialState: State = {
  currentCollaboratoractivityRegisters: [],
  activityRegisters: [],
  currentRegister: undefined,
  lastActivityRegister: undefined,
  isLoading: true,
  activityRegisterTypes: [],
  collaboratorStats: {
    todayHours: 0,
    yesterdayHours: 0,
    halfMonthHours: 0,
    monthHours: 0,
    quarterHours: 0,
    yearHours: 0,
    totalHours: 0,
  },
};

export const activityRegisterSlice = createSlice({
  name: "activityRegister",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setActivityRegisters: (
      state,
      action: PayloadAction<IActivityRegister[]>
    ) => {
      state.currentCollaboratoractivityRegisters = action.payload;
    },
    setActiveCollaboratorRegister: (
      state,
      action: PayloadAction<IActivityRegister[]>
    ) => {
      state.activityRegisters = action.payload;
    },
    setCurrentRegister: (state, action: PayloadAction<IActivityRegister>) => {
      state.currentRegister = action.payload;
    },
    setLastActivityRegister: (
      state,
      action: PayloadAction<IActivityRegister>
    ) => {
      state.lastActivityRegister = action.payload;
    },
    setActivityRegisterTypes: (state, action: PayloadAction<IOption[]>) => {
      state.activityRegisterTypes = action.payload;
    },
    setCollaboratorStats: (
      state,
      action: PayloadAction<ActivityRegisterCollaboratorStats>
    ) => {
      state.collaboratorStats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentColActivityRegisters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchCurrentColActivityRegisters.fulfilled,
        (state, action: PayloadAction<IActivityRegister[]>) => {
          state.isLoading = false;
          state.currentCollaboratoractivityRegisters = action.payload;

          const currentRegister = action.payload.find(
            (register) => !register.endingTime
          );

          const lastRegister = action.payload
            .filter((register) => register.endingTime)
            .sort(
              (a, b) =>
                new Date(b.endingTime!).getTime() -
                new Date(a.endingTime!).getTime()
            )[0];
          state.currentRegister = currentRegister;
          state.lastActivityRegister = lastRegister;
        }
      )
      .addCase(fetchCurrentColActivityRegisters.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchActivityRegisterTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchActivityRegisterTypes.fulfilled,
        (state, action: PayloadAction<IOption[]>) => {
          state.isLoading = false;
          state.activityRegisterTypes = action.payload;
        }
      )
      .addCase(fetchActivityRegisterTypes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchCollaboratorStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchCollaboratorStats.fulfilled,
        (state, action: PayloadAction<ActivityRegisterCollaboratorStats>) => {
          state.isLoading = false;
          state.collaboratorStats = action.payload;
        }
      )
      .addCase(fetchCollaboratorStats.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        updateActivityRegister.fulfilled,
        (state, action: PayloadAction<IActivityRegister>) => {
          if (action.payload.endingTime) {
            state.currentRegister = undefined;
            state.lastActivityRegister = action.payload;
          }

          state.currentCollaboratoractivityRegisters =
            state.currentCollaboratoractivityRegisters.map((register) =>
              register.id === action.payload.id ? action.payload : register
            );
        }
      )
      .addCase(
        createActivityRegister.fulfilled,
        (state, action: PayloadAction<IActivityRegister>) => {
          if (action.payload.endingTime) {
            state.lastActivityRegister = action.payload;
          } else {
            state.currentRegister = action.payload;
          }
          state.currentCollaboratoractivityRegisters = [
            ...state.currentCollaboratoractivityRegisters,
            action.payload,
          ];
        }
      )
      .addCase(
        deleteActivityRegister.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.currentCollaboratoractivityRegisters =
            state.currentCollaboratoractivityRegisters.filter(
              (register) => register.id !== action.payload
            );
          if (state.currentRegister?.id === action.payload) {
            state.currentRegister =
              state.currentCollaboratoractivityRegisters.find(
                (register) => !register.endingTime
              );
          }
          if (state.lastActivityRegister?.id === action.payload) {
            state.lastActivityRegister =
              state.currentCollaboratoractivityRegisters
                .filter((register) => register.endingTime)
                .sort(
                  (a, b) =>
                    new Date(b.endingTime!).getTime() -
                    new Date(a.endingTime!).getTime()
                )[0];
          }
        }
      )
      .addCase(fetchActivityRegisters.fulfilled, (state, action) => {
        state.activityRegisters = action.payload;
      })
      .addCase(createActivityRegisterType.fulfilled, (state, action) => {
        state.activityRegisterTypes = [
          ...state.activityRegisterTypes,
          action.payload,
        ];
      })
      .addCase(updateActivityRegisterType.fulfilled, (state, action) => {
        state.activityRegisterTypes = state.activityRegisterTypes.map((type) =>
          type.id === action.payload.id ? action.payload : type
        );
      })
      .addCase(deleteActivityRegisterType.fulfilled, (state, action) => {
        state.activityRegisterTypes = state.activityRegisterTypes.filter(
          (type) => type.id !== action.payload
        );
      });
  },
});
