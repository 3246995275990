import React from "react";
import { getContrastText } from "../../helpers/colorHelpers";

type PieLabelProps = {
  cx: number;
  cy: number;
  fill: string;
  stroke: string;
  percent: number;
  name: string;
  tooltipPayload: {
    name: string;
    value: number;
    payload: {
      payload: {
        name: string;
        value: number;
        fill: string;
      };
      cx: string;
      cy: string;
      fill: string;
      stroke: string;
      name: string;
      value: number;
    };
    dataKey: string;
  }[];
  midAngle: number;
  middleRadius: number;
  tooltipPosition: {
    x: number;
    y: number;
  };
  payload: {
    payload: {
      name: string;
      value: number;
      fill: string;
    };
    cx: string;
    cy: string;
    fill: string;
    stroke: string;
    name: string;
    value: number;
  };
  value: number;
  innerRadius: number;
  outerRadius: number;
  maxRadius: number;
  startAngle: number;
  endAngle: number;
  paddingAngle: number;
  index: number;
  textAnchor: string;
  x: number;
  y: number;
};

export const CustomPieLabel: React.FC<PieLabelProps> = (props) => {
  const RADIAN = Math.PI / 180;
  const { outerRadius, innerRadius, cx, midAngle, cy, percent, fill, payload } =
    props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent < 0.1) return null;

  return (
    <text
      x={x}
      y={y}
      fill={getContrastText(fill)}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${payload.name} - ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
