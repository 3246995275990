import { Grid } from "@mui/material";
import React from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import { CollaboratorRole } from "../../../../shared/types/CollaboratorsTypes";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface Route {
  name: string;
  path: string;
  authorizedRoles?: CollaboratorRole[];
}
const routes: Route[] = [
  {
    name: "Main",
    path: "",
  },
  {
    name: "Add new Customer",
    path: "customer-rfcs",
  },
  {
    name: "Customers list",
    path: "customer-rfcs/list",
  },
  {
    name: "Add Bill Create Info",
    path: "create-bill",
  },
  { name: "Bill List", path: "bill-list" },
];

export const BillingMenu = () => {
  const { role } = useAppSelector((state) => state.authReducer.user!);
  return (
    <Grid container direction="row" spacing={1}>
      {routes.map(
        (route) =>
          (!route.authorizedRoles ||
            (role && route.authorizedRoles.includes(role))) && (
            <Grid key={route.path} item xs={12} md={2}>
              <CustomButton to={route.path}>{route.name}</CustomButton>
            </Grid>
          )
      )}
    </Grid>
  );
};
