import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { supplierThunks } from "../../../../store/thunks/suppliers.thunks";
import { sortAlphabeticallyByProperty } from "../../../../helpers";

export const SuppliersList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { suppliers, isLoading } = useAppSelector((state) => state.suppliers);
  const sortedSuppliers = sortAlphabeticallyByProperty("name", suppliers);

  useEffect(() => {
    dispatch(supplierThunks.fetchItems());
  }, [dispatch]);

  const handleDelete = (id: string) => {
    dispatch(supplierThunks.deleteItem(id));
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: 1000, margin: "auto", mt: 4 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Legal Name</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSuppliers.map((element) => (
            <TableRow
              key={element.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {element.id}
              </TableCell>
              <TableCell>{element.name}</TableCell>
              <TableCell>{element.legalName}</TableCell>
              <TableCell align="center">
                <IconButton color="primary" aria-label="edit product">
                  <EditIcon onClick={() => navigate(element.id!)} />
                </IconButton>
                <IconButton color="error" aria-label="delete product">
                  <DeleteIcon onClick={() => handleDelete(element.id!)} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
