import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { fetchInitialData } from "../../../store/thunks/attendanceRecordsThunks";
import { AttendanceRecordsMenu } from "./";

export const AttendanceRecordsLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchInitialData());
  }, [dispatch]);

  const { isInitialLoading } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AttendanceRecordsMenu />
      </Grid>
      <Grid item xs={12}>
        {isInitialLoading ? <CircularProgress /> : <Outlet />}
      </Grid>
    </Grid>
  );
};
