import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React from "react";

type Option = {
  value: string;
  label: string;
};

type Props = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
};

const CustomRadioGroup: React.FC<Props> = ({ options, value, onChange }) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <RadioGroup
      aria-label="options"
      name="options"
      value={value}
      onChange={handleRadioChange}
      row
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
