export interface Task {
  _id?: string;
  activities?: TaskActivity[];
  assignees?: string[];
  author: string;
  completedAt?: string | null;
  completionCriteria?: string[];
  createdAt?: string;
  createdBy?: string;
  description?: string;
  dueDate?: string;
  isRestrictedView: boolean;
  notes?: string;
  number?: number;
  priority: TaskPriority;
  requestedAt?: string;
  status: TaskStatus;
  tags?: TaskTag[];
  title: string;
  updatedAt?: string;
  updatedBy?: string;
}

// author, assignee, status, priority, tags
// sorting number, title, dueDate, updatedAt

export interface TaskActivity {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  content: string;
  updatedAt?: string;
  updatedBy?: string;
  registeredAt: string;
  author: string;
}

export enum TaskPriority {
  High = "High",
  Medium = "Medium",
  Low = "Low",
  Exploratory = "Exploratory",
}

export const PRIORITY_RANKING = {
  [TaskPriority.High]: 1,
  [TaskPriority.Medium]: 2,
  [TaskPriority.Low]: 3,
  [TaskPriority.Exploratory]: 4,
};

export enum TaskStatus {
  Backlog = "Backlog",
  OnHold = "On hold",
  Next = "Next",
  InProgress = "In progress",
  InReview = "In review",
  Completed = "Completed",
  Canceled = "Canceled",
}

export enum TaskTag {
  Accounting = "Accounting", // 1
  Advertising = "Advertising", // 3
  Billing = "Billing", // 1
  Customers = "Customers", // 6
  Documentation = "Documentation", // 0
  Equipment = "Equipment", // 1
  Events = "Events", // 1
  FileManagement = "File management", // 6
  General = "General", // 1
  HumanResources = "HR", // 12
  Inventory = "Inventory", // 6
  Maintenance = "Maintenance", // 19
  Petco = "Petco", // 2
  Qvet = "Qvet", // 6
  Reports = "Reports", // 0
  SocialMedia = "Social media", // 0
  SocialSecurity = "Social security", // 0
  Suppliers = "Suppliers", // 5
  Taxes = "Taxes", // 0
  WebApp = "Web app", // 7
}

export const PRIORITY_COLORS: Record<TaskPriority, string> = {
  [TaskPriority.High]: "#FF0000", // Red
  [TaskPriority.Medium]: "#FFA500", // Orange
  [TaskPriority.Low]: "#008000", // Green
  [TaskPriority.Exploratory]: "#0000FF", // Blue
};

export const TAG_COLORS: Record<TaskTag, string> = {
  [TaskTag.Maintenance]: "#FF5733",
  [TaskTag.Inventory]: "#33FF57",
  [TaskTag.Suppliers]: "#9C33FF",
  [TaskTag.Customers]: "#FF3366",
  [TaskTag.Petco]: "#3366FF",
  [TaskTag.HumanResources]: "#FF33CC",
  [TaskTag.Billing]: "#33CCFF",
  [TaskTag.Reports]: "#FF9900",
  [TaskTag.Advertising]: "#00FF99",
  [TaskTag.SocialMedia]: "#FF6633",
  [TaskTag.Events]: "#33FF99",
  [TaskTag.WebApp]: "#FFCC33",
  [TaskTag.Qvet]: "#33FFCC",
  [TaskTag.General]: "#CC33FF",
  [TaskTag.FileManagement]: "#AA33FF",
  [TaskTag.Accounting]: "#33FFFF",
  [TaskTag.Equipment]: "#FFFF33",
  [TaskTag.Taxes]: "#CCFF33",
  [TaskTag.SocialSecurity]: "#33FF33",
  [TaskTag.Documentation]: "#FF33FF",
};

export type TaskColumns = {
  [key in TaskStatus]: Task[];
};
