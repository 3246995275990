import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { differenceInMinutes } from "date-fns";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { useNavigate } from "react-router-dom";
import { AttendanceRecord } from "../../../../shared/types";

type Props = {
  attendanceRecords: AttendanceRecord[];
};

const predefinedCoordinates = [
  // Urban
  { lat: 21.0198725, lng: -89.5961368 },
  // Montejo
  { lat: 21.0036815, lng: -89.6147023 },
  // Harbor
  { lat: 21.046548, lng: -89.630642 },
];

// Haversine formula to calculate distance in meters
const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const toRad = (value: number) => (value * Math.PI) / 180;
  const R = 6371000; // Radius of the Earth in meters
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in meters
  return distance;
};

const isWithinDistance = (lat: number, lon: number, distance: number) => {
  return predefinedCoordinates.some(
    (coord) => calculateDistance(lat, lon, coord.lat, coord.lng) < distance
  );
};

// Start Latitude: 37.3959728
// Start Longitude: -5.9996942

export const AttendanceRecordsTable = ({ attendanceRecords }: Props) => {
  const navigate = useNavigate();
  const { getCollaboratorCodeById } = useCollaboratorHook();

  const handleClick = (id: string) => {
    navigate(`/dashboard/attendance-records/${id}`);
  };

  const calculateDuration = (
    startTime?: string | Date,
    endTime?: Date | string
  ) => {
    if (!startTime || !endTime) return "N/A";
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffInMinutes = differenceInMinutes(end, start);
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };
  const sortedRecords = [...attendanceRecords].sort((a, b) => {
    const timeA = a.startTime ? new Date(a.startTime).getTime() : 0;
    const timeB = b.startTime ? new Date(b.startTime).getTime() : 0;
    return timeB - timeA;
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Branch</TableCell>
            <TableCell>Col Code</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Start Location</TableCell>
            <TableCell>End Location</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRecords.map((record) => {
            const startLocationColor =
              record.startLatitude !== undefined &&
              record.startLongitude !== undefined &&
              isWithinDistance(record.startLatitude, record.startLongitude, 500)
                ? "green"
                : "red";

            const endLocationColor =
              record.endLatitude !== undefined &&
              record.endLongitude !== undefined &&
              isWithinDistance(record.endLatitude, record.endLongitude, 500)
                ? "green"
                : "red";

            return (
              <TableRow
                key={record._id}
                style={{
                  backgroundColor: record.endTime ? "inherit" : "coral",
                }}
              >
                <TableCell>{record.clockInBranch || "N/A"}</TableCell>
                <TableCell>
                  {getCollaboratorCodeById(record.collaborator) || "N/A"}
                </TableCell>
                <TableCell>
                  {record.startTime
                    ? new Date(record.startTime).toLocaleString()
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {record.endTime
                    ? new Date(record.endTime).toLocaleString()
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {calculateDuration(record?.startTime, record?.endTime)}
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      backgroundColor: startLocationColor,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      backgroundColor: endLocationColor,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleClick(record._id!)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
