import React from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { BillLastList, BillPendingList, CustomerSearch } from "../";

export const BillingMain = () => {
  return (
    <Container>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <CustomerSearch />
        <BillPendingList />
        <BillLastList />
      </Box>
    </Container>
  );
};
