import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Collaborator } from "../../../../../shared/types/CollaboratorsTypes";
import {
  TaskPriority,
  TaskStatus,
  TaskTag,
} from "../../../../../shared/types/Task";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { getActiveCollaborators } from "../../../../../helpers";

type Props = {
  filters: { [key: string]: string };
  handleFilterChange: (filterType: string, value: string) => void;
};

export const TasksFilters: React.FC<Props> = ({
  filters,
  handleFilterChange,
}) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const { tasks } = useAppSelector((state) => state.taskReducer);
  const activeCollaborators = getActiveCollaborators(collaborators);
  const taskOptions = tasks.map((task) => ({
    title: `${task.number} - ${task.title}`,
    id: task._id,
  }));

  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Autocomplete
        key="task-title-autocomplete"
        options={taskOptions}
        getOptionLabel={(option) => option.title}
        onChange={(event, newValue) => {
          handleFilterChange("title", newValue ? newValue.id || "" : "");
        }}
        renderInput={(params) => <TextField {...params} label="Task Title" />}
        style={{ flex: 1, margin: "0 16px" }}
      />
      <TextField
        select
        label="Assignee"
        value={filters.assignee}
        onChange={(e) => handleFilterChange("assignee", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {activeCollaborators.map((collaborator: Collaborator) => (
          <MenuItem key={collaborator._id} value={collaborator._id}>
            {collaborator.col_code}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Author"
        value={filters.author}
        onChange={(e) => handleFilterChange("author", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {activeCollaborators.map((collaborator: Collaborator) => (
          <MenuItem key={collaborator._id} value={collaborator._id}>
            {collaborator.col_code}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Priority"
        value={filters.priority}
        onChange={(e) => handleFilterChange("priority", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {Object.values(TaskPriority).map((priority) => (
          <MenuItem key={priority} value={priority}>
            {priority}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Status"
        value={filters.status}
        onChange={(e) => handleFilterChange("status", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {Object.values(TaskStatus).map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Tags"
        value={filters.tags}
        onChange={(e) => handleFilterChange("tags", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {Object.values(TaskTag).map((tag) => (
          <MenuItem key={tag} value={tag}>
            {tag}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
