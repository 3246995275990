import React from "react";
import { Divider, Menu, MenuItem } from "@mui/material";
import { INotification } from "../../../../shared/types";
import { useNavigate } from "react-router-dom";
import { createNotificationLink } from "../../../../helpers";
import { useAppDispatch } from "../../../../hooks";
import { readNotification } from "../../../../store/thunks/notificationThunks";

interface NotificationsMenuProps {
  anchorEl: null | HTMLElement;
  notifications: INotification[];
  onClose: () => void;
}

export const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  anchorEl,
  notifications,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const filteredNotifications = [...notifications] // Create a copy of the array
    .sort(
      (a, b) =>
        new Date(b.createdAt ?? 0).getTime() -
        new Date(a.createdAt ?? 0).getTime()
    )
    .filter((notification) => !notification.read)
    .slice(0, 10);
  const navigate = useNavigate();

  const handleShowAll = () => {
    onClose();

    navigate("/dashboard/notifications");
  };

  const handleNotificationClick = async (notification: INotification) => {
    onClose(); // Close the menu first

    try {
      await dispatch(
        readNotification({ ...notification, read: !notification.read })
      ).unwrap();

      const link = createNotificationLink(notification);
      navigate(link);
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  return (
    <Menu
      id="notification-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {filteredNotifications.map((notification, index) => (
        <MenuItem
          key={notification.id}
          onClick={() => handleNotificationClick(notification)}
        >
          {`${notification.title} - ${notification.message}`}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem onClick={handleShowAll}>Show All</MenuItem>
    </Menu>
  );
};
