import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { sortTasks } from "../../../../helpers/tasksHelpers";
import { Box } from "@mui/material";

import { TaskListCard } from "./TaskListCard";
import { TaskStatus } from "../../../../shared/types/Task";

export const MyTasks = () => {
  const { tasks } = useAppSelector((state) => state.taskReducer);
  const { uid } = useAppSelector((state) => state.authReducer.user!);

  const filteredTasksByCollaborator = tasks
    .filter((task) => task.assignees?.includes(uid!))
    .filter(
      (task) =>
        task.status !== TaskStatus.Canceled &&
        task.status !== TaskStatus.Completed
    );

  const sortedTasks = sortTasks(filteredTasksByCollaborator);

  return (
    <Box>
      {sortedTasks.map((task) => (
        <TaskListCard task={task} />
      ))}
    </Box>
  );
};
