import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { Select, MenuItem, Button, Box } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";

import { SelectChangeEvent } from "@mui/material/Select";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  field: keyof Task;
  onSave: (updatedTask: Task) => void;
  fontColor?: string;
  enumValues: string[];
};

export const EditableSelectField: React.FC<Props> = ({
  task,
  field,
  onSave,
  fontColor,
  enumValues,
}) => {
  const [editedValue, setEditedValue] = useState<string>(task[field] as string);
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setEditedValue(event.target.value);
  };

  const handleSave = async () => {
    if (!editedValue) {
      fireSwalError("The status must be set");
      setIsEditable(false);

      return;
    }
    await onSave({ ...task, [field]: editedValue });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          <Select value={editedValue} onChange={handleChange}>
            {enumValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          <Box component="span" sx={{ color: fontColor }}>
            {String(task[field])}
          </Box>
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </>
      )}
    </>
  );
};
