import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { TimeOffRequestsList } from "./TimeOffRequestsList";

export const TimeOffRequestsCollaborator = () => {
  const { timeOffRequests } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const { uid } = useAppSelector((state) => state.authReducer.user!);

  const collaboratorTimeOffRequests = timeOffRequests.filter(
    (timeOffRequest) => timeOffRequest.collaborator === uid
  );
  return <TimeOffRequestsList timeOffRequests={collaboratorTimeOffRequests} />;
};
