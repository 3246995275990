import { activityRegisterSlice } from "./slice/activity-register.slice";
import { configureStore } from "@reduxjs/toolkit";
//@ts-ignore
import { authLogReducer } from "../reducers/authLogReducer";
//@ts-ignore

//@ts-ignore
import { authReducer } from "../reducers/authReducer";
//@ts-ignore
import { cleanUpsReducer } from "../reducers/cleanUpsReducer";
//@ts-ignore
import { clientsReducer } from "../reducers/clientsReducer";
//@ts-ignore
import { collaboratorReducer } from "../reducers/collaboratorReducer";
//@ts-ignore
import { documentationReducer } from "../reducers/documentationReducer";
//@ts-ignore
import { dbUiReducer } from "../reducers/dbUiReducer";
//@ts-ignore
import { fcmReducer } from "../reducers/fcmReducer";
//@ts-ignore
import { miscReducer } from "../reducers/miscReducer";
//@ts-ignore
import { rfcReducer } from "../reducers/rfcReducer";
//@ts-ignore
import { userReducer } from "../reducers/userReducer";
import { timeOffRequestsSlice } from "./slice/timeOffRequestSlice";
import { tasksSlice } from "./slice/taskSlice";
import { workLogSlice } from "./slice/workLogsSlice";
import { attendanceRecordsSlice } from "./slice/attendanceRecordsSlice";
import { billingSlice } from "./slice/billingSlice";
import { authSlice } from "./slice/authSlice";
import { authActivitiesSlice } from "./slice/authActivitiesSlice";
import { notificationSlice } from "./slice/notificationSlice";
import { productsSlice } from "./slice/products.slice";
import { suppliersSlice } from "./slice/suppliers.slice";
import { controlledPrescriptionsSlice } from "./slice/controlled-prescription.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    // @ts-ignore
    authLogs: authLogReducer,
    // @ts-ignore
    cleanups: cleanUpsReducer,
    clients: clientsReducer,
    // @ts-ignore
    collaborator: collaboratorReducer,
    documentation: documentationReducer,
    dbUi: dbUiReducer,
    fcm: fcmReducer,
    misc: miscReducer,
    // @ts-ignore
    rfc: rfcReducer,
    // @ts-ignore
    users: userReducer,
    timeOffRequestsReducer: timeOffRequestsSlice.reducer,
    taskReducer: tasksSlice.reducer,
    workLogsReducer: workLogSlice.reducer,
    attendanceRecordsReducer: attendanceRecordsSlice.reducer,
    billingReducer: billingSlice.reducer,
    authReducer: authSlice.reducer,
    authActivitiesReducer: authActivitiesSlice.reducer,
    notificationsReducer: notificationSlice.reducer,
    activityRegisterReducer: activityRegisterSlice.reducer,
    products: productsSlice.reducer,
    suppliers: suppliersSlice.reducer,
    controlledPrescriptions: controlledPrescriptionsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
