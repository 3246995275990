import { INotification, NotificationReferenceType } from "../shared/types";

export const createNotificationLink = (notification: INotification) => {
  switch (notification.referenceType) {
    case NotificationReferenceType.TASK:
      return `/dashboard/tasks/${notification.referenceId}`;
    case NotificationReferenceType.BILL_CREATION_INFO:
      return `/dashboard/billing/create-bill/${notification.referenceId}`;
    case NotificationReferenceType.AUTH_ACTIVITY:
      return `/dashboard/auth/${notification.referenceId}`;
    case NotificationReferenceType.TIME_OFF_REQUEST:
      return `/dashboard/time-off-requests/${notification.referenceId}`;
    case NotificationReferenceType.USER:
      return `/dashboard/users/${notification.referenceId}`;
    default:
      return `/dashboard/notifications/${notification.id}`;
  }
};
