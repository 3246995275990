import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, FormikErrors } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Box,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  IControlledPrescription,
  ControlledPrescriptionItem,
} from "../../../../shared/types/controlled-prescription.types";
import { ControlledPrescriptionService } from "../../../../services";
import { FormikOptionSelect } from "../../../../components";
import {
  createOptionsFromArray,
  getValueByKey,
  sortAlphabeticallyByProperty,
} from "../../../../helpers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { controlledPrescriptionThunks } from "../../../../store/thunks";
import { getNextControlledPrescriptionNumber } from "../../../../shared/helpers";

const validationSchema = Yup.object().shape({
  supplier: Yup.object().shape({
    id: Yup.string().required("Supplier ID is required"),
    legalName: Yup.string(),
  }),
  products: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("Product ID is required"),
      quantity: Yup.number()
        .required("Quantity is required")
        .min(1, "Quantity must be at least 1"),
      batchCode: Yup.string().required("Batch code is required"),
      expirationDate: Yup.date().nullable(true).default(null),
    })
  ),
  date: Yup.date().required("Date is required"),
  number: Yup.number().required("Number is required"),
  use: Yup.mixed().oneOf(["internal", "external"]).required("Use is required"),
  status: Yup.string().required("Status is required"),
});

export const ControlledPrescriptionsForm = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { products } = useAppSelector((state) => state.products);
  const { suppliers } = useAppSelector((state) => state.suppliers);
  const sortedProducts = sortAlphabeticallyByProperty("name", products);
  const sortedSuppliers = sortAlphabeticallyByProperty("name", suppliers);
  const { controlledPrescriptionsStatusOptions, controlledPrescriptions } =
    useAppSelector((state) => state.controlledPrescriptions);

  const nextPrescriptionNumber = getNextControlledPrescriptionNumber(
    controlledPrescriptions
  );

  const [initialValues, setInitialValues] = useState<IControlledPrescription>({
    supplier: { id: "", legalName: "" },
    products: [
      {
        id: "",
        name: "",
        quantity: 1,
        batchCode: "",
        expirationDate: new Date(),
      },
    ],
    date: new Date(),
    number: nextPrescriptionNumber,
    use: "external",
    status: "pending",
  });

  useEffect(() => {
    if (id && id !== "new") {
      const fetchById = async () => {
        const service = new ControlledPrescriptionService();
        const resource = await service.getById(id);
        setInitialValues(resource);
      };
      fetchById();
    }
  }, [id]);

  const handleSubmit = (values: IControlledPrescription) => {
    if (id && id !== "new") {
      dispatch(controlledPrescriptionThunks.updateItem(values));
    } else {
      dispatch(controlledPrescriptionThunks.createItem(values));
    }
    navigate("/dashboard/controlled-prescriptions");
  };

  return (
    <Paper elevation={3}>
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          Controlled Prescription
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormikOptionSelect
                    label="Supplier"
                    name="supplier.id"
                    error={touched.supplier?.id && !!errors.supplier?.id}
                    options={createOptionsFromArray(sortedSuppliers, "name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" component="div">
                    <strong>Supplier Legal Name:</strong>{" "}
                    {getValueByKey(suppliers, "legalName", values.supplier.id)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={dayjs(values.date)} // Ensure this is a dayjs object
                      onChange={(date) => setFieldValue("date", date)}
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: { fullWidth: true },
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="number"
                    label="Number"
                    type="number"
                    error={touched.number && !!errors.number}
                    helperText={touched.number && errors.number}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    select
                    name="use"
                    label="Use"
                    error={touched.use && !!errors.use}
                    helperText={touched.use && errors.use}
                  >
                    <MenuItem value="internal">Internal</MenuItem>
                    <MenuItem value="external">External</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikOptionSelect
                    label="Status"
                    name="status"
                    error={touched.status && !!errors.status}
                    options={controlledPrescriptionsStatusOptions}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Products
                  </Typography>
                  <FieldArray name="products">
                    {({ push, remove }) => (
                      <Box>
                        {values.products.map(
                          (
                            product: ControlledPrescriptionItem,
                            index: number
                          ) => {
                            const productTouched = (
                              touched?.products as FormikErrors<ControlledPrescriptionItem>[]
                            )?.[index];
                            const productErrors = (
                              errors?.products as FormikErrors<ControlledPrescriptionItem>[]
                            )?.[index];

                            return (
                              <Box key={index} mb={2}>
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={12} sm={3}>
                                    <FormikOptionSelect
                                      label="Product Name"
                                      name={`products.${index}.id`}
                                      error={
                                        !!productTouched?.id &&
                                        !!productErrors?.id
                                      }
                                      options={createOptionsFromArray(
                                        sortedProducts,
                                        "name"
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={2}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      name={`products.${index}.quantity`}
                                      label="Quantity"
                                      type="number"
                                      error={
                                        productTouched?.quantity &&
                                        !!productErrors?.quantity
                                      }
                                      helperText={
                                        productTouched?.quantity &&
                                        productErrors?.quantity
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={2}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      name={`products.${index}.batchCode`}
                                      label="Batch Code"
                                      error={
                                        productTouched?.batchCode &&
                                        !!productErrors?.batchCode
                                      }
                                      helperText={
                                        productTouched?.batchCode &&
                                        productErrors?.batchCode
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={2}>
                                    <Box width="100%">
                                      <DatePicker
                                        label="Expiration Date"
                                        value={
                                          values.products[index].expirationDate
                                            ? dayjs(
                                                values.products[index]
                                                  .expirationDate
                                              )
                                            : null
                                        }
                                        onChange={(date) => {
                                          if (!date) return;
                                          setFieldValue(
                                            `products.${index}.expirationDate`,
                                            date ? date.toDate() : null
                                          );
                                        }}
                                        format="DD-MM-YYYY"
                                        slotProps={{
                                          textField: { fullWidth: true },
                                          field: {
                                            clearable: true,
                                          },
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={2}>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => remove(index)}
                                    >
                                      Remove Product
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          }
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            push({
                              id: "",
                              name: "",
                              quantity: 1,
                              batchCode: "",
                              expirationDate: new Date(),
                            })
                          }
                        >
                          Add Product
                        </Button>
                      </Box>
                    )}
                  </FieldArray>
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};
