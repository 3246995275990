import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { sortTasksByCriteria } from "../../../../helpers/tasksHelpers";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFilterTasksHook } from "../../../../hooks/useFilterTasksHook";
import { TasksFilters } from "./TaskViewComponents/TasksFilters";
import { useSortTasksHook } from "../../../../hooks/useSortTasksHook";
import { useFilterTaksByInclusions } from "../../../../hooks/useFilterTaksByInclusions";
import { TaskListCard } from "./TaskListCard";

export const TaskList = () => {
  const { tasks } = useAppSelector((state) => state.taskReducer);

  const {
    filteredTasksByCheckBoxes,
    showCanceled,
    showCompleted,
    handleShowCanceledChange,
    handleShowCompletedChange,
  } = useFilterTaksByInclusions(tasks);

  const { filters, filteredTasks, handleFilterChange } = useFilterTasksHook(
    filteredTasksByCheckBoxes
  );
  const { sortBy, sortOrder, handleSortByChange, handleSortOrderChange } =
    useSortTasksHook(filteredTasksByCheckBoxes);

  const sortedTasks = sortTasksByCriteria(filteredTasks, sortBy, sortOrder);

  console.log({ sortedTasks });

  return (
    <Box>
      <Box marginBottom={4}>
        {/* FILTERS */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={1}
          width="100%"
        >
          <Typography variant="h5" align="center">
            Filters
          </Typography>
          <Box width="100%">
            <TasksFilters
              filters={filters}
              handleFilterChange={handleFilterChange}
            />
          </Box>
        </Box>
        {/* SORTING */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <FormControl>
            <Typography>Sort by:</Typography>
            <Select
              defaultValue="number"
              value={sortBy}
              onChange={handleSortByChange}
            >
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="title">Title</MenuItem>
              <MenuItem value="dueDate">Due Date</MenuItem>
              <MenuItem value="updatedAt">Updated At</MenuItem>
              <MenuItem value="registeredAt">Registered At</MenuItem>
              <MenuItem value="completedAt">Completed At</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <Typography>Order:</Typography>
            <Select
              defaultValue="asc"
              value={sortOrder}
              onChange={handleSortOrderChange}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                name="showCompleted"
                checked={showCompleted}
                onChange={handleShowCompletedChange}
              />
            }
            label="Show Completed"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="showCanceled"
                checked={showCanceled}
                onChange={handleShowCanceledChange}
              />
            }
            label="Show Canceled"
          />
        </Box>
      </Box>

      <Box>
        {sortedTasks.map((task) => (
          <TaskListCard key={task._id} task={task} />
        ))}
      </Box>
    </Box>
  );
};

// number, title, author, priority, assignees, status, requestedAt, tags
