import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  field: keyof Task;
  onSave: (updatedTask: Task) => void;
  fontColor?: string;
};

export const EditableFieldString: React.FC<Props> = ({
  task,
  field,
  onSave,
  fontColor,
}) => {
  const [editedValue, setEditedValue] = useState<string>(
    task[field]?.toString() ?? ""
  );
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedValue(event.target.value);
  };

  const handleSave = async () => {
    if (editedValue.length < 6) {
      fireSwalError("The task description must be at least 8 characters long");
      setIsEditable(false);

      return;
    }
    await onSave({ ...task, [field]: editedValue });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <>
      {isEditable ? (
        <>
          <TextField
            fullWidth
            name={field}
            value={editedValue}
            onChange={handleChange}
            multiline
            rows={4}
            inputProps={fontColor ? { color: fontColor } : {}}
          />
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <>
          <Typography>{task[field]?.toString()}</Typography>
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </>
      )}
    </>
  );
};
