import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import {
  getDurationInHours,
  getFormattedMxDatetime,
} from "../../../../helpers/dateHelpers";
import { IActivityRegister } from "../../../../shared/types";
import {
  useAppDispatch,
  useAppSelector,
  useCollaboratorHook,
} from "../../../../hooks";
import { getOptionLabel } from "../../../../helpers/options.helpers";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { deleteActivityRegister } from "../../../../store/thunks";

interface ActivityRegistersTableProps {
  activityRegisters: IActivityRegister[];
}

export const ActivityRegistersTable: React.FC<ActivityRegistersTableProps> = ({
  activityRegisters,
}) => {
  const dispatch = useAppDispatch();
  const { activityRegisterTypes } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const { getCollaboratorCodeById } = useCollaboratorHook();
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/dashboard/activity-register/${id}`);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteActivityRegister(id));
  };
  return (
    <Box marginTop={2}>
      <Typography variant="h6">Activity Registers</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Collaborator</TableCell>
              <TableCell width="10%">Activity</TableCell>
              <TableCell width="20%">Description</TableCell>
              <TableCell width="20%">Starting Time</TableCell>
              <TableCell width="20%">Ending Time</TableCell>
              <TableCell width="10%">Duration</TableCell>
              <TableCell width="10%">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityRegisters.map((activity) => (
              <TableRow key={activity.id}>
                <TableCell>
                  {getCollaboratorCodeById(activity.collaborator)}
                </TableCell>
                <TableCell>
                  {getOptionLabel(activityRegisterTypes, activity.activity)}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {activity.desc}
                </TableCell>
                <TableCell>
                  {getFormattedMxDatetime(activity.startingTime)}
                </TableCell>
                <TableCell>
                  {getFormattedMxDatetime(activity.endingTime)}
                </TableCell>
                <TableCell>
                  {getDurationInHours(
                    activity.startingTime,
                    activity.endingTime
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(activity.id!)}>
                    <EditIcon color="success" />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(activity.id!)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>{" "}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
