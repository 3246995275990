import { Card, CardContent, Typography, Box } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Task } from "../../../../shared/types/Task";
import { CompletionRateChart } from "./statsComponents/CompletionRate";
import { MainNumbers } from "./statsComponents/MainNumbers";
import { CompletionTrendsChart } from "./statsComponents/CompletionTrendsChart";
import { ViewType } from "./TaskStats";
import { ProductivityTasksTable } from "./statsComponents/ProductivityTasksTable";
import { filterTasksByDateRange } from "../../../../helpers/tasksHelpers";

type Props = {
  tasks: Task[];
  startDate: Date;
  endDate: Date;
  viewType: ViewType;
};
export const TaskStatsDashboard: React.FC<Props> = ({
  tasks,
  startDate,
  endDate,
  viewType,
}) => {
  const [filteredTasks, setFilteredTasks] = useState<Task[]>(tasks);

  useEffect(() => {
    const filteredTasks = filterTasksByDateRange(tasks, startDate, endDate);

    setFilteredTasks(filteredTasks);
  }, [startDate, endDate, tasks]);

  return (
    <Box sx={{ "& > :not(:last-child)": { mb: 2 } }}>
      <Card>
        <CardContent>
          <Typography variant="h5">Main Numbers</Typography>
          <Box display="flex" justifyContent="center">
            <MainNumbers
              tasks={filteredTasks}
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h5">Completion Rate</Typography>
          <Box display="flex" justifyContent="center">
            <CompletionRateChart tasks={filteredTasks} />
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h5">Completion Trends</Typography>
          <CompletionTrendsChart
            tasks={filteredTasks}
            viewType={viewType}
            startDate={startDate}
            endDate={endDate}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h5">Productivity</Typography>
          <ProductivityTasksTable
            tasks={filteredTasks}
            startDate={startDate}
            endDate={endDate}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
