import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { getCollaboratorAttendanceRecords } from "../../../../store/thunks/attendanceRecordsThunks";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import { AttendanceRecordsTable } from "./AttendanceRecordsTable";

export const AttendanceRecordsHistory = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCollaboratorAttendanceRecords());
  }, [dispatch]);
  const { collaboratorAttendanceRecords: attendanceRecords } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  return (
    <>
      <AttendanceRecordsTable attendanceRecords={attendanceRecords} />
    </>
  );
};
