import {
  positionTypes,
  procedureTypes,
  roleTypes,
} from "../shared/types/types";
import dayjs from "dayjs";
import { Fragment } from "react";
import {
  Check,
  ChromeReaderMode,
  Clear,
  GridOn,
  MoreHoriz,
  PictureAsPdf,
  YouTube,
} from "@mui/icons-material/";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import isYesterday from "dayjs/plugin/isYesterday";
import Swal from "sweetalert2";
import { uploadImg } from "./uploadImg";

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);

export const generateRandomString = (length = 4) => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
};

export const sortCollaborators = (
  collaborators,
  sortCriteria = "position",
  order = true,
  primer = (a) => a.toUpperCase()
) => {
  const sort_by = (sortCriteria, reverse, primer) => {
    const key = primer
      ? function (x) {
          return primer(x[sortCriteria]);
        }
      : function (x) {
          return x[sortCriteria];
        };

    order = reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  return collaborators.sort(sort_by(sortCriteria, order, primer));
};

export const sortCollection = (collection = [], orderCriteria = "position") => {
  // get the values to sort
  let sortOrder = getValuesFromObject(positionTypes);

  // sort function
  const sortByPosition = (a, b) => {
    if (a.position == b.position) {
      // If the elements both have the same `position`,
      return a.col_numId - b.col_numId; // Compare the elements by id number.
    } else {
      // Otherwise,
      return sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position); // Substract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
    }
  };

  let sortByDate = (a, b) => {
    a = dayjs(a.date);
    b = dayjs(b.date);
    return -a.diff(b);
  };

  let compareFunction = () => {};
  if (orderCriteria === "position") {
    compareFunction = sortByPosition;
  }

  if (orderCriteria === "date") {
    compareFunction = sortByDate;
  }

  const collectionToSort = [...collection];

  return collectionToSort.sort(compareFunction);
};

const getValuesFromObject = (object) => {
  return Object.values(object);
};

// export const convertPlainTextToHtml = (text = "") => {
//   let newText = "<div><p>";
//   let lines = text.split("\n\n");
//   lines.forEach((element) => {
//     newText = newText.concat(element);
//     newText = newText.concat("</p><p>");
//   });
//   newText = newText.concat("</p></div>");

//   const parser = new DOMParser();

//   return ReactHtml;
// };

export const getTextAsJSX = (text = "") => {
  const JSXtoReturn =
    text &&
    text.split("\n").map((i, key) => {
      return <p key={key}>{i}</p>;
    });

  return JSXtoReturn;
};

// receive cleanups and return them with dates to string and ordered by date
export const formatAndOrderCollection = (collection = []) => {
  const sortedCollection = sortCollection(collection, "date");
  const formattedCollection = convertCollectionDatesToString(sortedCollection);
  return formattedCollection;
};

export const convertCollectionDatesToString = (collection = []) => {
  return collection.map((item) => {
    // Create a new object with the modified date property
    return { ...item, date: getSimplifiedStringDate2(item.date) };
  });
};

export const getSimplifiedStringDate2 = (date = new Date()) => {
  const formattedDate = new Date(date);
  const day = formattedDate.getUTCDate().toString().padStart(2, "0");
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = formattedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getSimplifiedStringDate = (date = new Date()) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const getIdOrEmpty = (object) => {
  return "hola";
};

export const getAColumn = (field, headerName, flex, property) => {
  return {
    field,
    headerName,
    flex,
    renderCell: (params) => {
      return (
        <Fragment>
          {params.row.activities[property] ? <Check /> : <Clear />}
        </Fragment>
      );
    },
  };
};

// *********   CLEANUPS *********************
export const getBranchesSummary = (lastMonthCleanUps = []) => {
  // format a branch data
  const defaultBranchData = {
    daysCleaned: 0,
    daysNotCleaned: 0,
    deepCleanUps: 0,
    operatingRoomCleanUps: 0,
  };

  // add branch to the data
  const urban = { ...defaultBranchData, branch: "Urban" };
  const harbor = { ...defaultBranchData, branch: "Harbor" };
  const montejo = { ...defaultBranchData, branch: "Montejo" };

  // branches summary. Return item
  const branchesSummary = [urban, harbor, montejo];

  lastMonthCleanUps.dailyCleanUps.forEach((dailyCleanUp) => {
    // find the corresponding branch
    const branch = branchesSummary.find(
      (b) => b.branch === dailyCleanUp.branch
    );

    // update the branch properties without mutating the original object
    if (branch) {
      branch.daysCleaned += dailyCleanUp.cleaners.length > 0 ? 1 : 0;
      branch.daysNotCleaned += dailyCleanUp.cleaners.length === 0 ? 1 : 0;
    }
  });

  lastMonthCleanUps.deepCleanUps.forEach((deepCleanUp) => {
    // find the corresponding branch
    const branch = branchesSummary.find((b) => b.branch === deepCleanUp.branch);

    // update the branch properties without mutating the original object
    if (branch && deepCleanUp.cleaners.length > 0) {
      branch.deepCleanUps += 1;
    }
  });

  lastMonthCleanUps.operatingRoomCleanUps.forEach((entry) => {
    // find the corresponding branch
    const branch = branchesSummary.find((b) => b.branch === entry.branch);

    // update the branch properties without mutating the original object
    if (branch && entry.cleaners.length > 0) {
      branch.operatingRoomCleanUps += 1;
    }
  });

  return branchesSummary;
};

export const getCollaboratorsCleanUpsSummary = (
  collaborators,
  lastMonthCleanUps
) => {
  const collaboratorsCleanUpsSummary = collaborators.map((collaborator) => ({
    ...collaborator,
    cleanUps: [], // Create a new array for each collaborator
  }));

  fillCollaboratorsCleanUps(
    lastMonthCleanUps.dailyCleanUps,
    collaboratorsCleanUpsSummary
  );
  fillCollaboratorsCleanUps(
    lastMonthCleanUps.operatingRoomCleanUps,
    collaboratorsCleanUpsSummary
  );
  fillCollaboratorsCleanUps(
    lastMonthCleanUps.deepCleanUps,
    collaboratorsCleanUpsSummary
  );

  const compare = (a, b) => {
    return b.cleanUps.length - a.cleanUps.length;
  };

  const sorted = collaboratorsCleanUpsSummary.sort(compare);

  return sorted;
};

const fillCollaboratorsCleanUps = (cleanUpsCollection, collaborators) => {
  cleanUpsCollection.map((cleanUp) => {
    // check if there are claners
    if (cleanUp.cleaners.length > 0) {
      // iterate through cleaners and supervisors
      cleanUp.cleaners.map((cleaner) => {
        if (!cleaner.cleaner) return "";
        collaborators.forEach((collaborator) => {
          if (cleaner.cleaner.col_code === collaborator.col_code) {
            collaborator.cleanUps.push(cleanUp.date);
          }
        });
      });
      cleanUp.supervisors.map((element) => {
        collaborators.forEach((collaborator) => {
          if (element.supervisor.col_code === collaborator.col_code) {
            collaborator.cleanUps.push(cleanUp.date);
          }
        });
      });
    }
  });
};

// return true if the role is the same or above
export const checkAuthorization = (
  role = roleTypes.guest,
  requiredAuthorization = roleTypes.admin
) => {
  if (requiredAuthorization === roleTypes.admin) {
    if (role === roleTypes.admin) return true;
  }
  if (requiredAuthorization === roleTypes.manager) {
    if (role === roleTypes.admin || role === roleTypes.manager) return true;
  }
  if (requiredAuthorization === roleTypes.collaborator) {
    if (
      role === roleTypes.admin ||
      role === roleTypes.manager ||
      role === roleTypes.collaborator
    )
      return true;
  }
  if (requiredAuthorization === roleTypes.user) {
    if (
      role === roleTypes.admin ||
      role === roleTypes.manager ||
      role === roleTypes.collaborator ||
      role === roleTypes.user
    )
      return true;
  }
  return false;
};

export const getCalcItems = (procedure, recValues) => {
  let values = { ...recValues };

  if (typeof procedure === "undefined") {
    return;
  }

  if (
    procedure.title === procedureTypes.initialRacePurity.title ||
    procedure.title === procedureTypes.initialRegister.title ||
    procedure.title === procedureTypes.contestCertificate.title
  ) {
    values.puppiesNum = 1;
  }
  if (procedure.title === procedureTypes.initialRacePurity.title) {
    values.olderThan3Months = true;
  }

  let calcItems = [];

  // PEDIGREE 6 sem 3 meses
  if (
    procedure.title === procedureTypes.pedigree.title &&
    values.puppiesNum > 0 &&
    !values.olderThan3Months
  ) {
    let item = {
      text: "Registro de camadas de 6 semanas a 3 meses de edad por perro con 3 generaciones",
      qty: values.puppiesNum,
      price: "$600",
      subtotal: values.puppiesNum * 600,
    };
    calcItems.push(item);
  }
  // PEDIGREE 3 a 8 meses
  if (
    procedure.title === procedureTypes.pedigree.title &&
    values.puppiesNum > 0 &&
    values.olderThan3Months
  ) {
    let item = {
      text: "Registro de camadas de 3 a 9 meses de edad por perro con 3 generaciones",
      qty: values.puppiesNum,
      price: "$700",
      subtotal: values.puppiesNum * 700,
    };
    calcItems.push(item);
  }

  // Pureza racial, Pureza racial inicial
  if (
    procedure.title === procedureTypes.racePurity.title ||
    procedure.title === procedureTypes.initialRacePurity.title
  ) {
    let item = {
      text: "Certificado de pureza racial (CPR)",
      qty: values.puppiesNum,
      price: "$700",
      subtotal: values.puppiesNum * 700,
    };
    calcItems.push(item);
  }

  // Registro inicial
  if (procedure.title === procedureTypes.initialRegister.title) {
    let item = {
      text: "Registro inicial (CRI) hasta 6 meses",
      qty: values.puppiesNum,
      price: "$700",
      subtotal: values.puppiesNum * 700,
    };
    calcItems.push(item);
  }

  // Registro inicial
  if (procedure.title === procedureTypes.contestCertificate.title) {
    let item = {
      text: "Certificado para concurso",
      qty: values.puppiesNum,
      price: "$700",
      subtotal: values.puppiesNum * 700,
    };
    calcItems.push(item);
  }

  // Microchip

  if (values.puppiesNum > 0) {
    let item = {
      text: "Microchip aplicado por médico inspector",
      qty: values.puppiesNum,
      price: "$700",
      subtotal: values.puppiesNum * 700,
    };
    calcItems.push(item);
  }

  // Tatuajes
  if (
    // procedure === procedureTypes.pedigree.value &&
    values.puppiesNum > 0 &&
    !values.olderThan3Months
  ) {
    let item = {
      text: "Tatuaje perros de 6 semanas a 3 meses de edad",
      qty: values.puppiesNum,
      price: "$500",
      subtotal: values.puppiesNum * 500,
    };
    calcItems.push(item);
  }

  // Tatuajes
  if (
    // procedure === procedureTypes.pedigree.value &&
    values.puppiesNum > 0 &&
    values.olderThan3Months
  ) {
    let item = {
      text: "Tatuaje perros mayores de 3 meses de edad",
      qty: values.puppiesNum,
      price: "$600",
      subtotal: values.puppiesNum * 600,
    };
    calcItems.push(item);
  }

  // Sedación
  if (
    // procedure === procedureTypes.pedigree.value &&
    values.puppiesNum > 0 &&
    values.sedationIsNeeded
  ) {
    let item = {
      text: "Tranquilización para perros que la requieran",
      qty: values.puppiesNum,
      price: "$600",
      subtotal: values.puppiesNum * 400,
    };
    calcItems.push(item);
  }

  // Socios
  if (values.partnerNum > 0) {
    let item = {
      text: "Socio usufructuario cuota anual",
      qty: values.partnerNum,
      price: "$900",
      subtotal: values.partnerNum * 900,
    };
    calcItems.push(item);
  }

  // Transferencias
  if (values.transfersNum > 0) {
    let item = {
      text: "Transferencia cualquier tipo de registro (cambio de propietario)",
      qty: values.transfersNum,
      price: "$600",
      subtotal: values.transfersNum * 600,
    };
    calcItems.push(item);
  }

  // Envío
  let send = {
    text: "Envío por mensajería",
    qty: 1,
    price: "$900",
    subtotal: 1 * 900,
  };

  calcItems.push(send);

  return calcItems;
};

export const getFCMTotal = (calcItems) => {
  let total = 0;
  calcItems.forEach((calcItem) => {
    total = total + calcItem.subtotal;
  });
  return total;
};

export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

export const findObjectByProperty = (collection, property, matchValue) => {
  return collection.find((obj) => {
    return obj[property] === matchValue;
  });
};

export const findObjectById = (collection, propertyValue) => {
  return collection.find((obj) => {
    return obj._id === propertyValue;
  });
};

export const isObjectEmpty = (object) => {
  if (!object) {
    return true;
  }
  return Object.keys(object).length === 0;
};

export const checkIfIsBetween = (collection, object) => {
  let isBetween = false;
  let objStartingTime = dayjs(object.startingTime);
  let objEndingTime = dayjs(object.endingTime);

  collection.map((element) => {
    if (object._id !== element._id) {
      if (
        objStartingTime.isBetween(
          dayjs(element.startingTime),
          dayjs(element.endingTime)
        ) ||
        objEndingTime.isBetween(
          dayjs(element.startingTime),
          dayjs(element.endingTime)
        ) ||
        objStartingTime.isSame(dayjs(element.startingTime))
      ) {
        isBetween = true;
      }
    }
  });

  return isBetween;
};

export const checkIfIsLong = (object) => {
  let isLong = false;
  let objStartingTime = dayjs(object.startingTime);
  let objEndingTime = dayjs(object.endingTime);

  if (objEndingTime.diff(objStartingTime, "hour") >= 14) {
    isLong = true;
  }

  return isLong;
};

export const checkIfIsOld = (object) => {
  let isOld = false;
  let objStartingTime = dayjs(object.startingTime);

  if (dayjs().diff(objStartingTime, "hour") >= 84) {
    isOld = true;
  }

  return isOld;
};

export const checkIfIsStartBeforeEnd = (object) => {
  if (!object.endingTime) {
    return true;
  }

  let objStartingTime = dayjs(object.startingTime);
  let objEndingTime = dayjs(object.endingTime);

  return objStartingTime.isBefore(objEndingTime);
};

export const sortCollectionByDate = (collection, datePropName) => {
  let sortByDate = (a, b) => {
    a = dayjs(a[datePropName]);
    b = dayjs(b[datePropName]);
    return -a.diff(b);
  };
  const collectionToSort = [...collection];
  return collectionToSort.sort(sortByDate);
};

export const sortCollectionAlphabetically = (collection, propName) => {
  let sortAlphabetically = (a, b) => {
    if (a[propName] < b[propName]) {
      return -1;
    }
    if (a[propName] > b[propName]) {
      return 1;
    }
    return 0;
  };
  const collectionToSort = [...collection];
  return collectionToSort.sort(sortAlphabetically);
};

export const sortObjectPropertiesAlphabetically = (object) => {
  return Object.fromEntries(
    Object.entries(object).sort(([, a], [, b]) => a - b)
  );
};

export const calculateTotalHours = (collection = []) => {
  const result = collection.reduce((acc, item) => {
    if (item.endingTime) {
      return (
        acc +
        dayjs.duration(dayjs(item.endingTime).diff(item.startingTime)).asHours()
      );
    } else {
      return acc;
    }
  }, 0);
  return (Math.round(result * 100) / 100).toFixed(2);
};

export const calculateYesterdayHours = (collection = []) => {
  const result = collection.reduce((acc, item) => {
    if (dayjs(item.startingTime).isYesterday() && item.endingTime) {
      return (
        acc +
        dayjs.duration(dayjs(item.endingTime).diff(item.startingTime)).asHours()
      );
    } else {
      return acc;
    }
  }, 0);

  return (Math.round(result * 100) / 100).toFixed(2);
};

export const calculateWeekHours = (collection = []) => {
  const result = collection.reduce((acc, item) => {
    if (
      dayjs(item.startingTime).isAfter(dayjs().startOf("week").add(1, "day")) &&
      item.endingTime
    ) {
      return (
        acc +
        dayjs.duration(dayjs(item.endingTime).diff(item.startingTime)).asHours()
      );
    } else {
      return acc;
    }
  }, 0);

  return (Math.round(result * 100) / 100).toFixed(2);
};

export const calculateMonthHours = (collection = []) => {
  const result = collection.reduce((acc, item) => {
    if (
      dayjs(item.startingTime).isAfter(dayjs().startOf("month")) &&
      item.endingTime
    ) {
      return (
        acc +
        dayjs.duration(dayjs(item.endingTime).diff(item.startingTime)).asHours()
      );
    } else {
      return acc;
    }
  }, 0);

  return (Math.round(result * 100) / 100).toFixed(2);
};

export const getConcludedActivityRegisters = (collection) => {
  return collection.filter((element) => element.endingTime);
};

export const fireSwalError = (message) => {
  return Swal.fire({
    icon: "error",
    title: "ERROR",
    text: message,
    showConfirmButton: true,
  });
};

export const fireSwalSuccess = (message) => {
  return Swal.fire({
    icon: "success",
    title: "ÉXITO",
    text: message,
    showConfirmButton: false,
    timer: 2000,
  });
};

export const fireSwalConfirmation = async (message) => {
  const result = await Swal.fire({
    icon: "warning",
    title: "CONFIRMACIÓN",
    text: message,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sí!",
  });

  console.log({ result });

  return result.isConfirmed;
};

export const fireSwalQuestionSelect = async (
  message,
  inputOptions,
  inputPlaceholder
) => {
  const sortedInputOptions = convertArrayToObjectAndSort(inputOptions);

  const result = await Swal.fire({
    title: "RESPONDE",
    text: message,
    icon: "question",
    input: "select",
    inputOptions: sortedInputOptions,
    inputPlaceholder: inputPlaceholder,
    showCancelButton: true,
  });

  return result.value;
};

export const fireSwalQuestionInput = async (message, inputLabel) => {
  const result = await Swal.fire({
    title: "RESPONDE",
    text: message,
    icon: "question",
    input: "text",
    inputLabel,
  });

  return result.value;
};

export const getDuration = (startTime, endTime) => {
  return dayjs.duration(dayjs(endTime).diff(startTime)).asHours();
};

const isRegisterInCustomMonth = (activityRegister, targetYear, targetMonth) => {
  const registerYear = dayjs(activityRegister.startingTime).year();
  const registerMonth = dayjs(activityRegister.startingTime).month();

  return registerYear === targetYear && registerMonth === targetMonth;
};

export const getColsActivityRegisters = (activityRegisters) => {
  const colMap = new Map();

  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();
  const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  activityRegisters.forEach((activityRegister) => {
    if (!activityRegister.endingTime) {
      return;
    }

    const { _id, col_code, imgUrl } = activityRegister.collaborator;

    if (colMap.has(col_code)) {
      const col = colMap.get(col_code);
      col.registersAmount++;
      col.thisMonthTime += isRegisterInCustomMonth(
        activityRegister,
        currentYear,
        currentMonth
      )
        ? getDuration(
            activityRegister.startingTime,
            activityRegister.endingTime
          )
        : 0;
      col.prevMonthTime += isRegisterInCustomMonth(
        activityRegister,
        prevYear,
        prevMonth
      )
        ? getDuration(
            activityRegister.startingTime,
            activityRegister.endingTime
          )
        : 0;
      col.totalTime += getDuration(
        activityRegister.startingTime,
        activityRegister.endingTime
      );
      col.registers.push(activityRegister);
    } else {
      colMap.set(col_code, {
        _id,
        col_code,
        imgUrl,
        registersAmount: 1,
        totalTime: getDuration(
          activityRegister.startingTime,
          activityRegister.endingTime
        ),
        thisMonthTime: isRegisterInCustomMonth(
          activityRegister,
          currentYear,
          currentMonth
        )
          ? getDuration(
              activityRegister.startingTime,
              activityRegister.endingTime
            )
          : 0,
        prevMonthTime: isRegisterInCustomMonth(
          activityRegister,
          prevYear,
          prevMonth
        )
          ? getDuration(
              activityRegister.startingTime,
              activityRegister.endingTime
            )
          : 0,
        registers: [activityRegister],
      });
    }
  });

  // Round numeric properties to two decimal places
  const roundedCols = Array.from(colMap.values()).map((col) => ({
    ...col,
    totalTime: parseFloat(col.totalTime.toFixed(2)),
    thisMonthTime: parseFloat(col.thisMonthTime.toFixed(2)),
    prevMonthTime: parseFloat(col.prevMonthTime.toFixed(2)),
  }));

  return roundedCols;
};

export const convertArrayToObjectAndSort = (collection = []) => {
  const sortedCollection = sortCollectionAlphabetically(collection, "label");
  let newObject = {};
  sortedCollection.forEach((element) => {
    newObject[element.value] = element.label;
  });

  return newObject;
};

export const findLabelByValue = (collection = [], value) => {
  const foundElement = collection.find((element) => element.value === value);
  const label = foundElement?.label;
  if (!label) {
    return value;
  }
  return label;
};

export const findValueByLabel = (collection = [], label) => {
  const foundElement = collection.find((element) => element.label === label);
  const value = foundElement?.value;
  if (!value) {
    return label;
  }
  return value;
};

export const organiseDocumentation = (data) => {
  // const colArray = [
  //   {typeName, data[
  //  {topicName, data[
  // {title, format, link, date, author, status}
  // ]}

  // ]}
  // ];
  const organisedDocumentation = [];

  data.map((element) => {
    // variable to determine if the type existed
    let typeExistedBefore = false;
    for (let type of organisedDocumentation) {
      if (type.typeName === element.type) {
        typeExistedBefore = true;
        // variable to determine if the topic existed
        let topicExistedBefore = false;
        for (let topic of type.data) {
          if (topic.topicName === element.topic) {
            topicExistedBefore = true;
            topic.data.push(element);
            break;
          }
        }
        if (!topicExistedBefore) {
          let newTopic = {
            topicName: element.topic,
            data: [element],
          };
          type.data.push(newTopic);
        }

        break;
      }
    }
    if (!typeExistedBefore) {
      let newType = {
        typeName: element.type,
        data: [{ topicName: element.topic, data: [element] }],
      };
      organisedDocumentation.push(newType);
    }
  });

  return organisedDocumentation;
};

export const prepareDocumentation = (documentation, role) => {
  const authorizedDocumentation = documentation.filter((element) => {
    if (checkAuthorization(role, roleTypes[element.authorization])) {
      return element;
    }
  });

  return organiseDocumentation(authorizedDocumentation);
};

export const checkIfLabelAndValueAreUnique = (values, collection) => {
  let isNotUniqueValue = findObjectByProperty(
    collection,
    "value",
    values.value
  );
  let isNotUniqueLabel = findObjectByProperty(
    collection,
    "label",
    values.label
  );

  if (isNotUniqueValue || isNotUniqueLabel) {
    return false;
  }
  return true;
};

export const getFormatIcon = (format) => {
  if (format === "video") {
    return <YouTube />;
  }
  if (format === "spreadsheet") {
    return <GridOn />;
  }
  if (format === "pdf") {
    return <PictureAsPdf />;
  }
  if (format === "textDocument") {
    return <ChromeReaderMode />;
  }
  return <MoreHoriz />;
};

export const getTxtClass = (status) => {
  switch (status) {
    case "updated":
      return "txt-success";

    case "semiOutdated":
      return "txt-warning";

    case "outdated":
      return "txt-danger";

    case "notValid":
      return "txt-gray";

    case "notApplicable":
      return "txt-dark";

    default:
      return null;
  }
};

export const getRoleTypesLabelsAndValues = () => {
  const newRoleTypes = [];
  Object.entries(roleTypes).map((item) => {
    newRoleTypes.push({
      label: item[1],
      value: item[0],
    });
  });

  return newRoleTypes;
};

export const getLabelsAndValuesFromCollection = (
  collection,
  labelProperty,
  valueProperty
) => {
  const labelsAndValues = [];
  collection.map((element) => {
    labelsAndValues.push({
      label: element[labelProperty],
      value: element[valueProperty],
    });
  });

  return sortCollectionAlphabetically(labelsAndValues, "label");
};

export const formatDatePropertyJustDate = (collection, dateProperty) => {
  return collection.map((element) => {
    if (element[dateProperty] === null) {
      element[dateProperty] = "";
    } else {
      element[dateProperty] = dayjs(element[dateProperty]).format("YYYY-MM-DD");
    }
    return element;
  });
};

export const formatDatePropertyToIsoString = (object, dateProperty) => {
  if (object[dateProperty]) {
    object[dateProperty] = dayjs(object[dateProperty]).toISOString();
  }
};

export const trimAllValues = (object) => {
  const newObject = { ...object };
  for (const [key, value] of Object.entries(object)) {
    newObject[key] = value.trim();
  }

  return newObject;
};

// todo: Doing
export const getCollaboratorCodeById = () => {};

export const excludeFromCollection = (
  collection,
  collectionToExclude,
  propertyName = "_id"
) => {
  const propertyArray = collectionToExclude.map((element) => {
    return element[propertyName];
  });

  return collection.filter(
    (element) => !propertyArray.includes(element[propertyName])
  );
};

export const setUrlValueOrRefreshImage = async (
  values,
  files,
  urlProperty,
  url
) => {
  let newValues = { ...values };
  if (files.length > 0) {
    const tempImgUrl = await uploadImg(files[0], false);
    newValues[urlProperty] = tempImgUrl;
  } else {
    values[urlProperty] = url;
  }

  return { ...newValues };
};

export const replaceElementInCollection = (object, collection) => {
  return collection.map((element) => {
    if (element._id === object._id) {
      return object;
    } else {
      return element;
    }
  });
};

export const includeInPackage = (collection = [], object, propertyName) => {
  if (!collection) {
    return [object];
  }
  const index = collection.findIndex(
    (element) => element[propertyName] === object[propertyName]
  );

  if (index >= 0) {
    collection[index] = object;
  } else {
    collection.push(object);
  }

  return [...collection];
};

export const isStepSkipped = (set = new Set(), step) => {
  return set.has(step);
};

export const objectContainsObjectProperties = (bigObject, smallObject) => {
  return Object.entries(smallObject).every(
    (element) => bigObject[element[0]] == element[1]
  );
};

export const getFullNameOfObject = (object) => {
  return `${object?.firstName || ""} ${object?.paternalSurname || ""} ${
    object?.maternalSurname || ""
  }`;
};

export const checkIfUrlOrFileExist = (files, url) => {
  if (files.length === 0 && !url) {
    return false;
  }
  return true;
};

export const getImgUrlByFileOrUrl = async (files, url) => {
  return files.length > 0 ? await uploadImg(files[0], false) : url;
};

export const printAndFireError = (error) => {
  fireSwalError(error.message);
};

export const transformBooleanToString = (boolean) => {
  return boolean ? "Sí" : "No";
};

export const testData = {
  activityRegisters: [
    {
      _id: "657a5ecc89db1ce5d2847cc3",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-12-13T14:40:00.000Z",
      endingTime: "2023-12-14T01:48:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "657a5e5689db1ce5d2847c9a",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-12-12T15:15:00.000Z",
      endingTime: "2023-12-13T01:00:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "6574ce28cd51ccb3ed12d2f7",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-12-11T14:00:00.000Z",
      endingTime: "2023-12-11T23:00:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "6574cdfccd51ccb3ed12d2e0",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-12-08T14:00:00.000Z",
      endingTime: "2023-12-08T22:00:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "6573a7a0f53540119749b071",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-12-08T17:00:00.000Z",
      endingTime: "2023-12-08T20:00:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "6574cdc8cd51ccb3ed12d2c9",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-12-07T15:45:00.000Z",
      endingTime: "2023-12-08T00:10:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "65734092d00b311c582f5c54",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-12-07T18:10:00.000Z",
      endingTime: "2023-12-07T22:19:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "6564bdfb35b38e6068ead7ba",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-12-06T14:15:00.000Z",
      endingTime: "2023-12-06T22:30:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "65734072d00b311c582f5c42",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-12-06T15:16:00.000Z",
      endingTime: "2023-12-06T21:16:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "65734016d00b311c582f5c2b",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-12-05T14:14:00.000Z",
      endingTime: "2023-12-05T19:14:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "656e028deffbc42cadf4b6ab",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-12-04T16:00:00.000Z",
      endingTime: "2023-12-04T23:28:10.628Z",
      activity: "reunion",
      desc: "",
      __v: 0,
    },
    {
      _id: "656a55fd41c2383745a284cc",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-12-01T21:00:00.000Z",
      endingTime: "2023-12-02T00:00:00.000Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "656a37367e78385da551bd61",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-12-01T15:46:00.000Z",
      endingTime: "2023-12-01T18:46:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "656a1a333917c36065ce9c35",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-30T16:30:00.000Z",
      endingTime: "2023-11-30T23:42:00.000Z",
      activity: "payroll",
      desc: "",
      __v: 0,
    },
    {
      _id: "6568d2f69edc919b9d123c55",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-30T16:00:00.000Z",
      endingTime: "2023-11-30T22:00:00.000Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "6567796f7637aed373db2696",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-29T16:00:00.000Z",
      endingTime: "2023-11-29T22:45:44.284Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "656a1a0f3917c36065ce9c23",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-29T13:42:00.000Z",
      endingTime: "2023-11-29T19:42:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "6566369e3594f8e1ae4a409f",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-28T16:00:00.000Z",
      endingTime: "2023-11-28T22:04:00.000Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "65648918ff775cc597dbf038",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-27T12:18:23.527Z",
      endingTime: "2023-11-27T20:44:41.202Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "65660ef944b5b54f7886a938",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-27T16:05:00.000Z",
      endingTime: "2023-11-27T20:05:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "65632786c1817be1c9797873",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-26T11:00:00.000Z",
      endingTime: "2023-11-26T16:00:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "6564bdb735b38e6068ead7a3",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-25T14:23:00.000Z",
      endingTime: "2023-11-25T21:15:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "6564bdb035b38e6068ead79e",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-25T15:06:00.000Z",
      endingTime: "2023-11-25T20:08:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "656107f73f721c3277e3da2c",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-24T20:30:00.000Z",
      endingTime: "2023-11-24T22:30:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "6552451fbb4a4ea87ec971d6",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-24T14:00:00.000Z",
      endingTime: "2023-11-24T21:50:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "6564bd8035b38e6068ead775",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-24T13:05:00.000Z",
      endingTime: "2023-11-24T20:05:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "655e76028386d2386321ad4f",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-22T21:43:00.000Z",
      endingTime: "2023-11-22T23:43:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "655d2836a49283849c2acf89",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-21T21:59:16.852Z",
      endingTime: "2023-11-21T23:16:10.440Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "6557ee32a539e4b651edabcb",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-17T22:50:25.603Z",
      endingTime: "2023-11-17T23:59:15.450Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "65550c1430adcfcc76a70c2c",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-15T17:24:00.000Z",
      endingTime: "2023-11-15T20:24:00.000Z",
      activity: "payroll",
      desc: "",
      __v: 0,
    },
    {
      _id: "65551bbc30adcfcc76a70c3e",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-15T16:00:00.000Z",
      endingTime: "2023-11-15T19:28:08.703Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "65539c3539b3c64909cd7197",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-14T16:11:00.000Z",
      endingTime: "2023-11-15T06:11:00.000Z",
      activity: "reunion",
      desc: "",
      __v: 0,
    },
    {
      _id: "65525cdf98002cd3f49260e9",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-13T17:28:57.966Z",
      endingTime: "2023-11-13T21:36:25.390Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "6552440fbb4a4ea87ec971a5",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-13T14:46:00.000Z",
      endingTime: "2023-11-13T19:04:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "65511bed667605629922b468",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-12T18:39:00.000Z",
      endingTime: "2023-11-13T08:39:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "655244cebb4a4ea87ec971b1",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-11T15:46:00.000Z",
      endingTime: "2023-11-11T23:30:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "654eafd2579f73a85c71d03a",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-10T14:00:00.000Z",
      endingTime: "2023-11-10T22:34:12.178Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "655243e8bb4a4ea87ec9718e",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-10T14:46:00.000Z",
      endingTime: "2023-11-10T19:46:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "654eafa1579f73a85c71d023",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-09T16:00:00.000Z",
      endingTime: "2023-11-09T22:33:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "654eaf5c579f73a85c71d007",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-08T14:10:00.000Z",
      endingTime: "2023-11-08T22:12:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "654bd095cfc765226623aad5",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-08T14:20:00.000Z",
      endingTime: "2023-11-08T18:20:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "654a677d7e5a0fb119f309ce",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-07T16:36:00.000Z",
      endingTime: "2023-11-08T06:36:00.000Z",
      activity: "Escritos",
      desc: "",
      __v: 0,
    },
    {
      _id: "654abfd719fbb87396d78e55",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-07T16:05:00.000Z",
      endingTime: "2023-11-08T00:15:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "654bd066cfc765226623aabe",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-07T14:19:00.000Z",
      endingTime: "2023-11-07T20:19:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "654963aaab3b1a70841eaa9f",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-06T22:07:00.000Z",
      endingTime: "2023-11-06T23:35:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "654abfa619fbb87396d78e3e",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-06T14:00:00.000Z",
      endingTime: "2023-11-06T22:15:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "654bd040cfc765226623aaa7",
      collaborator: {
        _id: "61e70696968eb1eef1f52a99",
        col_code: "ADG",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643046797/torzlk62sx6oeowmrdm6.jpg",
      },
      startingTime: "2023-11-05T13:19:00.000Z",
      endingTime: "2023-11-05T18:19:00.000Z",
      activity: "accounting",
      desc: "",
      __v: 0,
    },
    {
      _id: "654abf6719fbb87396d78e27",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-04T14:00:00.000Z",
      endingTime: "2023-11-04T21:20:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "65481dde5b9bee96250984bc",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-04T19:00:00.000Z",
      endingTime: "2023-11-04T20:30:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
    {
      _id: "65443048711acb6455623f76",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-02T15:10:00.000Z",
      endingTime: "2023-11-02T23:27:26.920Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "6544300e711acb6455623f5f",
      collaborator: {
        _id: "61e706be968eb1eef1f52a9c",
        col_code: "AAA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1643052594/qnxoptti6qmf55bgqpap.jpg",
      },
      startingTime: "2023-11-01T14:08:00.000Z",
      endingTime: "2023-11-01T22:03:00.000Z",
      activity: "visit",
      desc: "",
      __v: 0,
    },
    {
      _id: "65429459533c1e2ec74438dd",
      collaborator: {
        _id: "6356d0dad4d66673d96e8448",
        col_code: "AGA",
        imgUrl:
          "https://res.cloudinary.com/dwalcv9li/image/upload/v1666643318/bik2y2kmqvmt4fjr8emm.jpg",
      },
      startingTime: "2023-11-01T16:56:00.000Z",
      endingTime: "2023-11-01T20:00:00.000Z",
      activity: "Promoción, respoder",
      desc: "",
      __v: 0,
    },
  ],
};

export const getActivityRegistersStatsPerCollaborator = (
  collaboratorCode,
  activityRegisters
) => {
  // Filter activities based on the collaborator code
  const filteredActivities = activityRegisters.filter(
    (activity) => activity.collaborator.col_code === collaboratorCode
  );

  const today = dayjs();
  const startOfThisWeek = today.startOf("week");
  const startOfThisMonth = today.startOf("month");
  const startOfThisYear = today.startOf("year");
  const startOfPrevMonth = today.subtract(1, "month").startOf("month");

  const todayHours = calculateHoursForTimeframe(
    today,
    today,
    filteredActivities
  );
  const thisWeekHours = calculateHoursForTimeframe(
    startOfThisWeek,
    today,
    filteredActivities
  );
  const thisMonthHours = calculateHoursForTimeframe(
    startOfThisMonth,
    today,
    filteredActivities
  );
  const thisYearHours = calculateHoursForTimeframe(
    startOfThisYear,
    today,
    filteredActivities
  );
  const allHours = calculateTotalHoursNew(filteredActivities);
  const prevMonthHours = calculateHoursForTimeframe(
    startOfPrevMonth,
    startOfThisMonth,
    filteredActivities
  );

  // Calculate hours for each of the past 12 months
  const monthStats = Array.from({ length: 12 }, (_, index) => {
    const startOfMonth = today.subtract(index, "month").startOf("month");
    const endOfMonth = startOfMonth.endOf("month");
    return calculateHoursForTimeframe(
      startOfMonth,
      endOfMonth,
      filteredActivities
    );
  });

  return {
    todayHours,
    thisWeekHours,
    thisMonthHours,
    thisYearHours,
    allHours,
    previousMonth1Hours: monthStats[1],
    previousMonth2Hours: monthStats[2],
    previousMonth3Hours: monthStats[3],
    previousMonth4Hours: monthStats[4],
    previousMonth5Hours: monthStats[5],
    previousMonth6Hours: monthStats[6],
    previousMonth7Hours: monthStats[7],
    previousMonth8Hours: monthStats[8],
    previousMonth9Hours: monthStats[9],
    previousMonth10Hours: monthStats[10],
    previousMonth11Hours: monthStats[11],
    previousMonth12Hours: monthStats[12],
  };
};

// Calculate the hours for each statistic
const calculateHours = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return end.diff(start, "hours");
};

// Helper function to calculate hours for a specific timeframe
const calculateHoursForTimeframe = (start, end, activities) => {
  return activities.reduce((total, activity) => {
    const activityStart = dayjs(activity.startingTime);
    const activityEnd = dayjs(activity.endingTime || activity.startingTime); // Use starting time if no end time is available

    // Check if the activity overlaps with the specified timeframe
    if (activityStart.isAfter(start) && activityStart.isBefore(end)) {
      const duration = calculateHours(activity.startingTime, activityEnd);
      return total + duration;
    }

    return total;
  }, 0);
};

// Helper function to calculate total hours
const calculateTotalHoursNew = (activities) => {
  return activities.reduce((total, activity) => {
    if (activity.endingTime === null) return total;
    return total + calculateHours(activity.startingTime, activity.endingTime);
  }, 0);
};

export const getMonthName = (offset = 0) => {
  // Validate the offset
  if (typeof offset !== "number") {
    throw new Error("Invalid offset parameter. It should be a number.");
  }

  const currentMonth = dayjs().add(offset, "month");
  const monthNumber = currentMonth.month() + 1; // dayjs months are 0-indexed
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Validate the monthNumber
  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error("Invalid month number. It should be between 1 and 12.");
  }

  return months[monthNumber - 1];
};
