import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
};
export const ChangeUserDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  email,
  setEmail,
  password,
  setPassword,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Change User</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="email"
        label="Email Address"
        type="email"
        fullWidth
        variant="standard"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="dense"
        id="password"
        label="Password"
        type="password"
        fullWidth
        variant="standard"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit}>Submit</Button>
    </DialogActions>
  </Dialog>
);
