import { Avatar, Tooltip } from "@mui/material";
import React from "react";

type CustomAvatarProps = {
  id: string;
  firstName: string;
  imgUrl: string;
  width?: number;
};
export const CustomAvatar = ({
  id,
  firstName,
  imgUrl,
  width = 32,
}: CustomAvatarProps) => {
  return (
    <Tooltip title={firstName} key={id}>
      <Avatar
        alt={firstName}
        src={imgUrl}
        sx={{ width: width, height: width }}
      />
    </Tooltip>
  );
};
