import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  Link as MuiLink,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { sendPasswordResetEmail } from "../../../api/apiCalls/sendPasswordResetEmail";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage("");
    setError("");
    try {
      await sendPasswordResetEmail(email);
      setMessage("Check your email for the password reset link.");
    } catch (error) {
      setError("Failed to send password reset email.");
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#5583a7",
      }}
    >
      <Paper
        elevation={6}
        style={{ padding: "20px", width: "100%", maxWidth: "400px" }}
      >
        <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: "24px 0px 16px" }}
          >
            Send Reset Link
          </Button>
          {message && (
            <Typography color="primary" style={{ textAlign: "center" }}>
              {message}
            </Typography>
          )}
          {error && (
            <Typography color="error" style={{ textAlign: "center" }}>
              {error}
            </Typography>
          )}
        </form>
        <Typography style={{ marginTop: "20px", textAlign: "center" }}>
          <MuiLink
            component={RouterLink}
            to="/"
            style={{ textDecoration: "none" }}
          >
            Return to Main Page
          </MuiLink>
        </Typography>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
