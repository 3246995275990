import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TasksMenu } from "./components/TaksMenu";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { startInitialLoading } from "../../../store/thunks/taskThunks";
import { useAppSelector } from "../../../hooks/useAppSelector";

export const TasksLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(startInitialLoading());
  }, [dispatch]);

  const { isInitialLoading } = useAppSelector((state) => state.taskReducer);

  if (isInitialLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TasksMenu />
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
