import { Dispatch, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../store";
import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";
import { ApiResponse } from "../../shared/types/responses";
import { AnyAction } from "redux";
import Swal from "sweetalert2";
import {
  addWorkLog,
  deleteWorkLog,
  setInitialLoading,
  setLoading,
  setWorkLogs,
  updateWorkLog,
} from "../slice/workLogsSlice";
import { WorkLog } from "../../shared/types/WorkLog";

const route = "/work-logs";

// TODO ALL
export const startLoadingWorkLogs = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<ApiResponse<WorkLog[]>>(
        route
      );
      const fetchedData = data.data;
      dispatch(setWorkLogs(fetchedData));
    } catch (error: any) {
      console.error("Axios Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const startInitialWorkLogsLoading = () => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch(setInitialLoading(true));
    try {
      await dispatch(startLoadingWorkLogs());
    } catch (error: any) {
      console.error("Axios Error:", error);
    } finally {
      dispatch(setInitialLoading(false));
    }
  };
};

export const startSavingWorkLog = (workLog: WorkLog) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { data } = await hvpAuthenticatedApi.post<ApiResponse<WorkLog>>(
        route,
        workLog
      );
      if (data.ok) {
        Swal.fire({
          icon: "success",
          title: "Registro exitoso",
          showConfirmButton: false,
          timer: 1000,
        });
      }

      const fetchedResource = data.data;
      dispatch(addWorkLog(fetchedResource));

      return fetchedResource;
    } catch (error: any) {
      console.error("Axios Error:", error);
    }
  };
};

export const startUpdatingWorkLog = (workLog: WorkLog, id: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { data } = await hvpAuthenticatedApi.put<ApiResponse<WorkLog>>(
        `${route}/${id}`,
        workLog
      );

      if (data.ok) {
        Swal.fire({
          icon: "success",
          title: "Registro exitoso",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        throw new Error("Error updating work log");
      }

      const fetchedResource = data.data;
      dispatch(updateWorkLog(fetchedResource));

      return fetchedResource;
    } catch (error: any) {
      console.error("Axios Error:", error);
    }
  };
};

export const startDeletingWorkLog = (id: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { data } = await hvpAuthenticatedApi.delete<ApiResponse<WorkLog>>(
        `${route}/${id}`
      );

      if (!data.ok) {
        throw new Error("Error deleting");
      }

      const deletedResource = data.data;
      dispatch(deleteWorkLog(id!));

      return deletedResource;
    } catch (error: any) {
      console.error("Axios Error:", error);
    }
  };
};
