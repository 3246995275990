import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  createActivityRegisterType,
  deleteActivityRegisterType,
  updateActivityRegisterType,
} from "../../../../store/thunks";

export const ActivityRegisterTypeManager = () => {
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState("");
  const [editableTypeId, setEditableTypeId] = useState<string | null>(null);
  const [updatedLabel, setUpdatedLabel] = useState("");

  const { activityRegisterTypes } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const handleDelete = (id: string) => {
    dispatch(deleteActivityRegisterType(id));
  };
  const handleAddActivity = () => {
    if (label.trim() !== "") {
      const value = label.toLowerCase().replace(/\s+/g, "-");
      dispatch(createActivityRegisterType({ label, value }));
      setLabel("");
    }
  };

  const handleUpdate = (id: string) => {
    if (updatedLabel.trim() !== "") {
      const value = updatedLabel.toLowerCase().replace(/\s+/g, "-");
      dispatch(updateActivityRegisterType({ id, label: updatedLabel, value }));
      setEditableTypeId(null);
      setUpdatedLabel("");
    }
  };

  const toggleEdit = (id: string) => {
    console.log("toggleEdit", id);
    setEditableTypeId(editableTypeId === id ? null : id);
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Manage Activity Register Types
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="activity register types table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityRegisterTypes.map((type) => (
              <TableRow key={type.id}>
                <TableCell component="th" scope="row">
                  {editableTypeId === type.id ? (
                    <TextField
                      fullWidth
                      value={updatedLabel}
                      onChange={(e) => setUpdatedLabel(e.target.value)}
                      variant="standard"
                    />
                  ) : (
                    <Typography>{type.label}</Typography>
                  )}
                </TableCell>

                <TableCell align="right">
                  {editableTypeId === type.id ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(type.id!)}
                    >
                      Update
                    </Button>
                  ) : (
                    <IconButton onClick={() => toggleEdit(type.id!)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(type.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: 2, display: "flex", alignItems: "center" }}>
        <TextField
          label="New Activity"
          variant="outlined"
          fullWidth
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddActivity}>
          Add Activity
        </Button>
      </Box>
    </Box>
  );
};
