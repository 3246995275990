import React, { useState, useEffect, useMemo } from "react";

import { TimeOffsCalendarMonth } from "./TimeOffsCalendarMonth";
import { Box, Button } from "@mui/material";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { convertTimeOffOverviewToDateTimeOffRequests } from "../../../../helpers/timeOffRequestsHelpers";

type VacationCalendarYearProps = {
  year?: number;
};

export const TimeOffsCalendarYear = ({
  year: initialYear,
}: VacationCalendarYearProps) => {
  const [isLoading, setisLoading] = useState(true);

  const { isLoading: isLoadingTimeOffRequests, collaboratorsTimeOffsOverview } =
    useAppSelector((state) => state.timeOffRequestsReducer);

  // @ts-ignore
  const { collaborators, isLoading: isLoadingCollaborators } = useAppSelector(
    (state) => state.collaborator
  );

  const currentDate = useMemo(() => new Date(), []);
  const currentYear = initialYear || currentDate.getFullYear();

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (change: number) => {
    setSelectedYear((prevYear) => prevYear + change);
  };

  useEffect(() => {
    const todayMonth = currentDate.getMonth();
    const todayYear = currentDate.getFullYear();

    if (selectedYear === todayYear) {
      const currentMonthElement = document.getElementById(
        `${todayYear}-${todayMonth}`
      );
      currentMonthElement &&
        currentMonthElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentDate, selectedYear]);

  const timeOffDates = useMemo(() => {
    if (collaborators.length === 0) return [];

    return convertTimeOffOverviewToDateTimeOffRequests(
      collaborators,
      collaboratorsTimeOffsOverview
    );
  }, [collaborators, collaboratorsTimeOffsOverview]);

  console.log({ timeOffDates });

  useEffect(() => {
    if (isLoadingTimeOffRequests || isLoadingCollaborators) return;
    setisLoading(false);
  }, [isLoadingTimeOffRequests, isLoadingCollaborators]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Box display="flex" justifyContent="center" margin={4}>
        <Button onClick={() => handleYearChange(-1)}>Previous</Button>
        <Button onClick={() => handleYearChange(1)}>Next</Button>
      </Box>
      <Box>
        {Array.from({ length: 12 }, (_, index) => index).map((month) => (
          <TimeOffsCalendarMonth
            month={month}
            year={selectedYear}
            key={`${selectedYear}-${month}`}
            timeOffDates={timeOffDates}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="center" margin={4}>
        <Button onClick={() => handleYearChange(-1)}>Previous</Button>
        <Button onClick={() => handleYearChange(1)}>Next</Button>
      </Box>
    </>
  );
};
