import React from "react";
import { Route, Routes } from "react-router-dom";
import { SuppliersForm, SuppliersList } from "../../pages/dashboard/suppliers";

export const SupplierRouter = () => {
  return (
    <Routes>
      <Route index element={<SuppliersList />} />
      <Route path=":id" element={<SuppliersForm />} />
      <Route path="*" element={<SuppliersList />} />
    </Routes>
  );
};
