import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props extends ButtonProps {
  to: string;
  children: React.ReactNode;
}

const CustomNavigateButton: React.FC<Props> = ({ to, children, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("to", to);
    navigate(to);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      style={{ width: "100%", height: "100%" }}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  );
};
export default CustomNavigateButton;
