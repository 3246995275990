import { useMemo, useState } from "react";
import { Task, TaskTag } from "../shared/types/Task";

export const useFilterTasksHook = (tasks: Task[]) => {
  const [filters, setFilters] = useState({
    assignee: "",
    author: "",
    priority: "",
    tags: "",
    status: "",
    title: "",
  });

  const filteredTasks = useMemo(() => {
    return tasks
      .filter((task) =>
        filters.assignee ? task.assignees!.includes(filters.assignee) : true
      )
      .filter((task) =>
        filters.author ? task.author === filters.author : true
      )
      .filter((task) =>
        filters.priority ? task.priority === filters.priority : true
      )
      .filter((task) =>
        filters.tags ? task.tags!.includes(filters.tags as TaskTag) : true
      )
      .filter((task) =>
        filters.status ? task.status === filters.status : true
      )
      .filter((task) =>
        filters.title ? task._id!.includes(filters.title) : true
      );
  }, [tasks, filters]);

  const handleFilterChange = (filterType: string, value: string) => {
    setFilters({ ...filters, [filterType]: value });
  };

  return { filteredTasks, filters, handleFilterChange };
};
