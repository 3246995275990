import React, { useCallback, useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { WorkLog } from "../../../../shared/types/WorkLog";
import * as Yup from "yup";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Formik, Form, FieldArray } from "formik";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  startSavingWorkLog,
  startUpdatingWorkLog,
} from "../../../../store/thunks/workLogsThunks";
import { removeWorkLogEmptyActivities } from "../../../../helpers/workLogsHelpers";
import Swal from "sweetalert2";

const WorkLogSchema: Yup.SchemaOf<WorkLog> = Yup.object().shape({
  _id: Yup.string(),
  logDate: Yup.string().required("Required"),
  createdAt: Yup.string(),
  createdBy: Yup.string(),
  updatedBy: Yup.string(),
  updatedAt: Yup.string(),
  activities: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string(),
        createdAt: Yup.string(),
        createdBy: Yup.string(),
        updatedAt: Yup.string(),
        updatedBy: Yup.string(),
        content: Yup.string(),
        relatedTask: Yup.string(),
        relatedTaskActivity: Yup.string(),
      })
    )
    .required("Required"),
});

type Props = {
  originalValues: WorkLog;
  onSetDate: (date: Date) => void;
};
export const CreateWorkLog = ({ originalValues, onSetDate }: Props) => {
  const [initialValues, setInitialValues] = useState<WorkLog>(originalValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const { tasks } = useAppSelector((state) => state.taskReducer);
  const { logDate } = initialValues;
  const filteredTasks = tasks.filter((task) => {
    const oneDayBeforeLogDate = new Date(logDate);
    oneDayBeforeLogDate.setDate(oneDayBeforeLogDate.getDate() - 1);

    return (
      !task.completedAt || new Date(task.completedAt) > oneDayBeforeLogDate
    );
  });

  const handleSubmit = useCallback(
    async (values: WorkLog) => {
      setIsSubmitting(true);
      try {
        const workLog = removeWorkLogEmptyActivities(values);
        if (workLog.activities.length === 0) {
          return Swal.fire(
            "Error",
            "You must add at least one activity",
            "error"
          );
        }

        if (workLog._id) {
          const updatedWorkLog = await dispatch(
            startUpdatingWorkLog(workLog, workLog._id)
          );
          setInitialValues(updatedWorkLog!);
        } else {
          const savedWorkLog = await dispatch(startSavingWorkLog(workLog));
          setInitialValues(savedWorkLog!);
        }
      } catch (error) {
        console.error(error);
        Swal.fire(
          "Error",
          "An error occurred while processing the form",
          "error"
        );
      } finally {
        setIsSubmitting(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const values: WorkLog = originalValues;
    setInitialValues(values);
  }, [originalValues]);

  return (
    <Box>
      <Typography variant="h4">Create a WorkLog</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={WorkLogSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, setFieldValue, values, handleChange }) => {
          return (
            <Form>
              <Paper elevation={3} sx={{ margin: 2, padding: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="log-date-label">Log Date*</FormLabel>
                      <Field
                        as={DayPicker}
                        labelId="log-date-label"
                        name="logDate"
                        selected={new Date(values.logDate!)}
                        onSelect={(date: Date) => {
                          setFieldValue("logDate", date.toISOString());
                          onSetDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                        mode="single"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name="activities">
                      {({ push, remove }) => (
                        <Box>
                          {values.activities.map((activity, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              <Autocomplete
                                value={
                                  filteredTasks.find(
                                    (task) =>
                                      task._id ===
                                      values.activities[index].relatedTask
                                  ) || null
                                }
                                options={filteredTasks}
                                getOptionLabel={(option) =>
                                  `${option.number} ${option.title}`
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name={`activities.${index}.relatedTask`}
                                    placeholder="Related Task"
                                  />
                                )}
                                onChange={(_, newValue) => {
                                  setFieldValue(
                                    `activities.${index}.relatedTask`,
                                    newValue ? newValue._id : ""
                                  );
                                }}
                              />
                              <Field
                                fullWidth
                                as={TextField}
                                name={`activities.${index}.content`}
                                placeholder="Content"
                                multiline
                                rows={3}
                              />

                              <Button
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </Box>
                          ))}
                          <Button
                            type="button"
                            onClick={() =>
                              push({ content: "", relatedTask: "" })
                            }
                          >
                            Add Activity
                          </Button>
                        </Box>
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
