import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { productThunks } from "../../../../store/thunks";
import { sortAlphabeticallyByProperty } from "../../../../helpers";

export const ProductsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { products, isLoading } = useAppSelector((state) => state.products);

  const sortedProducts = sortAlphabeticallyByProperty("name", products);

  useEffect(() => {
    dispatch(productThunks.fetchItems());
  }, [dispatch]);

  const handleDelete = (id: string) => {
    dispatch(productThunks.deleteItem(id));
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: 1000, margin: "auto", mt: 4 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedProducts.map((product) => (
            <TableRow
              key={product.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {product.id}
              </TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell align="center">
                <IconButton color="primary" aria-label="edit product">
                  <EditIcon onClick={() => navigate(product.id!)} />
                </IconButton>
                <IconButton color="error" aria-label="delete product">
                  <DeleteIcon onClick={() => handleDelete(product.id!)} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
