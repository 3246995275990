import { productThunks } from "./../thunks/product.thunks";
import { createSlice } from "@reduxjs/toolkit";

import { IProduct } from "../../shared/types";

interface State {
  products: IProduct[];
  isLoading: boolean;
}

const initialState: State = {
  products: [],
  isLoading: true,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productThunks.fetchItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(productThunks.fetchItems.fulfilled, (state, action) => {
        state.products = action.payload;
        state.isLoading = false;
      })
      .addCase(productThunks.fetchItems.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(productThunks.createItem.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(productThunks.updateItem.fulfilled, (state, action) => {
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );
        state.products[index] = action.payload;
      })
      .addCase(productThunks.deleteItem.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
      });
  },
});
