import React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { Task, TaskStatus } from "../../../../../shared/types/Task";
import { ViewType } from "../TaskStats";
import {
  MONTH_NAMES,
  daysInMonth,
  isDateBetween,
} from "../../../../../helpers/dateHelpers";

type Props = {
  tasks: Task[];
  viewType: ViewType;
  startDate: Date;
  endDate: Date;
};

export const CompletionTrendsChart: React.FC<Props> = ({
  tasks,
  viewType,
  startDate,
  endDate,
}) => {
  const currentYear = new Date().getFullYear();

  const getPeriods = () => {
    switch (viewType) {
      case ViewType.General:
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 3;

        const yearsArray = Array.from({ length: 7 }, (_, i) => startYear + i);
        return yearsArray;
      case ViewType.Year:
        return MONTH_NAMES.map((month) => month.substring(0, 3));
      case ViewType.Month:
        return ["Q1", "Q2", "Q3", "Q4"];
      case ViewType.Custom:
        return Array.from({ length: 10 }, (_, i) => i + 1);
      default:
        return [];
    }
  };

  const periods = getPeriods();
  const completedTasks = new Array(periods.length).fill(0);
  const newTasks = new Array(periods.length).fill(0);
  const cancelledTasks = new Array(periods.length).fill(0);

  const getPeriodIndex = (dateString: string, viewType: ViewType) => {
    const date = new Date(dateString);
    switch (viewType) {
      case ViewType.General:
        const year = date.getFullYear();

        const index = year - currentYear + 3;

        return index;

      case ViewType.Year:
        return date.getMonth();
      case ViewType.Month: {
        const totalMonthDays = daysInMonth(startDate);

        const dayOfMonth = date.getDate();

        if (dayOfMonth <= totalMonthDays / 4) {
          return 0; // First quarter
        } else if (dayOfMonth <= (totalMonthDays / 4) * 2) {
          return 1; // Second quarter
        } else if (dayOfMonth <= (totalMonthDays / 4) * 3) {
          return 2; // Third quarter
        } else {
          return 3; // Fourth quarter
        }
      }

      case ViewType.Custom:
        const totalPeriodDays = endDate.getTime() - startDate.getTime();
        const dayOfPeriod = date.getTime() - startDate.getTime();
        return Math.floor((dayOfPeriod / totalPeriodDays) * periods.length);
      default:
        return 0;
    }
  };

  tasks.forEach((task) => {
    if (
      task.requestedAt &&
      isDateBetween(startDate, endDate, task.requestedAt)
    ) {
      const periodIndex = getPeriodIndex(task.requestedAt, viewType);

      newTasks[periodIndex]++;
    }
    if (
      task.completedAt &&
      isDateBetween(startDate, endDate, task.completedAt) &&
      task.status === TaskStatus.Completed
    ) {
      const periodIndex = getPeriodIndex(task.completedAt, viewType);

      completedTasks[periodIndex]++;
    }

    if (
      task.completedAt &&
      isDateBetween(startDate, endDate, task.completedAt) &&
      task.status === TaskStatus.Canceled
    ) {
      const periodIndex = getPeriodIndex(task.completedAt, viewType);

      cancelledTasks[periodIndex]++;
    }
  });

  const data = periods.map((period, index) => ({
    period,
    label: `${period}`,
    completedTasks: completedTasks[index],
    newTasks: newTasks[index],
    cancelledTasks: cancelledTasks[index],
  }));

  return (
    <>
      <BarChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="completedTasks" fill="#8884d8" />
        <Bar dataKey="newTasks" fill="#82ca9d" />
        <Bar dataKey="cancelledTasks" fill="#ffc658" />
      </BarChart>
    </>
  );
};
