import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { daysOfWeek } from "../../../../shared/constants/datetimeConstants";
import { TimeOffsCalendarDay } from "./TimeOffsCalendarDay";
import { DateTimeOffRequestCollaborator } from "../../../../shared/types/TimeOffRequest";

type VacationsCalendarMonthProps = {
  month: number;
  year: number;
  timeOffDates: DateTimeOffRequestCollaborator[];
};

export const TimeOffsCalendarMonth = ({
  month,
  year,
  timeOffDates,
}: VacationsCalendarMonthProps) => {
  const monthStartingDate = dayjs(`${year}-${month + 1}-01`);

  const monthStart = monthStartingDate.startOf("month");
  const monthEnd = monthStartingDate.endOf("month");

  // Calculate the starting day of the week for the first day of the month
  const startDayOfWeek = monthStart.day();
  const daysToSubtract = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;
  const adjustedMonthStart = monthStart.subtract(daysToSubtract, "day");
  const lastDayOfWeek = monthEnd.day();
  const daysToAdd = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;
  const adjustedMonthEnd = monthEnd.add(daysToAdd, "day");

  // Calculate the length of the daysOfMonth array using the difference in days
  const daysDiff = adjustedMonthEnd.diff(adjustedMonthStart, "day") + 1;

  // Generate the days of the month
  const daysOfMonth = Array.from({ length: daysDiff }, (_, index) => {
    const currentDay = adjustedMonthStart.add(index, "day");
    const dayVacations = timeOffDates.filter((timeOffDate) =>
      dayjs(timeOffDate.date).isSame(currentDay, "day")
    );

    return {
      date: currentDay,
      vacations: dayVacations,
    };
  });

  return (
    <Box margin={4} id={`${year}-${month}`}>
      <Typography variant="h4">
        {monthStartingDate.format("MMMM YYYY")}
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "4px",
        }}
      >
        {daysOfWeek.map((day) => (
          <div key={day} style={{ textAlign: "center", padding: "8px" }}>
            <Typography variant="body1">{day}</Typography>
          </div>
        ))}
        {daysOfMonth.map((day) => (
          <TimeOffsCalendarDay
            key={day.date.toISOString()}
            day={day.date}
            monthYear={{ month: month, year: year }}
            timeOffCalendarDayData={day.vacations}
          />
        ))}
      </div>
    </Box>
  );
};
