import React, { useEffect, useState } from "react";
import { Container, Box, Button, Typography, FormControl } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  AttendanceRecord,
  AttendanceRecordAction,
  Branch,
} from "../../../../shared/types";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  startSavingAttendanceRecord,
  startUpdatingAttendanceRecord,
} from "../../../../store/thunks/attendanceRecordsThunks";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { getCurrentMexicanDate, isValidBranch } from "../../../../helpers";
import CustomRadioGroup from "../../../../components/fields/CustomRadioGroup";

export const AttendanceLogger: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const branchQueryParam = query.get("branch");
  const [isRegisteredDate, setIsRegisteredDate] = useState(false);
  // TODO implement a better way to get the current date with an API
  const todayDate = getCurrentMexicanDate();
  const { getCurrentCollaboratorCode, getCurrentCollaboratorId } =
    useCollaboratorHook();
  const dispatch = useAppDispatch();
  const { lastCollaboratorAttendanceRecord } = useAppSelector(
    (state) => state.attendanceRecordsReducer
  );

  const [attendanceRecord, setAttendanceRecord] = useState<AttendanceRecord>({
    shiftDate: todayDate,
    clockInBranch: isValidBranch(branchQueryParam)
      ? branchQueryParam
      : Branch.Urban,
    clockOutBranch: isValidBranch(branchQueryParam)
      ? branchQueryParam
      : Branch.Urban,
    collaborator: getCurrentCollaboratorId(),
    action: AttendanceRecordAction.ClockIn,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      lastCollaboratorAttendanceRecord &&
      !lastCollaboratorAttendanceRecord.endTime &&
      todayDate === lastCollaboratorAttendanceRecord.shiftDate
    ) {
      setAttendanceRecord({
        ...lastCollaboratorAttendanceRecord,
        clockOutBranch:
          (branchQueryParam as Branch) ??
          lastCollaboratorAttendanceRecord.clockInBranch,
        action: AttendanceRecordAction.ClockOut,
      });
    } else {
      if (lastCollaboratorAttendanceRecord?.shiftDate === todayDate) {
        setIsRegisteredDate(true);
      }
      setAttendanceRecord((prev) => {
        const newAttendanceRecord = { ...prev };
        if (
          branchQueryParam &&
          newAttendanceRecord.action === AttendanceRecordAction.ClockIn
        ) {
          newAttendanceRecord.clockInBranch = branchQueryParam as Branch;
        }
        if (
          branchQueryParam &&
          newAttendanceRecord.action === AttendanceRecordAction.ClockOut
        ) {
          newAttendanceRecord.clockOutBranch = branchQueryParam as Branch;
        }
        return newAttendanceRecord;
      });
    }
  }, [
    lastCollaboratorAttendanceRecord,
    branchQueryParam,
    todayDate,
    attendanceRecord.shiftDate,
    attendanceRecord.action,
  ]);

  const handleBranchChange = (value: string) => {
    const branchValue = value as Branch;
    const updatedRecord = {
      ...attendanceRecord,
      [attendanceRecord.action === AttendanceRecordAction.ClockIn
        ? "clockInBranch"
        : "clockOutBranch"]: branchValue,
    };
    setAttendanceRecord(updatedRecord);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Wrap the geolocation call in a new Promise to make it awaitable
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          // The getCurrentPosition function will prompt the user for permission
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
          );
        }
      );

      // Extract latitude and longitude from the position object
      const { latitude, longitude } = position.coords;
      const updatedRecord = {
        ...attendanceRecord,
        [attendanceRecord.action === AttendanceRecordAction.ClockIn
          ? "startLatitude"
          : "endLatitude"]: latitude,
        [attendanceRecord.action === AttendanceRecordAction.ClockIn
          ? "startLongitude"
          : "endLongitude"]: longitude,
      };

      // Update the state with the new record including geolocation data
      setAttendanceRecord(updatedRecord);

      // Proceed with saving or updating the record after geolocation data is set
      if (!updatedRecord._id) {
        await dispatch(startSavingAttendanceRecord(updatedRecord));
      } else {
        await dispatch(startUpdatingAttendanceRecord(updatedRecord));
        navigate(`/dashboard/cleanups/${updatedRecord.clockOutBranch}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 5,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        {/* HEADER */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Time Logger
          </Typography>
          <Typography variant="h6" gutterBottom>
            Collaborator Code: {getCurrentCollaboratorCode()}
          </Typography>
        </Box>
        {/* Last Registry data */}
        <Box sx={{ mb: 2, textAlign: "center" }}>
          <Typography variant="h6">Last Registry:</Typography>
          <Typography variant="body1">
            {`Date: ${lastCollaboratorAttendanceRecord?.shiftDate}`}
          </Typography>
          <Typography variant="body1">
            {`Clocked in at: ${
              lastCollaboratorAttendanceRecord?.clockInBranch
            } ${
              lastCollaboratorAttendanceRecord?.startTime
                ? new Date(
                    lastCollaboratorAttendanceRecord?.startTime
                  ).toLocaleTimeString()
                : ""
            }`}
          </Typography>
          {lastCollaboratorAttendanceRecord?.endTime && (
            <Typography variant="body1">
              {`Clocked out at: ${
                lastCollaboratorAttendanceRecord?.clockOutBranch
              } ${new Date(
                lastCollaboratorAttendanceRecord?.endTime
              ).toLocaleTimeString()}`}
            </Typography>
          )}
        </Box>
        {/* Form */}
        {!isRegisteredDate ? (
          <Box>
            <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Branch:
              </Typography>
              {attendanceRecord.action !== AttendanceRecordAction.ClockOut ? (
                <CustomRadioGroup
                  options={Object.values(Branch).map((branch) => ({
                    value: branch,
                    label: branch,
                  }))}
                  value={attendanceRecord.clockInBranch}
                  onChange={(value) => handleBranchChange(value)}
                />
              ) : (
                <CustomRadioGroup
                  options={Object.values(Branch).map((branch) => ({
                    value: branch,
                    label: branch,
                  }))}
                  value={attendanceRecord.clockOutBranch}
                  onChange={(value) => handleBranchChange(value)}
                />
              )}
            </FormControl>

            <Box sx={{ my: 2, textAlign: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  color:
                    attendanceRecord.action === AttendanceRecordAction.ClockIn
                      ? "green"
                      : "red",
                }}
              >
                {attendanceRecord.action === AttendanceRecordAction.ClockIn
                  ? "Clock in"
                  : "Clock out"}
                {` from ${
                  attendanceRecord.clockOutBranch ??
                  attendanceRecord.clockInBranch
                } `}
                at {new Date().toLocaleTimeString()}
              </Typography>
            </Box>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Submit
              </Button>
            </FormControl>
          </Box>
        ) : (
          <Box>
            <Typography variant="h5" sx={{ color: "green", fontWeight: 600 }}>
              You have already registered today
            </Typography>
          </Box>
        )}
      </Box>
      {/*       
      <Box sx={{ mt: 5 }}>
        <pre>{JSON.stringify({ attendanceRecord }, null, 2)}</pre>
        <pre>{JSON.stringify({ isRegisteredDate }, null, 2)}</pre>
        <pre>{JSON.stringify({ todayDate }, null, 2)}</pre>
        <pre>
          {JSON.stringify({ lastCollaboratorAttendanceRecord }, null, 2)}
        </pre>
      </Box> */}
    </Container>
  );
};
