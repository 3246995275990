import React from "react";
import { Field } from "formik";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../shared";

interface FormDateTimePickerProps {
  name: string;
  label: string;
}

export const FormikDateTimePicker: React.FC<FormDateTimePickerProps> = ({
  name,
  label,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={label}
            value={field.value ? dayjs(field.value) : null}
            format={DATETIME_FORMAT}
            onChange={(value) => form.setFieldValue(name, value?.toDate())}
          />
        </LocalizationProvider>
      )}
    </Field>
  );
};
