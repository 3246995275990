import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { IMissingProduct } from "../../../../shared/types";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCollaboratorHook } from "../../../../hooks";

type Props = {
  missingProducts: IMissingProduct[];
  onEdit: (missingProduct: IMissingProduct) => void;
  onDelete: (id: string) => void;
};

export const MissingProductsTable = ({
  missingProducts,
  onEdit,
  onDelete,
}: Props) => {
  const { getCollaboratorCodeById } = useCollaboratorHook();

  const calculateDaysPassed = (index: number) => {
    const currentDate = dayjs(missingProducts[index].date);
    if (index === 0) {
      // For the most recent item, calculate days passed from today
      return dayjs().diff(currentDate, "day");
    } else {
      // For other items, calculate days passed between this item and the next one
      const nextDate = dayjs(missingProducts[index - 1].date);
      return nextDate.diff(currentDate, "day");
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Reported By</TableCell>
            <TableCell>Date Reported</TableCell>
            <TableCell>Days Passed</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {missingProducts.map((missingProduct, index) => (
            <TableRow key={missingProduct.id}>
              <TableCell>{missingProduct.missingProduct}</TableCell>
              <TableCell>{missingProduct.description}</TableCell>
              <TableCell>
                {getCollaboratorCodeById(missingProduct.collaborator)}
              </TableCell>
              <TableCell>
                {dayjs(missingProduct.date).format("MMMM D, YYYY")}
              </TableCell>
              <TableCell>{calculateDaysPassed(index)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="edit"
                  onClick={() => onEdit(missingProduct)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(missingProduct.id!)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
