import React, { FC, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { createTheme, Theme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@emotion/react";
import { LoginTab } from "./LoginTab";
import { RegisterColTab } from "./RegisterColTab";
import { RegisterCliTab } from "./RegiterCliTab";

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  dir?: "ltr" | "rtl";
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

export const AuthTabs: FC = () => {
  const theme: Theme = createTheme({
    typography: {
      htmlFontSize: 10,
    },
  });

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: "#f1f3f5" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Login" {...a11yProps(0)} />
            <Tab label="Registro clientes" {...a11yProps(1)} />
            <Tab label="Registro colaboradores" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <LoginTab />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <RegisterCliTab />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <RegisterColTab />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </ThemeProvider>
  );
};
