import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import { controlledPrescriptionThunks } from "../../../../store/thunks";
import {
  getFormattedMxDate,
  getOptionLabel,
  sortArrayByDateProperty,
} from "../../../../helpers";

export const ControlledPrescriptionsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    controlledPrescriptions,
    isLoading,
    controlledPrescriptionsStatusOptions,
  } = useAppSelector((state) => state.controlledPrescriptions);

  const handleDelete = (id: string) => {
    dispatch(controlledPrescriptionThunks.deleteItem(id));
  };

  const getRowColor = (status: string) => {
    switch (status) {
      case "pending":
        return "#fff3e0"; // Light orange
      case "generated":
        return "#e8f5e9"; // Light green
      case "delivered":
        return "#e3f2fd"; // Light blue
      case "cancelled":
        return "#ffccbc"; // Light red
      default:
        return "#ffffff"; // Default to white
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  const sortedControlledPrescriptions = sortArrayByDateProperty(
    "date",
    controlledPrescriptions,
    "desc"
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: 1200, margin: "auto", mt: 4 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Use</TableCell>
            <TableCell>Supplier Legal Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Products</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedControlledPrescriptions.map((element) => (
            <TableRow
              key={element.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: getRowColor(element.status),
              }}
            >
              <TableCell>{element.number}</TableCell>
              <TableCell>{getFormattedMxDate(element.date)}</TableCell>
              <TableCell>{element.use}</TableCell>
              <TableCell>{element.supplier.legalName}</TableCell>
              <TableCell>
                {getOptionLabel(
                  controlledPrescriptionsStatusOptions,
                  element.status
                )}
              </TableCell>
              <TableCell>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Batch Code</TableCell>
                      <TableCell>Expiration date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {element.products.map((product) => (
                      <TableRow key={`${product.id}-${product.batchCode}`}>
                        <TableCell>{product.quantity}</TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.batchCode}</TableCell>
                        <TableCell>
                          {product.expirationDate
                            ? new Date(
                                product.expirationDate
                              ).toLocaleDateString()
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  color="primary"
                  aria-label="edit"
                  onClick={() => navigate(element.id!)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={() => handleDelete(element.id!)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
