import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../store";
import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";

import {
  ApiResponse,
  BillCreationInfo,
  CustomerRFC,
  FiscalRegime,
  InvoiceUsage,
} from "../../shared/types";
import {
  setBillCreationInfo,
  setBillCreationInfos,
  setCustomerRFCs,
  setFiscalRegimes,
  setInitialLoading,
  setInvoiceUsages,
  setIsLoading,
  setPaymentMethods,
} from "../slice/billingSlice";
import { fireSwalError, fireSwalSuccess } from "../../helpers";

const customerRFCsRoute = "/billing/customer-rfcs";
const invoiceUsagesRoute = "/billing/invoice-usages";
const fiscalRegimesRoute = "/billing/fiscal-regimes";
const paymentMethodsRoute = "/billing/payment-methods";
const billCreationInfosRoute = "/billing/bill-creation-info";

export const fetchInitialBillingData = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setInitialLoading(true));
    try {
      await dispatch<any>(fetchCustomerRFCs());
      await dispatch<any>(fetchInvoiceUsages());
      await dispatch<any>(fetchFiscalRegimes());
      await dispatch<any>(fetchPaymentMethods());
      await dispatch<any>(fetchBillCreationInfos());
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setInitialLoading(false));
    }
  };
};

export const createCustomerRFC = (customerRFC: CustomerRFC) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.post(
        `${customerRFCsRoute}`,
        customerRFC
      );
      await dispatch<any>(fetchCustomerRFCs());
      if (data.ok) {
        await fireSwalSuccess("El RFC ha sido creado");
      }
      return data;
    } catch (error: any) {
      handleError(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const updateCustomerRFC = (customerRFC: CustomerRFC) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.patch(
        `${customerRFCsRoute}/${customerRFC._id}`,
        customerRFC
      );
      if (data.ok) {
        await fireSwalSuccess("El RFC ha sido actualizado");
      }
      await dispatch<any>(fetchCustomerRFCs());
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteCustomerRFC = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await hvpAuthenticatedApi.delete(
        `${customerRFCsRoute}/${id}`
      );
      if (data.ok) {
        await fireSwalSuccess("El registro se ha eliminado exitosamente.");
      }
      await dispatch<any>(fetchCustomerRFCs());
    } catch (error: any) {
      handleError(error);
    }
  };
};

const fetchData = <T>(
  url: string,
  setDataAction: (data: T) => any,
  setIsLoadingAction: (isLoading: boolean) => any
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setIsLoadingAction(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<ApiResponse<T>>(url);
      const fetchedData = data.data;
      dispatch(setDataAction(fetchedData));
    } catch (error: any) {
      console.error(`Error fetching data from ${url}:`, error);
    } finally {
      dispatch(setIsLoadingAction(false));
    }
  };
};

export const fetchCustomerRFCs = () => {
  return fetchData<CustomerRFC[]>(
    `${customerRFCsRoute}`,
    setCustomerRFCs,
    setIsLoading
  );
};

export const fetchInvoiceUsages = () => {
  return fetchData<InvoiceUsage[]>(
    `${invoiceUsagesRoute}`,
    setInvoiceUsages,
    setIsLoading
  );
};

export const fetchFiscalRegimes = () => {
  return fetchData<FiscalRegime[]>(
    `${fiscalRegimesRoute}`,
    setFiscalRegimes,
    setIsLoading
  );
};

export const fetchPaymentMethods = () => {
  return fetchData<any>(
    `${paymentMethodsRoute}`,
    setPaymentMethods,
    setIsLoading
  );
};

export const createBillCreationInfo = (billCreationInfo: BillCreationInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.post(
        `${billCreationInfosRoute}`,
        billCreationInfo
      );

      if (data.ok) {
        fireSwalSuccess("Los datos de la factura han sido creados");
      }

      return data;
    } catch (error: any) {
      handleError(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateBillCreationInfo = (billCreationInfo: BillCreationInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.patch(
        `${billCreationInfosRoute}/${billCreationInfo._id}`,
        billCreationInfo
      );

      if (data.ok) {
        fireSwalSuccess("Factura actualizada exitosamente");
      }
      return data;
    } catch (error: any) {
      handleError(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const fetchBillCreationInfo = (billCreationInfoId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<BillCreationInfo>
      >(`${billCreationInfosRoute}/${billCreationInfoId}`);
      dispatch(setBillCreationInfo(data.data));
    } catch (error: any) {
      handleError(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const fetchBillCreationInfos = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<BillCreationInfo[]>
      >(`${billCreationInfosRoute}`);
      dispatch(setBillCreationInfos(data.data));
    } catch (error: any) {
      handleError(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteBillCreationInfo = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await hvpAuthenticatedApi.delete(
        `${billCreationInfosRoute}/${id}`
      );
      if (data.ok) {
        await fireSwalSuccess("El registro se ha eliminado exitosamente.");
        await dispatch<any>(fetchBillCreationInfos());
      }
    } catch (error: any) {
      handleError(error);
    }
  };
};

const handleError = (error: any) => {
  console.error("Axios Error:", error);

  let errorTitle = "Error al registrar";
  let errorMessage = "";

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorTitle = `Error: ${error.response.data.message || error.message}`;
    errorMessage = `Error: ${error.response.data.detail || error.message}`;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response from server. Please try again later.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }

  fireSwalError(errorMessage);
};
