import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchBillCreationInfos } from "../../../../store/thunks/billingThunks";
import { BillTable } from "./BillTable";
import { BillCreationInfoStatus } from "../../../../shared/types";
import { Container, Paper, Typography } from "@mui/material";

export const BillLastList = () => {
  const dispatch = useAppDispatch();

  const { billCreationInfos } = useAppSelector((state) => state.billingReducer);

  useEffect(() => {
    dispatch(fetchBillCreationInfos());
  }, [dispatch]);

  const sortedBills = billCreationInfos
    ? billCreationInfos
        .slice()
        .sort(
          (a, b) =>
            new Date(b.bill_date).getTime() - new Date(a.bill_date).getTime()
        )
    : [];

  const lastBills = sortedBills
    .filter((bill) => bill.status !== BillCreationInfoStatus.PENDING)
    .slice(0, 10);

  return (
    <Container>
      <Paper sx={{ padding: 2, textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h6">Last Bills</Typography>
        <BillTable billCreationInfos={lastBills} />
      </Paper>
    </Container>
  );
};
