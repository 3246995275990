import { startCase, camelCase } from "lodash";

export const formatToLabel = (value: string) => {
  if (/^[a-z]/.test(value)) {
    // If value starts with lowerCase, change it to upperCase
    return startCase(value);
  } else if (/_/.test(value)) {
    // If the value is like "requested-at", change it to "Requested at"
    return startCase(value);
  } else {
    // If the value is like "requestedAt", change it to "Requested at"
    return startCase(camelCase(value));
  }
};
