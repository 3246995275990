import React, { useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  Box,
  useTheme,
  Grid,
} from "@mui/material";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { CustomerRFCCard } from "./CustomerRFCCard";

interface CustomerSearchProps {
  showStartingData?: boolean;
  title?: string;
}

export const CustomerSearch = ({
  showStartingData = false,
  title = "Customer Search",
}: CustomerSearchProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { customerRFCs } = useAppSelector((state) => state.billingReducer);

  const theme = useTheme();

  const filteredCustomers = customerRFCs.filter(
    (customer) =>
      !searchTerm ||
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.rfc.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const boxStyle = showStartingData ? {} : { maxHeight: 400, overflow: "auto" };

  return (
    <Paper sx={{ padding: theme.spacing(3), margin: theme.spacing(2) }}>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ textAlign: "center" }}
      >
        {title}
      </Typography>
      <TextField
        label="Search by name or RFC"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: theme.spacing(2) }}
      />
      {(showStartingData || searchTerm) && (
        <Box sx={boxStyle}>
          <Grid container spacing={2}>
            {filteredCustomers.map((customer) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={customer._id}>
                <CustomerRFCCard customerRFC={customer} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};
