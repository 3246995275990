import { useState } from "react";
import { Task, TaskStatus } from "../shared/types/Task";

export const useFilterTaksByInclusions = (tasks: Task[]) => {
  const [showCanceled, setshowCanceled] = useState(false);
  const [showCompleted, setshowCompleted] = useState(false);
  const filteredTasksByCheckBoxes = tasks.filter((task) => {
    if (
      task.status === TaskStatus.Backlog ||
      task.status === TaskStatus.OnHold ||
      task.status === TaskStatus.Next ||
      task.status === TaskStatus.InProgress ||
      task.status === TaskStatus.InReview
    ) {
      return true;
    } else if (showCanceled && task.status === TaskStatus.Canceled) {
      return true;
    } else if (showCompleted && task.status === TaskStatus.Completed) {
      return true;
    }
    return false;
  });

  const handleShowCompletedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setshowCompleted(event.target.checked);
  };

  const handleShowCanceledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setshowCanceled(event.target.checked);
  };

  return {
    filteredTasksByCheckBoxes,
    showCanceled,
    showCompleted,
    handleShowCanceledChange,
    handleShowCompletedChange,
  };
};
