import { Dispatch, GetState } from "@reduxjs/toolkit";
import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";
import { ApiResponse } from "../../shared/types";
import {
  LoginResponse,
  RegisterCollaborator,
} from "../../shared/types/authTypes";
import {
  loginFailure,
  loginSuccess,
  requestLogin,
  setLoading,
  logout,
} from "../slice/authSlice";
import hvpPublicApi from "../../api/hvpPublicApi";
import {
  fireSwalSuccess,
  getCollaboratorById,
  handleError,
} from "../../helpers";
import { RootState } from "../store";

const authRoute = "/auth";
export const startCheckingNew = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem("token");

      if (!token) {
        return dispatch(setLoading(false));
      }
      const { data } = await hvpAuthenticatedApi.post<
        ApiResponse<LoginResponse>
      >(`${authRoute}/collaborator/refresh`);

      if (data.ok) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem(
          "token-init-date",
          new Date().getTime().toString()
        );
      }
      dispatch(loginSuccess(data.data.user));
    } catch (error: any) {
      dispatch(loginFailure(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const login = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await hvpPublicApi.post<ApiResponse<LoginResponse>>(
        `${authRoute}/collaborator/login`,
        { email, password }
      );
      if (data.ok) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem(
          "token-init-date",
          new Date().getTime().toString()
        );
      }
      const { uid, col_code, role, image_url } = data.data.user;
      dispatch(
        loginSuccess({
          uid,
          collaborator_code: col_code,
          role,
          image_url,
        })
      );
    } catch (error: any) {
      dispatch(loginFailure(error.message));
      handleError(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const startLoggingOut = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.post<
        ApiResponse<LoginResponse>
      >(`${authRoute}/collaborator/logout`);
      if (data.ok) {
        localStorage.removeItem("token");
        localStorage.removeItem("token-init-date");
        dispatch(logout());
      }
    } catch (error: any) {
      dispatch(loginFailure(error.message));
      handleError(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const registerCollaborator = (user: RegisterCollaborator) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      // todo re review my auth flow on the backend
      const { data } = await hvpPublicApi.post<ApiResponse<LoginResponse>>(
        `${authRoute}/register`,
        user
      );
    } catch (error: any) {
      dispatch(loginFailure(error.message));
      handleError(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const collaboratorPasswordChange = (
  password: string,
  newPassword: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await hvpAuthenticatedApi.patch<
        ApiResponse<LoginResponse>
      >(`${authRoute}/collaborator/change-password`, { password, newPassword });
      if (data.ok) {
        await fireSwalSuccess("Password changed successfully");
      }
      return true;
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changeUserCredentialsByEmailAndPassword = (
  email: string,
  password: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await hvpPublicApi.post<ApiResponse<LoginResponse>>(
        `${authRoute}/collaborator/login`,
        { email, password }
      );
      const { data: logoutData } = await hvpAuthenticatedApi.post<
        ApiResponse<LoginResponse>
      >(`${authRoute}/collaborator/logout`);
      if (data.ok && logoutData.ok) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem(
          "token-init-date",
          new Date().getTime().toString()
        );
        const { uid, col_code, role, image_url } = data.data.user;
        dispatch(
          loginSuccess({
            uid,
            collaborator_code: col_code,
            role,
            image_url,
          })
        );
        await fireSwalSuccess("user changed successfully");
      }
    } catch (error) {
      handleError(error);
    }
  };
};

export const changeUserCredentialsById = (collaboratorId: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { data } = await hvpPublicApi.post<ApiResponse<LoginResponse>>(
        `${authRoute}/collaborator/simplified-login`,
        { collaboratorId }
      );

      if (data.ok) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem(
          "token-init-date",
          new Date().getTime().toString()
        );
        const { uid, col_code, role, image_url } = data.data.user;
        dispatch(
          loginSuccess({
            uid,
            collaborator_code: col_code,
            role,
            image_url,
          })
        );
        await fireSwalSuccess("user changed successfully");
      }
    } catch (error) {
      handleError(error);
    }
  };
};
