import { Typography } from "@mui/material";
import React from "react";

type LabelComponentProps = {
  label: string;
};
export const PropertyLabel = ({ label }: LabelComponentProps) => {
  return (
    <Typography variant="body1" style={{ marginRight: 2, fontWeight: "bold" }}>
      {label}:&nbsp;
    </Typography>
  );
};
