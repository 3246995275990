import dayjsWithPlugins from "../config/dayjs";
import {
  PRIORITY_COLORS,
  PRIORITY_RANKING,
  TAG_COLORS,
  Task,
  TaskActivity,
  TaskColumns,
  TaskPriority,
  TaskStatus,
  TaskTag,
} from "../shared/types/Task";
import { isISODateString } from "./dateHelpers";

export const sortTasks = (tasks: Task[]): Task[] => {
  const sortedTasks = [...tasks];
  sortedTasks.sort((a, b) => {
    const priorityComparison =
      PRIORITY_RANKING[a.priority] - PRIORITY_RANKING[b.priority];
    if (priorityComparison !== 0) return priorityComparison;

    // If priority is equal, compare requestedAt dates
    if (a.requestedAt && b.requestedAt) {
      return (
        new Date(b.requestedAt).getTime() - new Date(a.requestedAt).getTime()
      );
    }

    // If one or both dates are missing, sort those with a date first
    if (!a.requestedAt) return 1;
    if (!b.requestedAt) return -1;

    // If both dates are missing, they are equal
    return 0;
  });
  return sortedTasks;
};

export const getPriorityColor = (priority: TaskPriority) =>
  PRIORITY_COLORS[priority] || "#FFFFFF"; // Default color

export const getTagColor = (tag: TaskTag) => TAG_COLORS[tag] || "#FFFFFF"; // Default color

export const getTaskNumberAndTitleById = (tasks: Task[], taskId: string) => {
  const task = tasks.find((task) => task._id === taskId);
  if (!task) return undefined;
  return `#${task?.number} - ${task?.title}`;
};

export const sortTasksByCriteria = (
  tasks: Task[],
  criteria: keyof Task,
  order: "asc" | "desc" = "asc"
): Task[] => {
  return tasks.slice().sort((taskA, taskB) => {
    const valueA = taskA[criteria];
    const valueB = taskB[criteria];

    // new date handling
    if (isISODateString(valueA) || isISODateString(valueB)) {
      const dateA = isISODateString(valueA) ? new Date(valueA as string) : null;
      const dateB = isISODateString(valueB) ? new Date(valueB as string) : null;

      if (dateA && dateB) {
        return order === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return order === "asc" ? -1 : 1;
      } else if (dateB) {
        return order === "asc" ? 1 : -1;
      }
    }

    // Handle date comparison separately
    if (typeof valueA === "string" && typeof valueB === "string") {
      const dateA = valueA ? new Date(valueA) : null;
      const dateB = valueB ? new Date(valueB) : null;

      if (dateA && dateB) {
        return order === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return order === "asc" ? -1 : 1;
      } else if (dateB) {
        return order === "asc" ? 1 : -1;
      }
    }

    // Provide a default value if the property is undefined
    const defaultValue =
      order === "asc" ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;

    // Use the default value when the property is undefined
    const compareA = valueA ? valueA : defaultValue;
    const compareB = valueB ? valueB : defaultValue;

    if (order === "asc") {
      if (compareA < compareB) return -1;
      if (compareA > compareB) return 1;
      return 0;
    } else {
      if (compareA > compareB) return -1;
      if (compareA < compareB) return 1;
      return 0;
    }
  });
};

export const filterTasksByDateRange = (
  tasks: Task[],
  startDate: Date,
  endDate: Date
) => {
  return tasks.filter((task) => {
    const requestedAt = task.requestedAt ? new Date(task.requestedAt) : null;
    const completedAt = task.completedAt ? new Date(task.completedAt) : null;

    // Condition 1: RequestedAt date is previous to the endDate
    const condition1 = !requestedAt || requestedAt <= endDate;

    // Condition 2: No completedAt or completedAt date is after startDate and before endDate
    const condition2 = !completedAt || completedAt >= startDate;

    return condition1 && condition2;
  });
};

/*
Requested before the end date
Is not completed
Is not completed before the start date
Is not completed after the end date
*/

export const filterActivitiesByDateRange = (
  activities: TaskActivity[],
  startDate: Date,
  endDate: Date
) => {
  return activities!.filter(
    (activity) =>
      dayjsWithPlugins(activity.registeredAt).isSameOrAfter(
        dayjsWithPlugins(startDate)
      ) &&
      dayjsWithPlugins(activity.registeredAt).isSameOrBefore(
        dayjsWithPlugins(endDate)
      )
  );
};

export const removeOldTasksFromColumns = (taskColumns: TaskColumns) => {
  const updatedColumns = { ...taskColumns };

  const filterTasks = (tasks: Task[]) =>
    tasks.filter((task) => {
      return (
        task.completedAt &&
        dayjsWithPlugins(task.completedAt).isAfter(
          dayjsWithPlugins().subtract(1, "month")
        )
      );
    });

  updatedColumns[TaskStatus.Completed] = filterTasks(
    updatedColumns[TaskStatus.Completed]
  );
  updatedColumns[TaskStatus.Canceled] = filterTasks(
    updatedColumns[TaskStatus.Canceled]
  );

  return updatedColumns;
};
