import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  setInitialLoading,
  setLastCollaboratorAttendanceRecord,
  setIsLastCollaboratorAttendanceLoading,
  setIsCurrentAttendanceRecordLoading,
  setCurrentAttendanceRecords,
  setCollaboratorAttendanceRecords,
  setIsLoading,
  setAttendanceRecord,
  setAllAttendanceRecords,
} from "../slice/attendanceRecordsSlice";
import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";

import { ApiResponse, AttendanceRecord } from "../../shared/types";
import Swal from "sweetalert2";
import { fireSwalError, fireSwalSuccess } from "../../helpers";

const route = "/attendance-records";

export const fetchInitialData = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setInitialLoading(true));
    try {
      await dispatch<any>(fetchLastRecord());
      await dispatch<any>(getCurrentAttendanceRecords());
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setInitialLoading(false));
    }
  };
};
export const fetchLastRecord = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { uid } = getState().authReducer.user!;
    dispatch(setIsLastCollaboratorAttendanceLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<AttendanceRecord>
      >(`${route}/collaborator/${uid}/last`);
      const fetchedData = data.data;
      dispatch(setLastCollaboratorAttendanceRecord(fetchedData));
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setIsLastCollaboratorAttendanceLoading(false));
    }
  };
};

export const getCurrentAttendanceRecords = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setIsCurrentAttendanceRecordLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<AttendanceRecord[]>
      >(`${route}/current`);
      const fetchedData = data.data;
      dispatch(setCurrentAttendanceRecords(fetchedData));
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setIsCurrentAttendanceRecordLoading(false));
    }
  };
};

export const startSavingAttendanceRecord = (
  attendanceRecord: AttendanceRecord
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      setIsLastCollaboratorAttendanceLoading(true);
      const { data } = await hvpAuthenticatedApi.post<
        ApiResponse<AttendanceRecord>
      >(route, attendanceRecord);
      if (data.ok) {
        await fireSwalSuccess("El registro se ha guardado exitosamente.");
      }

      const fetchedResource = data.data;
      await dispatch<any>(fetchInitialData());

      return fetchedResource;
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLastCollaboratorAttendanceLoading(false);
    }
  };
};

export const startUpdatingAttendanceRecord = (
  attendanceRecord: AttendanceRecord
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      setIsLastCollaboratorAttendanceLoading(true);
      const recordId = attendanceRecord._id;
      const { data } = await hvpAuthenticatedApi.patch<
        ApiResponse<AttendanceRecord>
      >(`${route}/${recordId}`, attendanceRecord);
      if (data.ok) {
        await Swal.fire({
          icon: "success",
          title: "Registro exitoso",
          showConfirmButton: false,
          timer: 1000,
        });
      }

      const fetchedResource = data.data;
      await dispatch<any>(fetchInitialData());

      return fetchedResource;
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLastCollaboratorAttendanceLoading(false);
    }
  };
};

export const getCollaboratorAttendanceRecords = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setIsCurrentAttendanceRecordLoading(true));
    try {
      //@ts-ignore
      const { uid } = getState().authReducer.user!;
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<AttendanceRecord[]>
      >(`${route}/collaborator/${uid}`);
      const fetchedData = data.data;
      dispatch(setCollaboratorAttendanceRecords(fetchedData));
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setIsCurrentAttendanceRecordLoading(false));
    }
  };
};

export const getAttendanceRecordById = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<AttendanceRecord>
      >(`${route}/${id}`);
      const fetchedData = data.data;
      dispatch(setAttendanceRecord(fetchedData));
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getAllAttendanceRecords = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<AttendanceRecord[]>
      >(`${route}`);
      const fetchedData = data.data;
      dispatch(setAllAttendanceRecords(fetchedData));
    } catch (error: any) {
      console.error("Error:", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteAttendanceRecordById = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await hvpAuthenticatedApi.delete<
        ApiResponse<AttendanceRecord>
      >(`${route}/${id}`);
      if (data.ok) {
        fireSwalSuccess("El registro se ha eliminado exitosamente.");
      }
      await dispatch<any>(fetchInitialData());
    } catch (error: any) {
      handleError(error);
    }
  };
};

const handleError = (error: any) => {
  console.error("Axios Error:", error);

  let errorTitle = "Error al registrar";
  let errorMessage = "";

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorMessage = `Error: ${error.response.data.message || error.message}`;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response from server. Please try again later.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }

  fireSwalError(errorMessage);
};
