import React from "react";
import { Box } from "@mui/material";
import { KanbanColumn } from "./KanbanColumn";
import { TaskStatus } from "../../../../shared/types/Task";
import { TasksFilters } from "./TaskViewComponents/TasksFilters";
import { DragDropContext } from "react-beautiful-dnd";
import { useKanban } from "../../../../hooks/useKanban";
import { removeOldTasksFromColumns } from "../../../../helpers";

export const Kanban = () => {
  const { filters, handleFilterChange, onDragEnd, columns } = useKanban();

  const filteredColumns = removeOldTasksFromColumns(columns);

  return (
    <Box>
      <TasksFilters filters={filters} handleFilterChange={handleFilterChange} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          overflow="auto"
          display="flex"
          whiteSpace="nowrap"
          style={{ height: "80vh", overflowY: "scroll" }}
        >
          {Object.entries(filteredColumns).map(([status, tasksForStatus]) => (
            <KanbanColumn
              key={status}
              status={status as TaskStatus}
              tasks={tasksForStatus}
            />
          ))}
        </Box>
      </DragDropContext>
    </Box>
  );
};
