import React from "react";
import { Task, TaskStatus } from "../../../../../shared/types/Task";
import { CustomPieChart } from "../../../../../components/charts/CustomPieChart";
import { generateColors } from "../../../../../helpers/colorHelpers";

import { countByKey } from "../../../../../helpers/collectionHelpers";

type Props = {
  tasks: Task[];
};

export const CompletionRateChart: React.FC<Props> = ({ tasks }) => {
  const taskStatuses = countByKey(tasks, (task: Task) => task.status);

  const data = Object.values(TaskStatus).map((status, index) => ({
    name: status,
    value: taskStatuses[status] || 0,
    fill: generateColors(Object.values(TaskStatus).length)[index],
  }));

  return <CustomPieChart data={data} />;
};
