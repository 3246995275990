import hvpPublicApi from "../hvpPublicApi";

export const sendPasswordResetEmail = async (email: string) => {
  try {
    const { data } = await hvpPublicApi.post("/api/auth/forgot-password", {
      email,
    });

    return data.ok;
  } catch (error) {
    throw error;
  }
};
