import { Box, Typography } from "@mui/material";
import React from "react";
import { getCollaboratorAvatarPropsById } from "../../helpers/collaboratorsHelpers";
import { CustomAvatar } from "../avatar/CustomAvatar";
import { Collaborator } from "../../shared/types/CollaboratorsTypes";

type Props = {
  collaborators: Collaborator[];
  collaboratorsIds: string[];
  label: string;
};

export const LabelValueAvatars: React.FC<Props> = ({
  collaboratorsIds,
  collaborators,
  label,
}) => {
  console.log("collaborator", collaboratorsIds);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 1,
      }}
    >
      <Typography
        variant="body1"
        style={{ marginRight: 2, fontWeight: "bold" }}
      >
        {label}:
      </Typography>
      {collaboratorsIds.map((collaboratorId) => (
        <Box marginRight={1} marginLeft={1} key={collaboratorId}>
          <CustomAvatar
            {...getCollaboratorAvatarPropsById(collaborators, collaboratorId)}
            width={40}
          />
        </Box>
      ))}
    </Box>
  );
};
