import React from "react";
import { FormControl, FormLabel } from "@mui/material";
import { DayPicker } from "react-day-picker";

interface Props {
  label: string;
  selected: Date;
  setter: (date: Date) => void;
  disabled?: boolean;
}
export const CustomDayPicker = ({
  label,
  selected,
  setter,
  disabled,
}: Props) => {
  return (
    <FormControl variant="outlined">
      <FormLabel>{label}</FormLabel>
      <DayPicker
        mode="single"
        disabled={disabled}
        selected={selected}
        onSelect={(date: Date | undefined) => {
          if (date) {
            date.setHours(23, 59, 59, 999);
            setter(date);
          }
        }}
      />
    </FormControl>
  );
};
