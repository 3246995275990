import React from "react";
import { Field } from "formik";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { IOption } from "../../shared/types/option.types";

interface Props {
  label: string;
  name: string;
  error?: boolean;
  options: IOption[];
  disabled?: boolean;
}

export const FormikOptionSelect: React.FC<Props> = ({
  label,
  name,
  error,
  options,
  disabled = false,
}) => {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Field
        as={Select}
        labelId={`${name}-label`}
        id={name}
        name={name}
        label={label}
        disabled={disabled}
      >
        {options.map((option: IOption) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};
