import { WorkLog } from "../shared/types/WorkLog";

export const getWorkLogForToday = (
  workLogs: WorkLog[],
  date: Date,
  uid: string
) => {
  const currentDate = new Date(date);
  // Set the time part of currentDate to midnight
  currentDate.setHours(0, 0, 0, 0);

  const todayLogs = workLogs.filter((log) => {
    // Parse the logDate from ISO string
    const logDate = new Date(log.logDate);

    // Set the time part of logDate to midnight
    logDate.setHours(0, 0, 0, 0);

    // Compare with currentDate

    return logDate.getTime() === currentDate.getTime();
  });
  const todayLog = todayLogs.find((log) => log.createdBy === uid);

  return todayLog;
};

export const removeWorkLogEmptyActivities = (workLog: WorkLog) => {
  const activities = workLog.activities
    .filter((activity) => activity.content && activity?.content.length > 0)
    .map((activity) => ({
      relatedTask:
        activity.relatedTask === "" ? undefined : activity.relatedTask,
      content: activity.content,
      relatedTaskActivity:
        activity.relatedTaskActivity === ""
          ? undefined
          : activity.relatedTaskActivity,
    }));

  workLog.activities = activities;

  return workLog;
};

export const getRecentDates = () => {
  const dates = [];
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  for (let i = 0; i < 15; i++) {
    const newDate = new Date(currentDate.getTime());
    newDate.setDate(newDate.getDate() - i);
    dates.push(newDate);
  }
  return dates;
};
