import React from "react";
import { Task } from "../../../../../shared/types/Task";
import dayjsWithPlugins from "../../../../../config/dayjs";
import { TaskListCard } from "../TaskListCard";

type Props = { tasks: Task[]; startDate: Date; endDate: Date };
export const ReportNewTasks: React.FC<Props> = ({
  tasks,
  startDate,
  endDate,
}) => {
  const newTasks = tasks.filter(
    (task) =>
      dayjsWithPlugins(task.requestedAt).isSameOrAfter(
        dayjsWithPlugins(startDate)
      ) &&
      dayjsWithPlugins(task.requestedAt).isSameOrBefore(
        dayjsWithPlugins(endDate)
      )
  );

  return (
    <>
      {newTasks.map((task) => (
        <TaskListCard
          task={task}
          showActivities={false}
          startDate={startDate}
          endDate={endDate}
        ></TaskListCard>
      ))}
    </>
  );
};
