import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  ActivityRegisterAll,
  ActivityRegisterCalculator,
  ActivityRegisterForm,
  ActivityRegisterTypeManager,
  ActivityRegiterColView,
} from "../../pages/dashboard";

export const ActivityRegisterRouter = () => {
  return (
    <Routes>
      <Route index element={<ActivityRegiterColView />} />
      <Route path="" element={<ActivityRegiterColView />} />
      <Route path="new" element={<ActivityRegisterForm />} />
      <Route path="all" element={<ActivityRegisterAll />} />
      <Route path="types" element={<ActivityRegisterTypeManager />} />
      <Route path="calculator" element={<ActivityRegisterCalculator />} />
      <Route path=":id" element={<ActivityRegisterForm />} />
      <Route path="*" element={<ActivityRegiterColView />} />
    </Routes>
  );
};
