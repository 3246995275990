import React from "react";
import { Task } from "../../../../../shared/types/Task";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getCollaboratorById } from "../../../../../helpers/collaboratorsHelpers";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { isDateBetween } from "../../../../../helpers";

type Props = {
  tasks: Task[];
  startDate: Date;
  endDate: Date;
};
export const ProductivityTasksTable: React.FC<Props> = ({
  tasks,
  startDate,
  endDate,
}) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const collaboratorsIds: string[] = [];

  tasks.forEach((task) => {
    if (!collaboratorsIds.includes(task.author)) {
      collaboratorsIds.push(task.author);
    }
    if (task.assignees) {
      task.assignees.forEach((assignee) => {
        if (!collaboratorsIds.includes(assignee)) {
          collaboratorsIds.push(assignee);
        }
      });
    }
  });

  interface Productivity {
    colId: string;
    tasksCreated: number;
    tasksAssigned: number;
    tasksCompleted: number;
    tasksPending: number;
  }
  const productivityData: Productivity[] = [];

  // Step 4
  collaboratorsIds.forEach((colId) => {
    const productivity = {
      colId,
      tasksCreated: 0,
      tasksAssigned: 0,
      tasksCompleted: 0,
      tasksPending: 0,
    };
    tasks.forEach((task) => {
      if (
        task.author === colId &&
        isDateBetween(startDate, endDate, task.requestedAt!)
      ) {
        productivity.tasksCreated++;
      }
      if (task.assignees) {
        if (task.assignees.includes(colId)) {
          productivity.tasksAssigned++;
          if (
            task.completedAt &&
            isDateBetween(startDate, endDate, task.completedAt)
          ) {
            productivity.tasksCompleted++;
          } else {
            productivity.tasksPending++;
          }
        }
      }
    });
    productivityData.push(productivity);
  });

  productivityData.sort((a, b) => {
    return b.tasksCompleted - a.tasksCompleted;
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Person</TableCell>
            <TableCell align="right">Tasks Created</TableCell>
            <TableCell align="right">Tasks Assigned</TableCell>
            <TableCell align="right">Tasks Completed</TableCell>
            <TableCell align="right">Tasks Pending</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productivityData.map((data) => (
            <TableRow key={data.colId}>
              <TableCell>
                {getCollaboratorById(collaborators, data.colId)?.col_code}
              </TableCell>
              <TableCell align="right">{data.tasksCreated}</TableCell>
              <TableCell align="right">{data.tasksAssigned}</TableCell>
              <TableCell align="right">{data.tasksCompleted}</TableCell>
              <TableCell align="right">{data.tasksPending}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
