import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useCollaboratorHook } from "../../hooks";
import { getFormattedDate } from "../../helpers";

type Props = {
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
};

export const CreatedAndUpdatedInfo = ({
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
}: Props) => {
  const { getCollaboratorCodeById } = useCollaboratorHook();

  return (
    <Box m={2}>
      <Typography variant="body2" color="textSecondary">
        {createdAt && createdBy && (
          <span>
            <strong>Created At:</strong> {getFormattedDate(createdAt)}{" "}
            <strong>by</strong> {getCollaboratorCodeById(createdBy)}{" "}
            {updatedAt && updatedBy && " | "}
          </span>
        )}
        {updatedAt && updatedBy && (
          <span>
            <strong>Last Updated At:</strong> {getFormattedDate(updatedAt)}{" "}
            <strong>by</strong> {getCollaboratorCodeById(updatedBy)}
          </span>
        )}
      </Typography>
    </Box>
  );
};
