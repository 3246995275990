import { Branch } from "../shared/types";

export const isValidBranch = (branch?: string | null): branch is Branch => {
  if (!branch) return false;
  return Object.values(Branch).includes(branch as Branch);
};

export const getBranchesAsOptions = () => {
  return Object.values(Branch).map((branch) => ({
    id: branch,
    label: branch,
    value: branch,
  }));
};
