import React, { useState } from "react";
import { TextField, Button, List, ListItem } from "@mui/material";
import { Task } from "../../../../../shared/types/Task";
import { RowFlexBoxContainer } from "./RowFlexBoxContainer";
import EditIcon from "@mui/icons-material/Edit";
import { fireSwalError } from "../../../../../helpers";

type Props = {
  task: Task;
  field: keyof Task;
  onSave: (updatedTask: Task) => void;
  fontColor?: string;
};

export const EditableArrayField: React.FC<Props> = ({
  task,
  field,
  onSave,
  fontColor,
}) => {
  const [editedValues, setEditedValues] = useState<string[]>(
    task[field] as string[]
  );
  const [isEditable, setIsEditable] = useState(false);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = [...editedValues];
      newValues[index] = event.target.value;
      setEditedValues(newValues);
    };

  const handleSave = async () => {
    if (editedValues.some((value) => value.length < 1)) {
      fireSwalError("The task description must not be empty");
      setIsEditable(false);
      return;
    }
    await onSave({ ...task, [field]: editedValues });
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleRemove = (index: number) => {
    const newValues = editedValues.filter((_, i) => i !== index);
    setEditedValues(newValues);
  };

  const handleAdd = () => {
    setEditedValues([...editedValues, ""]);
  };

  return (
    <>
      {isEditable ? (
        <>
          {editedValues.map((value, index) => (
            <div key={index}>
              <TextField
                fullWidth
                name={`${field.toString()}-${index}`}
                value={value}
                onChange={handleChange(index)}
                inputProps={fontColor ? { color: fontColor } : {}}
              />
              <Button onClick={() => handleRemove(index)}>Remove</Button>
            </div>
          ))}
          <Button onClick={handleAdd}>Add new</Button>
          <Button onClick={handleSave}>Save</Button>
        </>
      ) : (
        <RowFlexBoxContainer>
          <List>
            {(task[field] as string[]).map((value: string, index: number) => (
              <ListItem key={index}>{value}</ListItem>
            ))}
          </List>
          <Button onClick={handleEdit}>
            <EditIcon />
          </Button>
        </RowFlexBoxContainer>
      )}
    </>
  );
};
