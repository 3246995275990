import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createBillCreationInfo,
  deleteBillCreationInfo,
  fetchBillCreationInfo,
  updateBillCreationInfo,
} from "../../../../store/thunks/billingThunks";
import {
  BillCreationInfo,
  BillCreationInfoStatus,
} from "../../../../shared/types";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { fireSwalWarning, getBranchesAsOptions } from "../../../../helpers";
import { CreatedAndUpdatedInfo } from "../../../../components";

const BillCreationSchema = Yup.object().shape({
  customer_rfc: Yup.string().required("Customer RFC is required"),
  branch: Yup.string().required("Branch is required"),
  document_number: Yup.string().required("Document number is required"),
  status: Yup.mixed<BillCreationInfoStatus>()
    .oneOf(Object.values(BillCreationInfoStatus))
    .required("Status is required"),
  total: Yup.number().required("Total is required"),
  is_documented: Yup.boolean().required("Documentation status is required"),
  payment_method: Yup.string().required("Payment method is required"),
  bill_date: Yup.date().required("Bill date is required"), // Add this line
});

export const CreateOrUpdateBillInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const branches = getBranchesAsOptions();

  const { id } = useParams<{ id: string }>();

  const { customerRFCs, paymentMethods, billCreationInfo } = useAppSelector(
    (state) => state.billingReducer
  );

  const searchParams = new URLSearchParams(location.search);

  const customerRfcIdFromQuery = searchParams.get("customer-rfc-id");

  const initialBill = useMemo(() => {
    const baseBill = {
      customer_rfc: customerRfcIdFromQuery || "",
      document_number: "",
      status: BillCreationInfoStatus.PENDING,
      total: 0,
      is_documented: false,
      payment_method: "",
      bill_date: new Date().toISOString().split("T")[0], // Default to today's date
    };
    if (id && billCreationInfo) {
      const formattedBillDate = billCreationInfo.bill_date
        ? new Date(billCreationInfo.bill_date).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0];
      return { ...baseBill, ...billCreationInfo, bill_date: formattedBillDate };
    }
    return baseBill;
  }, [id, billCreationInfo, customerRfcIdFromQuery]);

  const [initialValues, setInitialValues] =
    useState<BillCreationInfo>(initialBill);

  useEffect(() => {
    if (id) {
      dispatch(fetchBillCreationInfo(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    setInitialValues(initialBill);
  }, [initialBill]);

  const handleSubmit = async (
    values: BillCreationInfo,
    { setSubmitting }: FormikHelpers<BillCreationInfo>
  ) => {
    setSubmitting(true);
    let redirectdId = id;
    if (redirectdId) {
      await dispatch(updateBillCreationInfo(values));
    } else {
      const data = await dispatch(createBillCreationInfo(values));
      redirectdId = data?.data?._id;
    }
    navigate(`/dashboard/billing/create-bill/${redirectdId}`);
    setSubmitting(false);
  };

  const handleDelete = async () => {
    if (id) {
      const result = await fireSwalWarning(
        "Are you sure?",
        "This action is irreversible."
      );
      if (!result.isConfirmed) return;
      await dispatch(deleteBillCreationInfo(id));
      // Ensure navigation is awaited or confirmed before proceeding
      navigate("/dashboard/billing");
    }
  };

  return (
    <Box>
      <Typography variant="h4">{id ? "Edit Bill" : "Create Bill"}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={BillCreationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          handleChange,
          isSubmitting,
        }) => {
          console.log({ values });
          return (
            <Form>
              <Paper elevation={3} sx={{ margin: 2, padding: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Bill Date*</FormLabel>
                      <Field
                        as={TextField}
                        type="date"
                        name="bill_date"
                        error={touched.bill_date && Boolean(errors.bill_date)}
                        helperText={touched.bill_date && errors.bill_date}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Customer RFC*</FormLabel>
                      <Autocomplete
                        options={customerRFCs}
                        getOptionLabel={(option) =>
                          `${option.rfc} - ${option.name}`
                        }
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "customer_rfc",
                            newValue ? newValue._id : ""
                          );
                        }}
                        value={
                          customerRFCs.find(
                            (option) => option._id === values.customer_rfc
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Customer RFC"
                            error={
                              touched.customer_rfc &&
                              Boolean(errors.customer_rfc)
                            }
                            helperText={
                              touched.customer_rfc && errors.customer_rfc
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Branch*</FormLabel>
                      <Autocomplete
                        options={branches}
                        getOptionLabel={(option) => option.value}
                        onChange={(event, newValue) =>
                          setFieldValue(
                            "branch",
                            newValue ? newValue.value : ""
                          )
                        }
                        value={
                          branches.find(
                            (branch) => branch.id === values.branch
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Branch"
                            error={touched.branch && Boolean(errors.branch)}
                            helperText={touched.branch && errors.branch}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Document Number*</FormLabel>
                      <Field
                        as={TextField}
                        name="document_number"
                        placeholder="Document Number"
                        error={
                          touched.document_number &&
                          Boolean(errors.document_number)
                        }
                        helperText={
                          touched.document_number && errors.document_number
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Status*</FormLabel>
                      <Autocomplete
                        options={Object.values(BillCreationInfoStatus)}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) =>
                          setFieldValue("status", newValue)
                        }
                        value={values.status}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Status"
                            error={touched.status && Boolean(errors.status)}
                            helperText={touched.status && errors.status}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Total*</FormLabel>
                      <Field
                        as={TextField}
                        name="total"
                        type="number"
                        placeholder="Total"
                        error={touched.total && Boolean(errors.total)}
                        helperText={touched.total && errors.total}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      error={
                        touched.payment_method && Boolean(errors.payment_method)
                      }
                      fullWidth
                    >
                      <InputLabel>Payment Method</InputLabel>
                      <Field as={Select} name="payment_method" displayEmpty>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {paymentMethods?.map((method) => (
                          <MenuItem key={method.id} value={method.value}>
                            {method.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText>
                        {touched.payment_method && errors.payment_method}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="is_documented"
                          color="primary"
                          checked={values.is_documented}
                        />
                      }
                      label="Documented"
                    />
                  </Grid>
                  <CreatedAndUpdatedInfo
                    createdAt={values.createdAt}
                    createdBy={values.createdBy}
                    updatedAt={values.updatedAt}
                    updatedBy={values.updatedBy}
                  />
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        disabled={!id} // Disable if there's no ID, meaning it's a new bill
                        fullWidth
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
