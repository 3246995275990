import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchBillCreationInfos } from "../../../../store/thunks/billingThunks";
import { BillTable } from "./BillTable";
import { BillCreationInfoStatus } from "../../../../shared/types";
import { Container, Paper, Typography } from "@mui/material";

export const BillPendingList = () => {
  const dispatch = useAppDispatch();

  const { billCreationInfos } =
    useAppSelector((state) => state.billingReducer) ?? [];

  useEffect(() => {
    dispatch(fetchBillCreationInfos());
  }, [dispatch]);

  const bills =
    billCreationInfos?.filter(
      (bill) => bill.status === BillCreationInfoStatus.PENDING
    ) ?? [];

  return (
    <Container>
      <Paper sx={{ padding: 2, textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h6">Pending Bills</Typography>
        <BillTable billCreationInfos={bills} />
      </Paper>
    </Container>
  );
};
