import React from "react";
import { Task, TaskStatus } from "../../../../../shared/types/Task";
import { Box, Typography } from "@mui/material";

type Props = {
  tasks: Task[];
  startDate: Date;
  endDate: Date;
};

export const MainNumbers: React.FC<Props> = ({ tasks, startDate, endDate }) => {
  // Replace these with actual calculations

  let pendingLastPeriod = 0;
  let startedThisPeriod = 0;
  let completedThisPeriod = 0;
  let canceledThisPeriod = 0;

  tasks.forEach((task) => {
    if (new Date(task.requestedAt!) < startDate) {
      pendingLastPeriod++;
    }

    if (
      new Date(task.requestedAt!) > startDate &&
      new Date(task.requestedAt!) < endDate
    ) {
      startedThisPeriod++;
    }

    if (
      new Date(task.completedAt!) > startDate &&
      new Date(task.completedAt!) < endDate &&
      task.status === TaskStatus.Completed
    ) {
      completedThisPeriod++;
    }

    if (
      new Date(task.completedAt!) > startDate &&
      new Date(task.completedAt!) < endDate &&
      task.status === TaskStatus.Canceled
    ) {
      canceledThisPeriod++;
    }
  });

  let pendingTasksPeriod = pendingLastPeriod + startedThisPeriod;
  let concludedThisPeriod = completedThisPeriod + canceledThisPeriod;
  let completionRateTotal =
    pendingTasksPeriod === 0
      ? "0.00%"
      : ((concludedThisPeriod * 100) / pendingTasksPeriod).toFixed(2) + "%";
  let completionRateThisPeriod =
    startedThisPeriod === 0
      ? "0.00%"
      : ((concludedThisPeriod * 100) / startedThisPeriod).toFixed(2) + "%";
  let pendingNextPeriod =
    pendingLastPeriod + startedThisPeriod - concludedThisPeriod;

  return (
    <Box>
      <Typography variant="h6">
        Pending from last period: {pendingLastPeriod}
      </Typography>
      <Typography variant="h6">
        Started in the period: {startedThisPeriod}
      </Typography>
      <Typography variant="h6">
        Total tasks for the period: {pendingTasksPeriod}
      </Typography>
      <Typography variant="h6">
        Concluded in the period: {concludedThisPeriod}
      </Typography>
      <Typography variant="h6">
        Completed in the period: {completedThisPeriod}
      </Typography>
      <Typography variant="h6">
        Canceled in the period: {canceledThisPeriod}
      </Typography>
      <Typography variant="h6">
        Completion rate in the period: {completionRateThisPeriod}
      </Typography>
      <Typography variant="h6">
        Completion rate total: {completionRateTotal}
      </Typography>
      <Typography variant="h6">
        Pending for next period: {pendingNextPeriod}
      </Typography>
    </Box>
  );
};
