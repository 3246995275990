import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/";
import { MenuRoute, ModuleMenu } from "../../../components/menu/ModuleMenu";
import { fetchCurrentColActivityRegisterInitialData } from "../../../store/thunks/";
import { ActivityRegisterRouter } from "../../../routes";

export const ActivityRegisterLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCurrentColActivityRegisterInitialData());
  }, [dispatch]);

  const { isLoading } = useAppSelector(
    (state) => state.activityRegisterReducer
  );

  const routes: MenuRoute[] = [
    { name: "Main", path: "" },
    { name: "New", path: "new" },
    { name: "Calculator", path: "calculator" },
    { name: "All", path: "all" },
    { name: "Types", path: "types" },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? <CircularProgress /> : <ActivityRegisterRouter />}
      </Grid>
    </Grid>
  );
};
