import { Grid } from "@mui/material";
import React from "react";
import CustomButton from "../../../../components/buttons/CustomButton";

const routes = [
  {
    name: "Kanban",
    path: "",
  },
  {
    name: "Create a task",
    path: "create",
  },
  {
    name: "List",
    path: "list",
  },
  {
    name: "My tasks",
    path: "my-tasks",
  },
  {
    name: "Stats",
    path: "stats",
  },
  {
    name: "Report",
    path: "report",
  },
];

export const TasksMenu = () => {
  return (
    <Grid container direction="row" spacing={1}>
      {routes.map((route) => (
        // Check if the route has authorizedRoles and if the user's role is included

        <Grid key={route.path} item xs={12} md={2.4}>
          <CustomButton to={route.path}>{route.name}</CustomButton>
        </Grid>
      ))}
    </Grid>
  );
};
