import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  SelectChangeEvent,
} from "@mui/material";
import { IMissingProduct } from "../../../../shared/types";
import { MissingProductService } from "../../../../services";
import dayjs, { Dayjs } from "dayjs";
import { MissingProductsTable } from "./MissingProductsTable";
import { CollaboratorSelect } from "../../../../components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const DaysWithoutMissingProducts = () => {
  const service = useMemo(() => new MissingProductService(), []);
  const [missingProducts, setMissingProducts] = useState<IMissingProduct[]>([]);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IMissingProduct | null>(
    null
  );

  const fetchMissingProducts = useCallback(async () => {
    const response = await service.getAll({
      limit: 20,
      sort_by: "date",
      direction: "desc",
    });
    setMissingProducts(response);
  }, [service]);

  useEffect(() => {
    fetchMissingProducts();
  }, [fetchMissingProducts]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentProduct) {
      setCurrentProduct({
        ...currentProduct,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleEdit = (missingProduct: IMissingProduct) => {
    setCurrentProduct(missingProduct);
    setOpen(true);
  };

  const handleAdd = () => {
    setCurrentProduct({
      id: "",
      missingProduct: "",
      description: "",
      collaborator: "",
      date: dayjs().toDate(),
    });
    setOpen(true);
  };

  const handleSave = async () => {
    if (currentProduct) {
      if (currentProduct.id) {
        await service.update(currentProduct.id, currentProduct);
      } else {
        await service.create(currentProduct);
      }
      fetchMissingProducts();
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentProduct(null);
  };

  const handleDelete = async (id: string) => {
    await service.delete(id);
    fetchMissingProducts();
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    if (currentProduct) {
      setCurrentProduct({
        ...currentProduct,
        collaborator: event.target.value,
      });
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (currentProduct) {
      setCurrentProduct({
        ...currentProduct,
        date: date?.toDate() || dayjs().toDate(),
      });
    }
  };

  console.log({ currentProduct });

  const daysSinceLastMissingItem = () => {
    if (missingProducts.length === 0) return "N/A";
    const lastDate = dayjs(missingProducts[0].date);
    const now = dayjs();
    return now.diff(lastDate, "day");
  };

  return (
    <Container>
      <Box mt={4} mb={2}>
        <Typography variant="h4">Missing Items</Typography>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", color: "#3f51b5" }}
        >
          Days since last missing item:{" "}
          <span style={{ fontSize: "3rem", color: "#f50057" }}>
            {daysSinceLastMissingItem()}
          </span>
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAdd}>
          Report Missing Item
        </Button>
      </Box>
      <MissingProductsTable
        missingProducts={missingProducts}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentProduct?.id ? "Edit Missing Product" : "Add Missing Product"}
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              value={currentProduct?.date ? dayjs(currentProduct?.date) : null}
              onChange={handleDateChange}
              format="DD-MM-YYYY"
              slotProps={{
                textField: { fullWidth: true },
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          </LocalizationProvider>
          <TextField
            autoFocus
            margin="dense"
            label="Product Name"
            name="missingProduct"
            value={currentProduct?.missingProduct || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={currentProduct?.description || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <CollaboratorSelect
            selectedCollaborator={currentProduct?.collaborator}
            handleSelectChange={handleSelectChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
