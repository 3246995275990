import React from "react";
import { AttendanceLogger } from "./AttendanceLogger";
import { TodayCollaboratorsList } from "./TodayCollaboratorsList";

export const AttendanceRecordsMain = () => {
  return (
    <>
      <AttendanceLogger />
      <TodayCollaboratorsList />
    </>
  );
};
