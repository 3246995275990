import React from "react";
import { Menu, MenuItem } from "@mui/material";

interface UserMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onChangePassword: () => void;
  onChangeUserClick: () => void;
  onLogout: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  anchorEl,
  onClose,
  onChangePassword,
  onChangeUserClick,
  onLogout,
}) => (
  <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    <MenuItem onClick={onChangePassword}>Change Password</MenuItem>
    <MenuItem onClick={onChangeUserClick}>Change User</MenuItem>
    <MenuItem onClick={onLogout}>Logout</MenuItem>
  </Menu>
);

export default UserMenu;
