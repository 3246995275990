import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export function useAuthHook() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.authReducer);

  return {
    isAuthenticated: auth.isAuthenticated,
    currentUserId: auth.user?.uid,
    currentUserRole: auth.user?.role,
  };
}
