import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const ColumnFlexBoxContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: 1,
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
};
