import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as Yup from "yup";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Formik, Form, FieldArray, FormikHelpers } from "formik";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import {
  CustomerRFC,
  FiscalRegime,
  InvoiceUsage,
} from "../../../../shared/types";
import { useParams, useNavigate } from "react-router-dom";
import {
  createCustomerRFC,
  deleteCustomerRFC,
  updateCustomerRFC,
} from "../../../../store/thunks/billingThunks";
import {
  fireSwalWarning,
  checkIfManagerOrAdmin,
  isValidRFC,
} from "../../../../helpers";
import { CreatedAndUpdatedInfo } from "../../../../components";

const CustomerRFCSchema = Yup.object().shape({
  _id: Yup.string(),
  name: Yup.string().required("Required"),
  rfc: Yup.string()
    .required("Required")
    .test("is-valid-rfc", "RFC is not valid", (value) =>
      value ? isValidRFC(value) : false
    ),
  invoice_usage: Yup.string().required("Required"),
  fiscal_regime: Yup.string().required("Required"),
  postal_code: Yup.string().required("Required"),
  isValidated: Yup.boolean(),
  createdAt: Yup.string(),
  createdBy: Yup.string(),
  updatedAt: Yup.string(),
  updatedBy: Yup.string(),
});

export const CreateOrUpdateCustomerRFC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { role } = useAppSelector((state) => state.authReducer.user!);
  const isAuthorized = checkIfManagerOrAdmin(role!);
  const { customerRFCs, isLoading, invoiceUsages, fiscalRegimes } =
    useAppSelector((state) => state.billingReducer);
  const customerRFC = useMemo(
    () =>
      customerRFCs.find((rfc) => rfc._id === id) || {
        _id: undefined,
        name: "",
        rfc: "",
        invoice_usage: "",
        fiscal_regime: "",
        postal_code: "",
        isValidated: false,
        createdAt: "",
        createdBy: "",
        email: "",
        phone_number: "",
      },
    [customerRFCs, id]
  );
  const [initialValues, setInitialValues] = useState<CustomerRFC>(customerRFC);
  const [invoiceUsagesOptions, setInvoiceUsagesOptions] = useState<
    InvoiceUsage[]
  >(invoiceUsages || []);
  const [fiscalRegimesOptions, setFiscalRegimesOptions] = useState<
    FiscalRegime[]
  >(fiscalRegimes || []);
  const [isMoralPerson, setIsMoralPerson] = useState(false);
  const [isNaturalPerson, setIsNaturalPerson] = useState(false);

  useEffect(() => {
    setInitialValues(customerRFC);
    const isNatural = customerRFC.rfc.length === 13; // RFC length of 13 indicates a natural person
    const isMoral = customerRFC.rfc.length === 12; // RFC length of 12 indicates a moral person
    setIsMoralPerson(isMoral);
    setIsNaturalPerson(isNatural);
  }, [customerRFC]);

  const handleRFCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isNatural = value.length === 13; // RFC length of 13 indicates a natural person
    const isMoral = value.length === 12; // RFC length of 12 indicates a moral person
    setIsMoralPerson(isMoral);
    setIsNaturalPerson(isNatural);
  };

  const handleSubmit = async (
    values: CustomerRFC,
    { setSubmitting }: FormikHelpers<CustomerRFC>
  ) => {
    setSubmitting(true);
    let newId;
    if (id) {
      newId = id;
      dispatch(updateCustomerRFC(values));
    } else {
      const data = await dispatch(createCustomerRFC(values));
      newId = data.data._id;
    }

    navigate(`/dashboard/billing/create-bill?customer-rfc-id=${newId}`);
    setSubmitting(false);
  };

  const handleDelete = async () => {
    if (id) {
      const result = await fireSwalWarning(
        "Are you sure?",
        "This action is irreversible."
      );
      if (!result.isConfirmed) return;
      await dispatch(deleteCustomerRFC(id)); // Assuming you have a delete thunk similar to deleteBillCreationInfo
      navigate("/dashboard/billing/");
    }
  };

  useEffect(() => {
    if (isMoralPerson) {
      setInvoiceUsagesOptions(invoiceUsages!.filter((usage) => usage.moral));
      setFiscalRegimesOptions(fiscalRegimes!.filter((regime) => regime.moral));
    } else if (isNaturalPerson) {
      setInvoiceUsagesOptions(invoiceUsages!.filter((usage) => usage.natural));
      setFiscalRegimesOptions(
        fiscalRegimes!.filter((regime) => regime.natural)
      );
    } else {
      setInvoiceUsagesOptions([]);
      setFiscalRegimesOptions([]);
    }
  }, [isMoralPerson, invoiceUsages, fiscalRegimes, isNaturalPerson]);

  return (
    <Box>
      <Typography variant="h4">Add or Edit Customer RFC</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={CustomerRFCSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          handleChange,
          isSubmitting,
        }) => {
          return (
            <Form>
              <Paper elevation={3} sx={{ margin: 2, padding: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Name*</FormLabel>
                      <Field
                        as={TextField}
                        name="name"
                        placeholder="Name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>RFC*</FormLabel>
                      <Field
                        as={TextField}
                        name="rfc"
                        placeholder="RFC"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChange(event);
                          handleRFCChange(event);
                        }}
                        error={touched.rfc && Boolean(errors.rfc)}
                        helperText={touched.rfc && errors.rfc}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Fiscal Regime*</FormLabel>
                      <Autocomplete
                        options={fiscalRegimesOptions}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) =>
                          setFieldValue(
                            "fiscal_regime",
                            newValue ? newValue.id : ""
                          )
                        }
                        value={
                          fiscalRegimesOptions.find(
                            (regime) => regime.id === values.fiscal_regime
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Fiscal Regime"
                            error={
                              touched.fiscal_regime &&
                              Boolean(errors.fiscal_regime)
                            }
                            helperText={
                              touched.fiscal_regime && errors.fiscal_regime
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Invoice Usage*</FormLabel>
                      <Autocomplete
                        options={invoiceUsagesOptions}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) =>
                          setFieldValue(
                            "invoice_usage",
                            newValue ? newValue.id : ""
                          )
                        }
                        value={
                          invoiceUsagesOptions.find(
                            (usage) => usage.id === values.invoice_usage
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Invoice Usage"
                            error={
                              touched.invoice_usage &&
                              Boolean(errors.invoice_usage)
                            }
                            helperText={
                              touched.invoice_usage && errors.invoice_usage
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Email</FormLabel>
                      <Field
                        as={TextField}
                        name="email"
                        placeholder="Email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Phone Number</FormLabel>
                      <Field
                        as={TextField}
                        name="phone_number"
                        placeholder="Phone Number"
                        error={
                          touched.phone_number && Boolean(errors.phone_number)
                        }
                        helperText={touched.phone_number && errors.phone_number}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Postal Code*</FormLabel>
                      <Field
                        as={TextField}
                        name="postal_code"
                        placeholder="Postal Code"
                        error={
                          touched.postal_code && Boolean(errors.postal_code)
                        }
                        helperText={touched.postal_code && errors.postal_code}
                      />
                    </FormControl>
                  </Grid>
                  {isAuthorized && (
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Field
                              as={Checkbox}
                              name="isValidated"
                              color="primary"
                              checked={values.isValidated}
                            />
                          }
                          label="Validated"
                        />
                        {touched.isValidated && errors.isValidated && (
                          <Typography color="error">
                            {errors.isValidated}
                          </Typography>
                        )}
                      </FormGroup>
                    </Grid>
                  )}
                  <CreatedAndUpdatedInfo
                    createdAt={values.createdAt}
                    createdBy={values.createdBy}
                    updatedAt={values.updatedAt}
                    updatedBy={values.updatedBy}
                  />
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        disabled={!id} // Disable if there's no ID, meaning it's a new bill
                        fullWidth
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
