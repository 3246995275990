import { createAsyncThunk } from "@reduxjs/toolkit";

import hvpAuthenticatedApi from "../../api/hvpAuthenticatedApi";
import { ApiResponse, AxiosParams } from "../../shared/types";
import {
  fireSwalError,
  fireSwalSuccess,
  getDateRangeInMexico,
} from "../../helpers";
import { RootState } from "../store";
import { IActivityRegister } from "../../shared/types/activity-register.types";
import { IOption } from "../../shared/types/option.types";
import { ActivityRegisterCollaboratorStats } from "../slice/activity-register.slice";
import axios from "axios";
import { ActivityRegisterService } from "../../services/activity-register.service";

const route = "/activity-register";
const acitivityRegisterTypesRoute = "/activity-register-types";

export const fetchCurrentColActivityRegisterInitialData = createAsyncThunk(
  "activityRegister/fetchActivityRegisterData",
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(
        fetchCurrentColActivityRegisters({
          sort_by: "startingTime",
          direction: "desc",
          limit: 200,
        })
      ),
      dispatch(fetchActivityRegisterTypes()),
      dispatch(fetchCollaboratorStats()),
    ]);
  }
);

export const fetchCurrentColActivityRegisters = createAsyncThunk<
  IActivityRegister[],
  AxiosParams | undefined
>(
  "activityRegister/fetchCurrentColActivityRegisters",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { authReducer } = getState() as RootState;

      const data = await ActivityRegisterService.getAll({
        collaborator: authReducer.user!.uid!,
      });

      return data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchActivityRegisterTypes = createAsyncThunk<IOption[]>(
  "activityRegister/fetchActivityRegisterTypes",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { data } = await hvpAuthenticatedApi.get<ApiResponse<IOption[]>>(
        `${acitivityRegisterTypesRoute}`
      );

      return data.data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchCollaboratorStats =
  createAsyncThunk<ActivityRegisterCollaboratorStats>(
    "activityRegister/fetchCollaboratorStats",
    async (_, { rejectWithValue, getState }) => {
      try {
        const { authReducer } = getState() as RootState;
        const collaborator = authReducer.user!.uid;

        const fetchStats = async (period: string) => {
          const { startDate, endDate } = getDateRangeInMexico(period);
          const { data } = await hvpAuthenticatedApi.get<ApiResponse<number>>(
            `${route}/calculate-duration`,
            { params: { collaborator, startDate, endDate } }
          );
          return data.data;
        };

        const [
          todayHours,
          yesterdayHours,
          halfMonthHours,
          monthHours,
          quarterHours,
          yearHours,
          totalHours,
        ] = await Promise.all([
          fetchStats("today"),
          fetchStats("yesterday"),
          fetchStats("biweek"),
          fetchStats("month"),
          fetchStats("quarter"),
          fetchStats("year"),
          fetchStats("all"),
        ]);

        return {
          todayHours,
          yesterdayHours,
          halfMonthHours,
          monthHours,
          quarterHours,
          yearHours,
          totalHours,
        };
      } catch (error: any) {
        handleError(error);
        return rejectWithValue(error);
      }
    }
  );

export const updateActivityRegister = createAsyncThunk<
  IActivityRegister,
  IActivityRegister
>(
  "activityRegister/updateActivityRegister",
  async (activityRegister: IActivityRegister, { rejectWithValue }) => {
    try {
      const { data } = await hvpAuthenticatedApi.patch<
        ApiResponse<IActivityRegister>
      >(`${route}/${activityRegister.id}`, activityRegister);

      await fireSwalSuccess("Actividad actualizada correctamente");
      return data.data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

// todo
export const finishCurrentActivityRegister = createAsyncThunk<
  IActivityRegister,
  IActivityRegister
>(
  "activityRegister/updateActivityRegister",
  async (
    activityRegister: IActivityRegister,
    { rejectWithValue, dispatch }
  ) => {
    const startTime = new Date(activityRegister.startingTime);
    const endingTime = new Date(startTime.getTime() + 60 * 60 * 1000);

    try {
      const { data } = await hvpAuthenticatedApi.patch<
        ApiResponse<IActivityRegister>
      >(`${route}/${activityRegister.id}`, {
        ...activityRegister,
        endingTime: endingTime,
      });

      return data.data;
    } catch (error: any) {
      console.error({ error });
      if (axios.isAxiosError(error)) {
        console.error("Axios error");
        // Handle Axios error
        await dispatch(deleteActivityRegister(activityRegister.id!));

        console.error("Axios error:", error.response?.data);
        // Do something specific for Axios errors here
        // For example, you could dispatch another action or update the state
      } else {
        // Handle other types of errors
        console.error("Non-Axios error:", error);
        handleError(error);
      }
      return rejectWithValue(error);
    }
  }
);
export const deleteActivityRegister = createAsyncThunk<string, string>(
  "activityRegister/deleteActivityRegister",
  async (activityRegisterId: string, { rejectWithValue }) => {
    try {
      await hvpAuthenticatedApi.delete<ApiResponse<void>>(
        `${route}/${activityRegisterId}`
      );

      return activityRegisterId;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const createActivityRegister = createAsyncThunk<
  IActivityRegister,
  IActivityRegister
>(
  "activityRegister/createActivityRegister",
  async (activityRegister: IActivityRegister, { rejectWithValue }) => {
    try {
      const { data } = await hvpAuthenticatedApi.post<
        ApiResponse<IActivityRegister>
      >(route, activityRegister);

      await fireSwalSuccess("Activity registered correctly");

      return data.data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchActivityRegisters = createAsyncThunk<
  IActivityRegister[],
  AxiosParams | undefined
>(
  "activityRegister/fetchActivityRegisters",
  async (params = {}, { rejectWithValue, getState }) => {
    try {
      const data = await ActivityRegisterService.getAll(params);
      return data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const createActivityRegisterType = createAsyncThunk<IOption, IOption>(
  "activityRegister/createActivityRegisterTypes",
  async (activityRegisterType: IOption, { rejectWithValue }) => {
    try {
      const data = await ActivityRegisterService.createActivityRegisterType(
        activityRegisterType
      );

      return data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const updateActivityRegisterType = createAsyncThunk<IOption, IOption>(
  "activityRegister/updateActivityRegisterTypes",
  async (activityRegisterType, { rejectWithValue }) => {
    try {
      const data = await ActivityRegisterService.updateActivityRegisterTypes(
        activityRegisterType.id!,
        activityRegisterType
      );
      return data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteActivityRegisterType = createAsyncThunk<string, string>(
  "activityRegister/deleteActivityRegisterTypes",
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await ActivityRegisterService.deleteActivityRegisterType(id);
      console.log({ data });

      return data;
    } catch (error: any) {
      handleError(error);
      return rejectWithValue(error);
    }
  }
);

const handleError = (error: any) => {
  console.error("Axios Error:", error);

  let errorTitle = "Error al registrar";
  let errorMessage = "";

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorTitle = `Error ${error.response.status}`;
    errorMessage = `Error: ${error.response.data.message || error.message}`;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response from server. Please try again later.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }

  fireSwalError(errorMessage);
};
