import { Branch } from "./Branch";

export interface AttendanceRecord {
  _id?: string;
  shiftDate: string;
  startTime?: string | Date;
  endTime?: string | Date;
  clockInBranch: Branch;
  clockOutBranch: Branch;
  collaborator: string;
  action?: AttendanceRecordAction;
  createdAt?: Date;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: Date;
  startLatitude?: number;
  startLongitude?: number;
  endLatitude?: number;
  endLongitude?: number;
}

export enum AttendanceRecordAction {
  ClockIn = "clock_in",
  ClockOut = "clock_out",
}
